import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import {
  getWorkflowFromCode,
  getWorkflowById,
} from '../../../actions/workflows';
import { FieldArray } from 'redux-form';
import WorkflowContainer from '../../../containers/workflow/WorkflowContainer';

const TypeModelCreateUpdateWorkflowCard = props => {
  const [currentItem, setCurrentItem] = useState(null);
  const {
    getWorkflowFromCode,
    readOneWorkflowUniqueCode,
    getWorkflowById,
    rows,
    classProperty,
  } = props;
  useEffect(() => {

    if(window.typeModelCreateUpdateWorkflowCard && window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode])  {
      console.log(window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode])
      if(!window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].callbacks) {
        window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].callbacks = [];
      }

      if(window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].loading) {
        console.log("Adding callbacks")
        window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].callbacks.push((val) =>{
          console.log("Executing callbacks")
          setCurrentItem(val);
        })
      } else {
        console.log("Loaded");
        setCurrentItem(window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].wf);
      }
      

      
    } else {
      if(!window.typeModelCreateUpdateWorkflowCard) {
        window.typeModelCreateUpdateWorkflowCard = {}
      }
      window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode] = {loading: true};
      getWorkflowFromCode(readOneWorkflowUniqueCode)
        .then(res => {
          res.data &&
            getWorkflowById(res.data.data)
              .then(workflowResponse => {
                if (workflowResponse) {
                  console.log(window.typeModelCreateUpdateWorkflowCard);
                  window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode] = {...window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode], loading: false, wf: workflowResponse.data.items[0]};
                  callOneCallback(window.typeModelCreateUpdateWorkflowCard[readOneWorkflowUniqueCode].callbacks,0 ,workflowResponse.data.items[0]);
                  setCurrentItem(workflowResponse.data.items[0]);
                }
              })
              .catch(ex => {});
        })
        .catch(error => {}); 
      }
  }, []);
  return (
    <div>
      {currentItem && rows ? (
        <FieldArray
          fields={rows}
          name={classProperty}
          classProperty={classProperty}
          component={renderWorkflowCards}
          currentItem={currentItem}
        />
      ): <div className='loader-container d-flex justify-content-center'>
      <span className='loader_1'></span>
    </div>}
    </div>
  );
};

const callOneCallback = (callbacks, index, value) => {
  setTimeout(() => {
    callbacks && callbacks[index](value);
    if(callbacks && index < callbacks.length-1) callOneCallback(callbacks, index+1, value)
  }, 50);
}
const renderWorkflowCards = ({ fields, currentItem, classProperty }) => {
  return (
    <div>
      {fields.map((member, index) => {
        const workflow = {
          currentItem,
          workflowData: member,
          memberIndex: index,
          memberClassProperty: classProperty,
          hasMember: true,
        };
        return (
          <div key={`member-${classProperty}-${index}`}>
            <WorkflowContainer
              onHandleSubmit={()=>{}}
              change={()=>{}}
              displayHeader={false}
              noPadding={true}
              workflow={workflow}
              isBase={false}
              isInline={true}
              inlineFormKey={'workflow-card-' + classProperty + index}
              callInlineNext={()=>{}}
              workflowData={member}
              isWorkflowCard={true}
              useActualClassProperty={true}
            />
          </div>
        )
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflowFromCode: code => {
      return dispatch(getWorkflowFromCode(code));
    },
    getWorkflowById: id => {
      return dispatch(getWorkflowById(id));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(TypeModelCreateUpdateWorkflowCard));
