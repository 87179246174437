import React, { Component } from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import moment from 'moment';
import { renderField } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';


class Time extends Component {
    constructor(props) {
        super(props);

    }
    getFormattedDate = function (date) {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    };

    getTimeZone = function (date) {

    }

    componentDidMount () {
        const { layoutItem: { itemProperties: { timeConfig, field: { classProperty },replaceClassProperty } },formValues } = this.props;
        const defaultTime = timeConfig && timeConfig.defaultTimeSet ? timeConfig.defaultTimeSet : null;
        let classProp = replaceClassProperty ? replaceClassProperty : classProperty;
        classProp = classProp.indexOf('{') == -1 ? `{{${classProp}}}` : classProp;
        const timeValue = formValues && ModelPropertiesParser(classProp, formValues);
        console.log(timeValue,'...........');
        if(timeValue){
            let newDate = moment(timeValue + ' GMT');
            const hh = newDate.toDate().getHours();
            const mm = newDate.toDate().getMinutes();
            const newValue = (hh > 9 ? '' : '0') + hh + ":" + (mm > 9 ? '' : '0') + mm;
            this.normalize(newValue,"Value")
        } else {
            this.normalize(defaultTime,"Default")
        }
    }

    normalize = (value,defaultValue) => {
		const now = new Date();
        const formatted = this.getFormattedDate(now);
        const newDate = new Date(`${formatted} ${value}`);
		let current = new moment(newDate);
		let updatedTime = current.clone();
		const offSet = current.utcOffset();
		updatedTime = updatedTime.subtract(offSet, 'minutes');
		let hh = updatedTime.toDate().getHours();
		let mm = updatedTime.toDate().getMinutes();
        const newValue = (hh > 9 ? '' : '0') + hh + ":" + (mm > 9 ? '' : '0') + mm;
        defaultValue && this.props.change(this.props.classProperty,newDate == "Invalid Date" ? '' : `${formatted} ${newValue}`)
        return `${formatted} ${newValue}`;
    }

    format = (value) => {
        const { layoutItem: { itemProperties: { timeConfig } } } = this.props;
        const defaultTime = timeConfig && timeConfig.defaultTimeSet ? timeConfig.defaultTimeSet : null
        let hh, mm;
        if (!value && !defaultTime) {
            return value
        }
        if (defaultTime && !value) {
            const valueArray = defaultTime.split(':')
            hh = parseInt(valueArray[0])
            mm = parseInt(valueArray[1])
        } else {
            let newDate = moment(value + ' GMT');
            hh = newDate.toDate().getHours();
            mm = newDate.toDate().getMinutes();
        }
        hh = new Date(value) == "Invalid Date" ? '':hh;
        mm = new Date(value) == "Invalid Date" ? '':mm;
        const newValue = (hh > 9 ? '' : '0') + hh + ":" + (mm > 9 ? '' : '0') + mm;
        return newValue;
    }

    render() {
        const { classProperty, type, validations, value, isReadOne, ...rest } = this.props;
        return (
            <div>
                {
                    isReadOne ?
                        <PrintText {...this.props} /> :
                        <Field
                            name={classProperty}
                            component={renderField}
                            type="time"
                            value={value}
                            fieldType={type}
                            onBlur={() => {
                                const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                Array.isArray(cEventKeys) &&    cEventKeys.map(k => {
                                    k.startsWith(classProperty) &&
                                    window.cEvents[k](this.props.formValues);
                                });
                            }}
                            {...rest}
                            normalize={this.normalize}
                            format={this.format.bind(this)}
                        />
                }
            </div>
        );
    }
}

export default Time;