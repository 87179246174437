import React, { Component } from 'react';
import { Button } from 'reactstrap';
import RenderIcon from '../../../../commons/RenderIcon';

class PossibleValuesButtonGroupFilter extends Component {
  constructor(props) {
    super(props);
    const { filterStateMap, filterIndex } = props;
    const savedFilterState = filterStateMap[`filter_${filterIndex}`];
    this.state = {
      activeIndex: savedFilterState
        ? this.getActiveIndex(savedFilterState)
        : -1,
      options: [],
    };
  }
  componentDidMount() {
    const { getSuggestions } = this.props;
    getSuggestions().then(res => {
      const values = res.data;
      const options =
        values &&
        values.map(val => {
          return {
            value: val.filterValue,
            label:
              val.filterValue == 'NULL' ? 'Not Available' : val.filterValue,
            count: val.count,
          };
        });
      this.setState({ options });
    });
  }
  setActiveIndex(activeIndex) {
    this.setState({ activeIndex });
  }
  itemClicked(value, activeIndex, ev) {
    ev.preventDefault();
    const {
      onFilterChange,
      filter,
      filterIndex,
      onFiltersApplied,
    } = this.props;
    const filterConfig = {
      filter,
      filterIndex,
    };
    this.setActiveIndex(activeIndex);
    value != ''
      ? onFilterChange(filterConfig, [value])
      : onFilterChange(filterConfig, []);
    onFiltersApplied();
    return false;
  }
  getCount(value) {
    const { options } = this.state;
    let count = 0;
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === value) {
        count = options[i].count;
        break;
      }
    }
    return count;
  }
  getActiveIndex(savedState) {
    const { filterBy } = savedState;
    const {
      filter: { possibleValues },
    } = this.props;
    let activeIndex = -1;
    possibleValues &&
      possibleValues.map((value, index) => {
        if (value === filterBy[0]) {
          activeIndex = index;
        }
      });
    return activeIndex;
  }
  render() {
    let { typeModelName, workflow, filter } = this.props;
    const { activeIndex } = this.state;
    const { possibleValues } = filter;
    const showClickableButtonGroupWithIcon = filter && filter.showClickableButtonGroupWithIcon
    const displayName = filter && filter.displayName
    if (possibleValues.indexOf('NULL') == -1) possibleValues.push('NULL');
    if (!typeModelName) {
      typeModelName = workflow.currentItem.typeModel.name;
    }

    if(showClickableButtonGroupWithIcon){
      return (
        <div className='possible-values-filter-wrapper-buttongroup align-items-center possible-values-filter-wrapper-buttongroup-withicon'>
          <div className='label_main_bti'>{displayName}</div>
         
          {possibleValues &&
            possibleValues.map((value, index) => {
              console.log(filter,value,"jehhehjejhejhejhjh")
              const valueIcon = `${value}_icon`
               let iconName = filter && filter[valueIcon]
  
               console.log(filter,"iconNameiconName")
              if(value == 'NULL') return
              if(showClickableButtonGroupWithIcon){
                return (
                  <Button 
                    key={index}
                    color='link'
                    onClick={this.itemClicked.bind(this, value, index)}
                    className={`possible-values-filter-item buttonmain_bti  ${
                      activeIndex === index ? 'active' : ''
                    }`}
                  >
                    <div className='d-flex flex-row text-center maindiv_bti'>
                    <RenderIcon config={iconName} className='icon_bti'/>
                      <div className='label_bti'>{value}</div>
                    </div>
                  </Button>
                );
              }
            })}
        </div>
      );
    }
    
    return (
      <div className='possible-values-filter-wrapper-buttongroup align-items-center'>
       
        {possibleValues &&
          possibleValues.map((value, index) => {
            if(value == 'NULL') return
            return (
              <Button
                key={index}
                color='link'
                onClick={this.itemClicked.bind(this, value, index)}
                className={`possible-values-filter-item  ${
                  activeIndex === index ? 'active' : ''
                }`}
              >
                <div className='d-flex flex-row text-center'>
                  <div>{value}</div>
                </div>
              </Button>
            );
          })}
      </div>
    );
  }
}

export default PossibleValuesButtonGroupFilter;
