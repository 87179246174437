import _required from './required';
import _requiredType from './requiredType';
import email from './email';
import positiveNumber from './positiveNumber';
import wholeNumber from './wholeNumber';
import url from './url';
import date from './date';
import Decimal from './Decimal';
import NumberRange from './numberRange';
import maxLength from './maxLength';
import ofLength from './ofLength';
import time from './time';
import _minLength from './minLength';
import _regexPattern from './regexPattern';
import _isTrue from './isTrue';
import _Password from './Password';
import _isUnique from './isUnique';
import _phone from './phone';
import _compare from './compare';

export const required = _required;
export const requiredType = _requiredType;
export const fixedLength = ofLength;
export const minLength = _minLength;
export const maxLngth = maxLength;
export const regexPattern = _regexPattern;
export const isTrue = _isTrue;
export const isUnique = _isUnique;
export const Password = _Password;
export const phone = _phone;
export const compare = _compare;
export const typeValidations = {
    EMAIL: [email, maxLength(100)],
    POSITIVE_NUMBER: [positiveNumber],
    NUMBER: [wholeNumber],
    URL: [url, maxLength(100)],
    DATE: [date],
    DECIMAL: [Decimal],
    NUMBER_RANGE: [NumberRange],
    SMALLTEXT: [maxLength(500)],
    MEDIUMTEXT: [maxLength(1000)],
    TIME: [time]
}