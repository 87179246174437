import React, { Component, useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import ForgotPassword2 from '../pages/login/ForgotPassword2';
import ReCAPTCHA from 'react-google-recaptcha';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayReCaptcha: false
    };
  }
    renderField({ input, label, type, placeholder, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} { ...input } />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
  render() {
    const {forgotPasswordState,companyDetails,layoutItem,layoutItem:{itemProperties:{forgotPasswordConfig}}} = this.props;
    const reCaptcha =
    forgotPasswordConfig &&
    forgotPasswordConfig.reCaptcha;

    const recatchpa_siteKey = forgotPasswordConfig && forgotPasswordConfig.recatchpa_siteKey
    const template = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.forgotPasswordConfig && layoutItem.itemProperties.forgotPasswordConfig.template.value
    if(template === 'Template2'){
      return <ForgotPassword2 
      forgotPassword={this.props.forgotPassword}
      displayReCaptcha={this.state.displayReCaptcha}
      reCaptchaComponent={
        reCaptcha && (
          <ReCaptcha
            recatchpa_siteKey={recatchpa_siteKey}
            displayReCaptcha={this.state.displayReCaptcha}
            updateDisplayReCaptcha={(value) => {
              this.setState({displayReCaptcha: value})
            }}
        
          />
        )
      }
      {...this.props}/>
    }
    const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/login'
    if (!forgotPasswordState.isLoading && forgotPasswordState.data !== null ) {
      return <div  className='player-public-user-form p-4 public-forgotpassword-component' style={{marginTop:100}}>
         <h5 className='text-center mb-3'>Password Link Sent</h5>
              <Alert color='info'>
                <i className='fa fa-check'></i> Thankyou for submitting the request. If the email address is registered with us, you will recieve an email with link to reset the password.
                </Alert>
                <Link className='mt-4 d-block text-center bg-white p-2 rounded' to={logoutUrl}>
                    Continue to login
                </Link>
            </div>
    }
    return (
      <div style={{marginTop:100}}>
         <Form onSubmit={this.props.forgotPassword} className='player-public-user-form p-4 public-forgotpassword-component'>
                        <h5 className='text-center mb-3'>Reset your password</h5>
                        <Field
                            name="email"
                            component={this.renderField}
                            type="text"
                            label='Enter your email Address'
                            placeholder='Email'
                        />
                        <Button className='mt-4' color='primary' block>
                        Reset Password
                        </Button>
                        <Link className='mt-4 d-block text-center' to={logoutUrl}>
                            <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                            Back to login
                        </Link>
                    </Form>
      </div>
    )
  }
}


const ReCaptcha = (props) => {

  const captchaRef = useRef();

  const [captchaDetails, setCaptchaDetails] = useState(null);
  useEffect(() => {
    let reCaptchaDetails = localStorage.getItem("_captcha_details_forgot");
    reCaptchaDetails = JSON.parse(reCaptchaDetails);
    if(reCaptchaDetails && reCaptchaDetails.count !== (captchaDetails && captchaDetails.count)) {
      if(reCaptchaDetails.count > 2) {
        props.updateDisplayReCaptcha(true);
        captchaRef.current && captchaRef.current.reset();
      }
      setCaptchaDetails(reCaptchaDetails);
    }
  }, [])

  if(!captchaDetails) {
    return null
  }
  if(captchaDetails && captchaDetails.count < 3) {
    return null
  }
  return (
    <div>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={
            props.recatchpa_siteKey ? props.recatchpa_siteKey : '6LeWWy8UAAAAANwTQi-Y3DNwRztm_6Mqiw2AjYuF'
          }
          onChange={token => {
              if(token) {

                props.updateDisplayReCaptcha(false)
              }
          }}
        />
      </div>
  )
}
