import React, { Component } from 'react';
import ReadAllComp from './ReadAllComp';
import CreateUpdate from './CreateUpdate';
import ReadOne from './ReadOne';
import FilterWrapperHOC from './filters/FilterWrapperHOC';

class ReadAll extends Component {
    constructor(props) {
        super(props);
        let state = {
            filterStateMap: {},
            paginationStart: 0,
            pageSize: 25,
            colModelId: ''
        }
        if(!props.workflow.parentSession) {
            state = {
                filterStateMap: {},
                paginationStart: props.sortPagination.paginationStart,
                pageSize: props.sortPagination.pageSize
            }
        } 
        this.state = state;
		this._onFilterChange = this._onFilterChange.bind(this);
        this.onFiltersApplied = this.onFiltersApplied.bind(this);
        this.onClearAppliedFilters = this.onClearAppliedFilters.bind(this);
    }
    onChangePage = (paginationStart, pageSize) => {
        this.setState({ paginationStart, pageSize }, () => {
            this.onFiltersApplied();
        });
    }
    onChangePageSize = (pageSize) => {
        this.setState({ pageSize, paginationStart: 0 }, () => {
            this.onFiltersApplied();
        });
    }
    onFiltersApplied() {
        const filterMap = Object.assign({}, this.state.filterStateMap);
        const { workflowItem, workflow, isBase, sortPagination, container,columnLayoutModel } = this.props;
		const alternateTypeModel = container.properties.alternateTypeModel;
        const payload = {
            customModelName: columnLayoutModel ? columnLayoutModel.name : alternateTypeModel
             ? alternateTypeModel.name : workflowItem.typeModel.name,
            typeId: columnLayoutModel ? this.state.colModelId : alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: [],
            filterInAnotherModel: {}
        };
        payload.start = this.state.paginationStart;
        payload.count = this.state.pageSize;
		sortPagination.paginationStart = this.state.paginationStart;
		sortPagination.pageSize = this.state.pageSize;
        if (Object.keys(filterMap).length) {
            for (let filterKey in filterMap) {
                const _f = filterMap[filterKey];
                const { collection, targetClassProperty, isSearch, filterBy, leafType, dateFilterType } = _f;
                if (collection === true) {
                    let modelName = targetClassProperty;
                    let modelProperty = targetClassProperty;
                    if (targetClassProperty.indexOf('.') !== -1) {
                        const splits = targetClassProperty.split('.');
                        modelName = splits.shift();
                        modelProperty = splits.join('.');
                    }
                    payload.filterInAnotherModel = {
                        modelName,
                        modelProperty
                    }
                }
                else {
                  const filterV = filterBy && filterBy.length && (filterBy[0] == 'NULL' || filterBy[0] === "") ? null:filterBy;
                  if(filterV) {
                    payload.filters.push({
                      filterField: targetClassProperty,
                      filterValue: filterV,
                      valueType: leafType,
                      isSearch: isSearch ? true: false,
					  filterOperator: dateFilterType == 'DATERANGE'?'BETWEEN':''
                  });
                  }
                }
            }
        }
		if(sortPagination.sortField != null) {
			payload.sort = [];
			payload.sort.push({
				sortField: sortPagination.sortField,
				descending: sortPagination.order == 'DESCENDING'? true:false
			});
		}
        this.props.triggerFilterReadAll(payload);
		this.props.setSortAndPagination(sortPagination);
    }
    onClearAppliedFilters() {
        const { workflowItem, workflow, isBase, container,columnLayoutModel } = this.props;
		const alternateTypeModel = container.properties.alternateTypeModel;
        const payload = {
            customModelName: columnLayoutModel ? columnLayoutModel.name : alternateTypeModel
            ? alternateTypeModel.name : workflowItem.typeModel.name,
            typeId: columnLayoutModel ? this.state.colModelId : alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: []
        };
        this.setState({ filterStateMap: {} });
        this.props.triggerFilterReadAll(payload);
    }
    _onFilterChange(filterConfig, filterBy) {
        if (!filterBy) {
            this.setState({
                filteredDataList: this._dataList,
            });
        }
        const { filter, filterIndex } = filterConfig;
        const { filterStateMap } = this.state;
        filter.filterBy = filterBy
		if(filterBy.length == 0 ) {
			delete filterStateMap[`filter_${filterIndex}`];
		} else {
			filterStateMap[`filter_${filterIndex}`] =filter;
		}
        
        this.setState({ filterStateMap });
    }

    componentDidMount() {
        const { columnLayoutModel } = this.props;
        if(columnLayoutModel && columnLayoutModel.name)
        {
            const self = this;
            this.props.getModelByNamePromise({ name: columnLayoutModel.name }).then((res) => {
                const model = res.data;
                const typeId = model.id;
                self.setState({colModelId: typeId})
            });
        }
        this.setState({ paginationStart: 0, pageSize: 25 });

    }
    componentWillUnmount() {
        this.setState({ paginationStart: 0, pageSize: 25 });
    }

	componentWillReceiveProps(nextProps) {
        if(this.props.workflow.workflowModel !== nextProps.workflow.workflowModel) {
            this.setState({ paginationStart: 0, pageSize: 25 });
        } else {
            const { sortPagination } = nextProps;
		    this.setState({ paginationStart: sortPagination.paginationStart, pageSize: sortPagination.pageSize });
        }
        
    }
	
    render() {
		const { container, columnIndex, workflowItem, containerIndex,viewMode, companyDetails } = this.props;
        const {
            layoutProperties: { readAllFilterConfig },
          } = workflowItem;
          const fetchAfterFilter =
            readAllFilterConfig &&
            readAllFilterConfig[containerIndex] &&
            readAllFilterConfig[containerIndex].filterConfig &&
            readAllFilterConfig[containerIndex].filterConfig.fetchAfterFilter;
   
        const { paginationStart, pageSize } = this.state;
        const { properties, properties: {colLayoutConfig, isStatic} } = container;
        const colOperation = colLayoutConfig && colLayoutConfig[`colOperation-${columnIndex}`];
        const { keyed,table } = 
            workflowItem.layoutProperties.readAllViews[`${containerIndex}_${columnIndex}`] || 
            workflowItem.layoutProperties.readAllViews[containerIndex | ""] || workflowItem.layoutProperties.readAllViews;
        const selectedTemplate = companyDetails && companyDetails.data != null && companyDetails.data.companySettings && companyDetails.data.companySettings.tableTemplate 
            ? companyDetails.data.companySettings.tableTemplate : table && table.isConfigured && table.selectedTemplate;
        const keyedConfig = keyed && keyed.config;
        const columns = table && table.isConfigured && table.config.columns;
        const tableStyleConfig = table && table.config && table.config.tableStyleConfig;
        const locationEnumeratorConfig = keyedConfig && keyedConfig.locationEnumeratorConfig;
        const keyedPagination = locationEnumeratorConfig != null ? locationEnumeratorConfig.allowPagination : false;
        const showThumnailImage = companyDetails && companyDetails.data != null && companyDetails.data.companySettings && companyDetails.data.companySettings.showThumnailImage;
        const filterTemplateCompany = companyDetails && companyDetails.data != null && companyDetails.data.companySettings && companyDetails.data.companySettings.filterTemplate;
        const filterPlacement = companyDetails && companyDetails.data != null && companyDetails.data.companySettings && companyDetails.data.companySettings.filterPlacement;
        const thmbnailFormatSelection = companyDetails && companyDetails.data != null && companyDetails.data.companySettings && companyDetails.data.companySettings.thmbnailFormatSelection;
        const filterTemplate =
        readAllFilterConfig &&
        readAllFilterConfig[containerIndex] &&
        readAllFilterConfig[containerIndex].filterConfig && readAllFilterConfig[containerIndex].filterConfig.templateType && readAllFilterConfig[containerIndex].filterConfig.templateType.value
        //    if(selectedTemplate === 'Template2'){
    //     return (
    //         <div className='h-100'>
    //             {
    //                 (isStatic === false && colOperation && colOperation != 'READALL') || (!colOperation && isStatic === false) ?
    //                     <ReadOne {...this.props} /> :
    //                     <FilterWrapperHOC
    //                         pagination={viewMode === "KEYED" ? keyedPagination : false}
    //                         paginationStart={paginationStart}
    //                         pageSize={pageSize}
    //                         onChangePageSize={this.onChangePageSize}
    //                         onChangePage={this.onChangePage}
    //                         filterStateMap={this.state.filterStateMap}
    //                         onFiltersApplied={this.onFiltersApplied}
    //                         onFilterChange={this._onFilterChange}
    //                         onClearAppliedFilters={this.onClearAppliedFilters}
    //                         {...this.props}
    //                     >
    //                         <ReadAllComp 
    //                             pagination={viewMode === "KEYED" ? keyedPagination : false}
    //                             paginationStart={paginationStart}
    //                             pageSize={100000}
    //                             onFiltersApplied={this.onFiltersApplied}
    //                             onFilterChange={this._onFilterChange}
    //                                 filterMap = {Object.assign({}, this.state.filterStateMap)}
    //                             {...this.props} />
    //                     </FilterWrapperHOC>
    //             }
    //         </div>
    //     );  
    //    }
     
     
       return (
        <div className='h-100'>
            {
                (isStatic === false && colOperation && colOperation != 'READALL') || (!colOperation && isStatic === false) ?
                    <ReadOne {...this.props} /> :
                    <FilterWrapperHOC
                        pagination={viewMode === "KEYED" ? keyedPagination : true}
                        paginationStart={paginationStart}
                        pageSize={pageSize}
                        onChangePageSize={this.onChangePageSize}
                        onChangePage={this.onChangePage}
                        filterStateMap={this.state.filterStateMap}
                        onFiltersApplied={this.onFiltersApplied}
                        onFilterChange={this._onFilterChange}
                        onClearAppliedFilters={this.onClearAppliedFilters}
                        selectedTemplate={selectedTemplate}
                        showThumnailImage={showThumnailImage}
                        thmbnailFormatSelection={thmbnailFormatSelection}
                        tableStyleConfig={tableStyleConfig}
                        fetchAfterFilter={fetchAfterFilter}
                        filterTemplate={filterTemplate}
                        filterTemplateCompany={filterTemplateCompany}
                        filterPlacement={filterPlacement}
                        {...this.props}
                    >
                        <ReadAllComp 
                            pagination={viewMode === "KEYED" ? keyedPagination : true}
                            paginationStart={paginationStart}
                            pageSize={pageSize}
                            onFiltersApplied={this.onFiltersApplied}
                            onFilterChange={this._onFilterChange}
                            colModelId={this.state.colModelId}
                                filterMap = {Object.assign({}, this.state.filterStateMap)}
                            {...this.props} />
                    </FilterWrapperHOC>
            }
        </div>
    );
    }
}

export default ReadAll;