import React from 'react';
import RenderCompanyLogo from '../commons/RenderCompanyLogo';
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import MobileMenuItems from './MobileMenuItems';
import PropTypes from 'prop-types';
import * as MenuTypes from './types';
import { renderSelect } from '../elements/_FORM_FIELDS';
import { Field } from 'redux-form';
import { Select } from "antd";
import  langauges  from './langauages';

class TopbarMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavActive: false,
	  isScroll: false
    }
  }

  componentDidMount() {
    const { getCompanyDetails, user } = this.props;
	window.addEventListener('scroll', this.handleScroll);
    getCompanyDetails && getCompanyDetails();
  }

  componentWillReceiveProps(nextProps) {
    const { companyDetails, getCompanyDetails } = nextProps;
    if(!companyDetails) getCompanyDetails && getCompanyDetails();
  }
  toggleMobileMenu = () => {
    this.setState({ isMobileNavActive: !this.state.isMobileNavActive });
  }

  

  checkForTemperaryLinkWorkflow = () => {
    const { router: { route: { match: { path } } } } = this.context;
    return path === '/public/link/:linkId';
  }

  checkScreenerMonkeyApp = () => {
    return window && window.location && window.location.hostname.includes('screenermonkey');
  }
  
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll = (event) => {
	let scrollConst = false;
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	scrollConst = scrollTop != 0 ? true : false;

	this.setState({
	  isScroll: scrollConst
	});
  }

   normalize = (setActiveLanguage, value) => {
    setActiveLanguage(value)
};

  render() {
    const { isMobileNavActive, isScroll } = this.state;
    const { currentLanguage, translate, isPublic, clearWorkflowState, baseWorkflowRoute, user, companyDetails,availableLanguages,setActiveLanguage } = this.props;
    let possibleValues = availableLanguages ? availableLanguages.split(',') : [];
    const options = possibleValues.map(val => {
      return {
        value: val,
        label: langauges[val]
      };
    });

	
    const isTemperaryLinkWorkflow = this.checkForTemperaryLinkWorkflow();
    const isScreenerMonkeyApp = this.checkScreenerMonkeyApp();
 const LogoLink = user && user.user && user.user.email ? '/app/' : '/login';
    let MenuComponent = companyDetails && companyDetails.data && companyDetails.data.menuType && MenuTypes[companyDetails.data.menuType];
    if(!MenuComponent) {
      MenuComponent = MenuTypes.LEVEL_ONE;
    }
    if(isTemperaryLinkWorkflow) {
      return <div></div>
    }
    const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;

    return (
      <div className={`topbar-menu-items-container ${isScreenerMonkeyApp ? 'screener-monkey' : ''} ${isScroll ? 'topbar-custom-class' : ''} ${isPublic ? 'public_topbar_items':''}`}
       onContextMenu={(e) =>{ 
              disableRightClick &&  e.preventDefault() ;
            }}
      >
        <a href={LogoLink} className={`company-logo-container ${isPublic ? '' : 'company-logo-custom-class'}`} >
          <RenderCompanyLogo />
        </a>
     {availableLanguages &&    <Select options={options}
        defaultValue={currentLanguage}
        style={{ flex: 1 }}
        onChange={this.normalize.bind(this,setActiveLanguage)} />}
        {
          !isTemperaryLinkWorkflow && (
            <div style={{ display: 'initial' }}>
              <div className="menu-bar-mobile">
                <Button className={`menu-bar-mobile-button ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} onClick={this.toggleMobileMenu} color="primary" size="lg">
                  {
                    isMobileNavActive ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>
                  }
                </Button>
              </div>
              <MobileMenuItems isScreenerMonkeyApp={isScreenerMonkeyApp} isActive={isMobileNavActive} isPublic={isPublic} logout={this.props.logout} baseWorkflowRoute={baseWorkflowRoute} />
              <MenuComponent isScreenerMonkeyApp={isScreenerMonkeyApp} isActive={isMobileNavActive} isPublic={isPublic} logout={this.props.logout} baseWorkflowRoute={baseWorkflowRoute}/>
            </div>
          )
        }
      </div>
    )
  }
}

TopbarMenuItems.contextTypes = {
  router: PropTypes.object
}

export default TopbarMenuItems;