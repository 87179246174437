import React, { Component } from 'react';
import {
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ButtonGroup,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';
import ActionContainer from '../../../../containers/workflow/ActionContainer';
import EndWorkflowButton from '../../../commons/EndWorkflowButton';
import { ModelPropertiesParser } from '../../../commons/modelPropertiesParser';
import Select from 'react-select';
import { Badge } from "antd";
import { Flex, Progress } from 'antd';
import RenderCompanyLogo from '../../../commons/RenderCompanyLogo';

class CreateUpdateHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsDDOpen: false,
      filterModelsData: {},
      states: null,
      cities: null,
      selectedState: '22',
      selectedCity: null,
    };
  }
  toggle() {
    this.setState({ actionsDDOpen: !this.state.actionsDDOpen });
  }
  componentDidMount() {
    const {
      layoutProperties: { uiActions },
      typeModel: { customerName },
    } = this.props.workflow.currentItem;
    if (Array.isArray(uiActions)) {
      let filterActions = [];
      uiActions.map(a => {
        if (a.type === 'FILTER') {
          filterActions.push(a);
          if (a.cityFilter) {
            const defaultCountryId = a.defaultCountry
              ? a.defaultCountry.countryId
              : '101';
            this.props.getStatesData().then(res => {
              if (Array.isArray(res.data)) {
                const states = res.data
                  .filter(state => state.country_id === defaultCountryId)
                  .map(state => {
                    return {
                      value: state.id,
                      label: state.name,
                      countryId: state.country_id,
                      stateId: state.id,
                    };
                  });
                this.setState({ states }, () => {
                  this.props.getCitiesData().then(res => {
                    const defaultStateId = this.state.selectedState;
                    if (Array.isArray(res.data)) {
                      const cities = res.data
                        .filter(city => city.state_id === defaultStateId)
                        .map(city => {
                          return {
                            value: city.name,
                            label: city.name,
                            countryId: city.state_id,
                            stateId: city.id,
                          };
                        });
                      this.setState({ cities }, () => {});
                    }
                  });
                });
              }
            });
          } else {
            const filterModelsData = this.state.filterModelsData;
            !filterModelsData[a.filterModel] &&
              this.props
                .readAllData({
                  typeId: a.filterModel,
                  filters: [],
                  customerName,
                })
                .then(res => {
                  filterModelsData[`${a.filterModel}`] = res.data;
                  this.setState(
                    {
                      filterModelsData,
                    },
                    () => {
                      if (Array.isArray(res.data) && res.data.length && filterActions.length === 1) {
                        const totalValues = res.data.length;
                        this.props.setUIFilter({
                          key: a.paramName,
                          value: res.data[totalValues - 1][a.keyField],
                        });
                      }
                    }
                  );
                });
          }
        }
      });
    }
  }
  getMenuTypeGlobalAction = actions => {
    const menuTypeAction = [];
    actions.length &&
      actions.map((action, index) => {
        if (
          action.actionProperties &&
          action.actionProperties.elementType == 'MENULINK'
        ) {
          menuTypeAction.push(action);
        }
      });
    return menuTypeAction;
  };
  render() {
    const {
      showOnlyActions,
      showOnlySwitch,
      workflow,
      getInlineActions,
      workflowData,
      saveAsPDF,
      saveAsOnePagePDF,
      containerIndex,
      showTitle,
      stepperType,
      stepperLogo,
      steps,
      activeStep
    } = this.props;
    const {
      currentItem,
      currentItem: { name },
      parentSession,
      displayMode,
    } = workflow;
    const {
      layoutProperties: {
        pageHeaderConfig,
        downloadPdfButtonLabel,
        downloadAsOnePagePDF,
        uiPluginFilterLabel,
      },
      allowDownloadAsPdf,
    } = currentItem;
    const actions = getInlineActions(currentItem, containerIndex);
    const menuTypeGlobalAction = this.getMenuTypeGlobalAction(actions.globals);

    const headerText =
      pageHeaderConfig && pageHeaderConfig.isConfigured
        ? ModelPropertiesParser(
            pageHeaderConfig.modelSelector,
            workflowData || workflow.workflowData
          )
        : name;
        const subHeaderText =
        pageHeaderConfig && pageHeaderConfig.isConfigured && pageHeaderConfig.subHeadingProperty
          ? ModelPropertiesParser(
              pageHeaderConfig.subHeadingProperty,
              workflowData || workflow.workflowData
            )
          : null;
        const subHeadingColor = pageHeaderConfig && pageHeaderConfig.isConfigured && pageHeaderConfig.subHeadingColor ;
      let progressFactor = 0;
      let progressPercentage = 0;
        if(steps && steps.length > 0 ){

          progressFactor = (activeStep + 1)/(steps.length) ;
          progressPercentage = progressFactor * 100
        }
    const {
      layoutProperties: { uiActions },
      typeModel: { customerName },
    } = currentItem;
    const uiFilters = [];
    if (Array.isArray(uiActions)) {
      uiActions.map(a => {
        if (a.type === 'FILTER') {
          uiFilters.push(a);
        }
      });
    }

    return !showOnlySwitch ? (
      <div className='wf-item-header'>
  { stepperType === 'STEPPERTEMPLATE2' ?<div className='heading'>
          {showTitle && (
            <div className='d-flex align-items-center'>
              {parentSession && displayMode === 'DEFAULT' && false ? (
                <div className='mb-2'>
                  <EndWorkflowButton
                    iconClass='fa fa-long-arrow-left'
                    className='header-endworkflow-button text-muted'
                  />
                </div>
              ) : (
                ''
              )}
        <div className='headerHeadingDIV'>
      { stepperLogo &&   <div>
          <img src={stepperLogo} className='logoHeaderImage' alt="stepperLogo" />
         </div>}
              <h5 className='text'>
                {pageHeaderConfig && pageHeaderConfig.headerIcon ? (
                  <i
                    className={`fa ${pageHeaderConfig.headerIcon.cssClass} mr-1`}
                    aria-hidden='true'
                  ></i>
                ) : (
                  ''
                )}
                {headerText}
              </h5>
            {subHeaderText &&      <Badge count={subHeaderText} color={subHeadingColor?subHeadingColor:'green'} style={{marginLeft:10}}/>}
              </div>
              <div
                className={uiFilters.length ? 'filters' : 'no-filters'}
                style={{
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className='d-flex'>
                  {uiFilters.length ? (
                    <div>
                      {uiPluginFilterLabel ? uiPluginFilterLabel : 'Filters'}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {uiFilters.length
                  ? uiFilters.map((fil, fIndex) => {
                      const options =
                        this.state.filterModelsData[fil.filterModel];
                      if (fil.cityFilter) {
                        return (
                          <div key={fIndex} className='d-flex'>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedState}
                                options={this.state.states}
                                placeholder="Select State"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedState: value.value },
                                      () => {
                                        this.props.getCitiesData().then(res => {
                                          const defaultStateId =
                                            this.state.selectedState;
                                          if (Array.isArray(res.data)) {
                                            const cities = res.data
                                              .filter(
                                                city =>
                                                  city.state_id ===
                                                  defaultStateId
                                              )
                                              .map(city => {
                                                return {
                                                  value: city.name,
                                                  label: city.name,
                                                  countryId: city.state_id,
                                                  stateId: city.id,
                                                };
                                              });
                                            this.setState({ cities }, () => {});
                                          }
                                        });
                                      }
                                    );
                                  }
                                }}
                                className={'Select-Control'}
                                classNamePrefix={'Select-Control-inner'}
                              />
                            </div>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedCity}
                                options={this.state.cities}
                                placeholder="Select City"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedCity: value.value },
                                      () => {
                                        this.props.setUIFilter({
                                          key: fil.paramName,
                                          value: this.state.selectedCity,
                                        });
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={fIndex}
                          style={{ minWidth: '200px', marginLeft: '20px' }}
                        >
                          <Label className={"report-filter-label"}>
                            {fil.placeholderText}
                          </Label>
                          <Select
                            valueKey={fil.keyField}
                            labelKey={fil.labelField}
                            value={
                              this.props.uiFilters &&
                              this.props.uiFilters[fil.keyField]
                            }
                            placeholder={`${fil.placeholderText}`}
                            options={options}
                            onChange={value => {
                              value
                                ? this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: value[fil.keyField],
                                  })
                                : this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: null,
                                  });
                            }}
                            className={'Select-Control'}
                            classNamePrefix={'Select-Control-inner'}
                          />
                        </div>
                      );
                    })
                  : ''}
              </div>
              <div className='d-flex justify-content-end'>
          <div className='d-flex'>
            <div className='actions ml-1'>
              {actions.globals.length > 0 ? (
                <RenderActions
                  workflow={workflow}
                  actions={actions.globals}
                  menuTypeGlobalAction={menuTypeGlobalAction}
                  actionsDDOpen={this.state.actionsDDOpen}
                  toggle={this.toggle.bind(this, 'actionsDDOpen')}
                />
              ) : (
                ''
              )}
            </div>
            {allowDownloadAsPdf ? (
              <div
                className={
                  containerIndex == 0
                    ? 'view-mode-selector d-flex display_PDFc0 '
                    : 'view-mode-selector d-flex hide_PDFc0'
                }
                data-html2canvas-ignore='true'
              >
                <Button
                  onClick={downloadAsOnePagePDF ? saveAsOnePagePDF : saveAsPDF}
                  className='ml-1 pdf_DownloadButton'
                  title='Download As PDF'
                  color='success'
                  size='sm'
                >
                  <i
                    className={
                      downloadPdfButtonLabel
                        ? 'fa fa-file-pdf-o pdf_downloaIcon mr-2 p-1'
                        : 'fa fa-file-pdf-o pdf_downloaIcon'
                    }
                    aria-hidden='true'
                  ></i>
                  {downloadPdfButtonLabel ? downloadPdfButtonLabel : ''}
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
            </div>
          )}
        </div>:stepperType !== 'PROGRESSBARSTEPPER'  ?     
    <div className='heading'>
          {showTitle && (
            <div className='d-flex align-items-center'>
              {parentSession && displayMode === 'DEFAULT' ? (
                <div className='mb-2'>
                  <EndWorkflowButton
                    iconClass='fa fa-long-arrow-left'
                    className='header-endworkflow-button text-muted'
                  />
                </div>
              ) : (
                ''
              )}
        <div className='headerHeadingDIV'>
              <h5 className='text'>
                {pageHeaderConfig && pageHeaderConfig.headerIcon ? (
                  <i
                    className={`fa ${pageHeaderConfig.headerIcon.cssClass} mr-1`}
                    aria-hidden='true'
                  ></i>
                ) : (
                  ''
                )}
                {headerText}
              </h5>
            {subHeaderText &&      <Badge count={subHeaderText} color={subHeadingColor?subHeadingColor:'green'} style={{marginLeft:10}}/>}
              </div>
              <div
                className={uiFilters.length ? 'filters' : 'no-filters'}
                style={{
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className='d-flex'>
                  {uiFilters.length ? (
                    <div>
                      {uiPluginFilterLabel ? uiPluginFilterLabel : 'Filters'}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {uiFilters.length
                  ? uiFilters.map((fil, fIndex) => {
                      const options =
                        this.state.filterModelsData[fil.filterModel];
                      if (fil.cityFilter) {
                        return (
                          <div key={fIndex} className='d-flex'>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedState}
                                options={this.state.states}
                                placeholder="Select State"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedState: value.value },
                                      () => {
                                        this.props.getCitiesData().then(res => {
                                          const defaultStateId =
                                            this.state.selectedState;
                                          if (Array.isArray(res.data)) {
                                            const cities = res.data
                                              .filter(
                                                city =>
                                                  city.state_id ===
                                                  defaultStateId
                                              )
                                              .map(city => {
                                                return {
                                                  value: city.name,
                                                  label: city.name,
                                                  countryId: city.state_id,
                                                  stateId: city.id,
                                                };
                                              });
                                            this.setState({ cities }, () => {});
                                          }
                                        });
                                      }
                                    );
                                  }
                                }}
                                className={'Select-Control'}
                                classNamePrefix={'Select-Control-inner'}
                              />
                            </div>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedCity}
                                options={this.state.cities}
                                placeholder="Select City"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedCity: value.value },
                                      () => {
                                        this.props.setUIFilter({
                                          key: fil.paramName,
                                          value: this.state.selectedCity,
                                        });
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={fIndex}
                          style={{ minWidth: '200px', marginLeft: '20px' }}
                        >
                          <Label className={"report-filter-label"}>
                            {fil.placeholderText}
                          </Label>
                          <Select
                            valueKey={fil.keyField}
                            labelKey={fil.labelField}
                            value={
                              this.props.uiFilters &&
                              this.props.uiFilters[fil.keyField]
                            }
                            placeholder={`${fil.placeholderText}`}
                            options={options}
                            onChange={value => {
                              value
                                ? this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: value[fil.keyField],
                                  })
                                : this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: null,
                                  });
                            }}
                            className={'Select-Control'}
                            classNamePrefix={'Select-Control-inner'}
                          />
                        </div>
                      );
                    })
                  : ''}
              </div>
              <div className='d-flex justify-content-end'>
          <div className='d-flex'>
            <div className='actions ml-1'>
              {actions.globals.length > 0 ? (
                <RenderActions
                  workflow={workflow}
                  actions={actions.globals}
                  menuTypeGlobalAction={menuTypeGlobalAction}
                  actionsDDOpen={this.state.actionsDDOpen}
                  toggle={this.toggle.bind(this, 'actionsDDOpen')}
                />
              ) : (
                ''
              )}
            </div>
            {allowDownloadAsPdf ? (
              <div
                className={
                  containerIndex == 0
                    ? 'view-mode-selector d-flex display_PDFc0 '
                    : 'view-mode-selector d-flex hide_PDFc0'
                }
                data-html2canvas-ignore='true'
              >
                <Button
                  onClick={downloadAsOnePagePDF ? saveAsOnePagePDF : saveAsPDF}
                  className='ml-1 pdf_DownloadButton'
                  title='Download As PDF'
                  color='success'
                  size='sm'
                >
                  <i
                    className={
                      downloadPdfButtonLabel
                        ? 'fa fa-file-pdf-o pdf_downloaIcon mr-2 p-1'
                        : 'fa fa-file-pdf-o pdf_downloaIcon'
                    }
                    aria-hidden='true'
                  ></i>
                  {downloadPdfButtonLabel ? downloadPdfButtonLabel : ''}
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
            </div>
          )}
        </div>:    <div className='heading-progressBarStepper'>
          {showTitle && (
            <div style={{alignItems:'center',display:'flex',    padding: parentSession && displayMode === 'DEFAULT'?0:10
          }}>
                   <div className='d-flex'>
              {parentSession && displayMode === 'DEFAULT' ? (
           
                  <EndWorkflowButton
                    iconClass='fa fa-long-arrow-left'
                    className='header-endworkflow-button text-muted'
                  />
         
               

              ) : (
               <div style={{marginLeft:10}}>

               </div>
              )}
                            <div className='headerHeadingDIV-2'>
              <h5 className='text'>
                {pageHeaderConfig && pageHeaderConfig.headerIcon ? (
                  <i
                    className={`fa ${pageHeaderConfig.headerIcon.cssClass} mr-1`}
                    aria-hidden='true'
                  ></i>
                ) : (
                  ''
                )}
                {headerText}
              </h5>
            {subHeaderText &&      <Badge count={subHeaderText} color={subHeadingColor?subHeadingColor:'green'} style={{marginLeft:10}}/>}
              </div>
               </div>
              <div>
    
           
              </div>
              <div
                className={uiFilters.length ? 'filters' : 'no-filters'}
                style={{
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className='d-flex'>
                  {uiFilters.length ? (
                    <div>
                      {uiPluginFilterLabel ? uiPluginFilterLabel : 'Filters'}
                    </div>
                  ) : (
                    ''
                  )}
                     <div className='stepperProgressBar'>
                      <div className='firstLineStepper'>
                <span>  {steps && steps[activeStep].title} </span> <span style={{fontWeight:'bold'}}>  Step {activeStep + 1} of {steps.length}</span>    </div>
                 <Progress percent={progressPercentage} showInfo={false}  />
              </div>
                </div>
                {uiFilters.length
                  ? uiFilters.map((fil, fIndex) => {
                      const options =
                        this.state.filterModelsData[fil.filterModel];

                      if (fil.cityFilter) {
                        return (
                          <div key={fIndex} className='d-flex'>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedState}
                                options={this.state.states}
                                placeholder="Select State"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedState: value.value },
                                      () => {
                                        this.props.getCitiesData().then(res => {
                                          const defaultStateId =
                                            this.state.selectedState;
                                          if (Array.isArray(res.data)) {
                                            const cities = res.data
                                              .filter(
                                                city =>
                                                  city.state_id ===
                                                  defaultStateId
                                              )
                                              .map(city => {
                                                return {
                                                  value: city.name,
                                                  label: city.name,
                                                  countryId: city.state_id,
                                                  stateId: city.id,
                                                };
                                              });
                                            this.setState({ cities }, () => {});
                                          }
                                        });
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{ minWidth: '200px', marginLeft: '20px' }}
                            >
                              <Select
                                valueKey={'value'}
                                labelKey={'label'}
                                value={this.state.selectedCity}
                                options={this.state.cities}
                                placeholder="Select City"
                                onChange={value => {
                                  if (value) {
                                    this.setState(
                                      { selectedCity: value.value },
                                      () => {
                                        this.props.setUIFilter({
                                          key: fil.paramName,
                                          value: this.state.selectedCity,
                                        });
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div
                          key={fIndex}
                          style={{ minWidth: '200px', marginLeft: '20px' }}
                        >
                          <Select
                            valueKey={fil.keyField}
                            labelKey={fil.labelField}
                            value={
                              this.props.uiFilters &&
                              this.props.uiFilters[fil.keyField]
                            }
                            options={options}
                            onChange={value => {
                              value
                                ? this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: value[fil.keyField],
                                  })
                                : this.props.setUIFilter({
                                    key: fil.paramName,
                                    value: null,
                                  });
                            }}
                          />
                        </div>
                      );
                    })
                  : ''}
              </div>
              <div className='d-flex justify-content-end'>
          <div className='d-flex'>
            <div className='actions ml-1'>
              {actions.globals.length > 0 ? (
                <RenderActions
                  workflow={workflow}
                  actions={actions.globals}
                  menuTypeGlobalAction={menuTypeGlobalAction}
                  actionsDDOpen={this.state.actionsDDOpen}
                  toggle={this.toggle.bind(this, 'actionsDDOpen')}
                />
              ) : (
                ''
              )}
            </div>
            {allowDownloadAsPdf ? (
              <div
                className={
                  containerIndex == 0
                    ? 'view-mode-selector d-flex display_PDFc0 '
                    : 'view-mode-selector d-flex hide_PDFc0'
                }
                data-html2canvas-ignore='true'
              >
                <Button
                  onClick={downloadAsOnePagePDF ? saveAsOnePagePDF : saveAsPDF}
                  className='ml-1 pdf_DownloadButton'
                  title='Download As PDF'
                  color='success'
                  size='sm'
                >
                  <i
                    className={
                      downloadPdfButtonLabel
                        ? 'fa fa-file-pdf-o pdf_downloaIcon mr-2 p-1'
                        : 'fa fa-file-pdf-o pdf_downloaIcon'
                    }
                    aria-hidden='true'
                  ></i>
                  {downloadPdfButtonLabel ? downloadPdfButtonLabel : ''}
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
            </div>
          )}
        </div>
}
      
      </div>
    ) : null;
  }
}

const RenderActions = props => {
  return (
    <div>
      {props.actions.map((action, index) => {
        const { actionProperties } = action;
        if (
          (actionProperties && actionProperties.actionDisabled) ||
          actionProperties.elementType == 'MENULINK'
        ) {
          return null;
        }
        return (
          <div key={index} className='ml-1 d-inline-block'>
            <ActionContainer {...props} action={action} />
          </div>
        );
      })}
      {props.menuTypeGlobalAction && props.menuTypeGlobalAction.length > 0 && (
        <div className='ml-1 d-inline-block'>
          <Dropdown
            size='sm'
            isOpen={props.actionsDDOpen}
            toggle={props.toggle}
          >
            <DropdownToggle color='link'>
              <i className='fa fa-ellipsis-v fa-lg' aria-hidden='true'></i>
            </DropdownToggle>
            <DropdownMenu>
              {props.menuTypeGlobalAction.map((action, index) => {
                const { actionProperties } = action;
                if (actionProperties && actionProperties.actionDisabled) {
                  return null;
                }
                return (
                  <DropdownItem key={index}>
                    <ActionContainer {...props} action={action} />
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
export default CreateUpdateHeader;
