import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import * as ECharts from './ExternalReports';
import { getReportDataByAction } from '../../actions/reports';
import {
  Direction,
  Path,
  Area,
  PathTwo
} from './ExternalReports/DataReports';

const ExternalReport = props => {
  
  const {
    layoutItem: {
      itemProperties: { reportConfig, label },
    },
  } = props;
  console.log(label)
  if(label.text === 'Direction') {
    return <Direction />
  }
  if(label.text === 'Area') {
    return <Area />
  }
  if(label.text === 'Path') {
    return <Path />
  }
  if(label.text === 'Path Two') {
    return <PathTwo />
  }
  if (!reportConfig) return null;
  const { reportDefaultType } = reportConfig;
  const ReportComponent = reportDefaultType
    ? ECharts[reportDefaultType.code]
    : ECharts['PIECHART'];
  return (
    <div>
      <ReportComponent label={label} reportConfig={reportConfig} {...props} />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getReportDataByAction: payload => {
      return dispatch(getReportDataByAction(payload));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    graphHeight: 300,
    companyDetails: state.getIn(['company', 'details']),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ExternalReport));
