import React from 'react';
import { Alert } from 'reactstrap';
import { getMatchedAction } from '../workflow/action/workflowActionsUtils';
import { triggerAction } from '../../actions/workflow-actions';
import RenderIcon from '../commons/RenderIcon';
import {ModelPropertiesParser} from './../../components/commons/modelPropertiesParser'
import { Link } from 'react-router-dom';

const StaticLink = (props) => {
    const { layoutItem: { itemProperties: { staticLinkConfig } },customIcons } = props;
    if(!staticLinkConfig) return (
      <Alert color='warning' className='w-100'>
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Link not configured(Please configure the link)
      </Alert>
    )
    const { link, displayText,beforeDisplayText,afterDisplayText, openInNewWindow,checkValidation,staticLinkTemplate,iconBackgroundcolor,path,enablePath } = staticLinkConfig;

    if(staticLinkTemplate){
      return   <div className="static-link-div-t2" >
      {beforeDisplayText}
      <a style={{display:'flex',alignItems:'center'}}  target={openInNewWindow ? "_blank" : ""} href='#' className="static-link-t2" onClick={checkValidation ? props.onHandleSubmit(()=>{
        OnLinkClick(props)
      }):()=>{OnLinkClick(props)}}>
     { props.layoutItem.itemProperties.beforeIcon?<RenderIcon iconBackgroundcolor={iconBackgroundcolor} customIcons={customIcons} config={props.layoutItem.itemProperties.beforeIcon} />: null}
     <div className='displayText-staticLink-t2' >      {`  ${displayText} `}
      </div>
      </a>
      {afterDisplayText}
    </div>
    }
    if(enablePath && path){
    return   <div className="static-link-div" >
      {beforeDisplayText}
      <Link style={{display:'flex',alignItems:'center'}}  className='static-link' to={path} >
      <div className='displayText-staticLink'>      {`  ${displayText} `}
      </div>
      </Link>
      {afterDisplayText}
    </div>
    }
    return (
        <div className="static-link-div" >
          {beforeDisplayText}
          <a  style={{display:'flex',alignItems:'center'}}  target={openInNewWindow ? "_blank" : ""} href='#' className="static-link" onClick={checkValidation ? props.onHandleSubmit(()=>{
            OnLinkClick(props)
          }):()=>{OnLinkClick(props)}}>
         { props.layoutItem.itemProperties.beforeIcon?<RenderIcon  customIcons={customIcons} config={props.layoutItem.itemProperties.beforeIcon} />: null}
         <div className='displayText-staticLink'>      {`  ${displayText} `}
          </div>
          </a>
          {afterDisplayText}
        </div>
    );
}

const OnLinkClick = (props) => {
	const { layoutItem: { itemProperties: { staticLinkConfig } }, workflow: { currentItem },
    workflow, dispatch, workflowData} = props;
	const { link, displayText,addtoken, openInNewWindow, isTextualActionTriggerEnabled, textualActionTriggerCode,checkValidation } = staticLinkConfig;
    if (link) {
      let linkSet = null;
      let newLink = link;
      const user = JSON.parse(localStorage.getItem('user'));
      newLink = addtoken ?  newLink + `?t=${user.token}` : link
      

      linkSet = workflowData ? ModelPropertiesParser(link, workflowData): link;
      if(openInNewWindow){
        window.open(newLink, '_blank')
      }else{
        window.location.href = linkSet
      }
      
    }  else if (isTextualActionTriggerEnabled) {
      const mainAction = getMatchedAction(
        currentItem.inlineAction,
        textualActionTriggerCode
      );
      if (mainAction) {
        dispatch(triggerAction(mainAction, null, workflow));
      }
    }
}

export default StaticLink;




