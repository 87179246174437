import React from 'react';
import * as ReportTypes from './StaticReports';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import moment from 'moment';


const StaticReport = props => {
  const {
    layoutItem: {
      itemProperties: { staticReportConfig, label },
    },
    workflowData,
  } = props;

  const { reportDefaultType, xAxis, yAxis } = staticReportConfig;
  const ReportComp = reportDefaultType
    ? ReportTypes[reportDefaultType.code]
    : ReportTypes['LINECHART'];

  if (!staticReportConfig) return null;

  const data = [];

  const xAxisData = JSON.parse(ModelPropertiesParser(
    xAxis.parentField,
    workflowData
  ));

  if(Array.isArray(xAxisData)) {
    let parent = xAxis.parentField;
    parent = parent.replace(/{{/, "");
    parent = parent.replace(/}}/, "");
    xAxisData.map((entry) => {
      const xAxisField = xAxis.field.replace(`${parent}.`, "");
      let value = ModelPropertiesParser(
        xAxisField,
        entry
      )
      // console.log(new Date(parseInt(value)), parseInt(value))
      if(xAxis.isDateField) {
        value = moment(new Date(parseInt(value))).format(xAxis.dateFormat)
      }
      const axis = {
        xAxis: value
      }
      data.push(axis);
    });
  }

  if(Array.isArray(yAxis)) {
    yAxis.map((axis) => {
      const yAxisData = JSON.parse(ModelPropertiesParser(
        axis.parentField,
        workflowData
      ));
      if(Array.isArray(yAxisData)) {
        let parent = axis.parentField;
        parent = parent.replace(/{{/, "");
        parent = parent.replace(/}}/, "");
        yAxisData.map((entry, index) => {
          const yAxisField = axis.field.replace(`${parent}.`, "");
          const key = axis.label.replace(/ /g, "_");
          data[index][key] = ModelPropertiesParser(
            yAxisField,
            entry
          )
        });
      }
    })
  }
  
  if (data.length <= 0) {
    return <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 100,
      }}
    >
      <div>{label && label.show ? `No Report data for ${label.text}` : "No report data"}</div>
    </div>;
  }
  return (
    <div>
      <ReportComp xAxis={xAxis} yAxis={yAxis} data={data} />
    </div>
  );
};

export default StaticReport;
