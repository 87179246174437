import React from 'react';
import RenderCompanyLogo from '../commons/RenderCompanyLogo';
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import MobileMenuItems from './MobileMenuItems';
import PropTypes from 'prop-types';
import * as MenuTypes from './types';
import { SidebarComp } from './SidebarComp';
import DesktopWorkflowSidebarWithTopBarContaianer from '../../containers/DesktopWorkflowSidebarWithTopBarContaianer';

class SidebarWithTopbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavActive: false,
	  isScroll: false
    }
  }

  componentDidMount() {
    const { getCompanyDetails, user } = this.props;
	window.addEventListener('scroll', this.handleScroll);
    getCompanyDetails && getCompanyDetails();
  }

  componentWillReceiveProps(nextProps) {
    const { companyDetails, getCompanyDetails } = nextProps;
    if(!companyDetails) getCompanyDetails && getCompanyDetails();
  }
  toggleMobileMenu = () => {
    this.setState({ isMobileNavActive: !this.state.isMobileNavActive });
  }


  checkForTemperaryLinkWorkflow = () => {
    const { router: { route: { match: { path } } } } = this.context;
    return path === '/public/link/:linkId';
  }

  checkScreenerMonkeyApp = () => {
    return window && window.location && window.location.hostname.includes('screenermonkey');
  }
  
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll = (event) => {
	let scrollConst = false;
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	scrollConst = scrollTop != 0 ? true : false;

	this.setState({
	  isScroll: scrollConst
	});
  }
  

  render() {
    const { isMobileNavActive, isScroll } = this.state;
    const { currentLanguage, translate, isPublic, clearWorkflowState, baseWorkflowRoute, user, companyDetails, fixedSideBar } = this.props;
	
    const isTemperaryLinkWorkflow = this.checkForTemperaryLinkWorkflow();
    const isScreenerMonkeyApp = this.checkScreenerMonkeyApp();
 const LogoLink = user && user.user && user.user.email ? '/app/' : '/login';
    let MenuComponent = companyDetails && companyDetails.data && companyDetails.data.menuType && MenuTypes[companyDetails.data.menuType];
   const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;

    if(!MenuComponent) {
      MenuComponent = MenuTypes.LEVEL_ONE;
    }
    if(isTemperaryLinkWorkflow) {
      return <div></div>
    }

    return (
      <div className={`topbar-menu-items-container sideBarWithTopBarParent ${isScroll ? 'topbar-custom-class' : ''}`} 
      onContextMenu={(e) =>{ 
        disableRightClick &&  e.preventDefault() ;
     }}
      >
     <div style={{display:'flex',alignItems:"center"}} className='sideWithTopBarMenuLOGO'>
        {!fixedSideBar && <a onClick={this.props.toggleIsExpanded} className='sideWithTopbarMenuIcon'>   <i style={{color:"#000",margin:10,cursor:"pointer"}} className={` ${!this.props.isExpanded ? 'fa fa-bars' : 'fa fa-times'}`}></i>  </a>}
       <a className='sideWithTopbarLogo company-logo-container' href={LogoLink}  >
          <RenderCompanyLogo />
        </a>
      </div>
    
        {
          !isTemperaryLinkWorkflow && (
            <div style={{ display: 'initial' }}>
              <div className="menu-bar-mobile">
                <Button className={`menu-bar-mobile-button ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} onClick={this.toggleMobileMenu} color="primary" size="lg">
                  {
                    isMobileNavActive ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>
                  }
                </Button>
              </div>
              {/* <MobileMenuItems isScreenerMonkeyApp={isScreenerMonkeyApp} isActive={isMobileNavActive} isPublic={isPublic} logout={this.props.logout} baseWorkflowRoute={baseWorkflowRoute} /> */}
              <MenuComponent sideBarWithTopBar={true} isScreenerMonkeyApp={isScreenerMonkeyApp} isActive={isMobileNavActive} isPublic={isPublic} logout={this.props.logout} baseWorkflowRoute={baseWorkflowRoute}/>
            </div>
          )
        }
        <DesktopWorkflowSidebarWithTopBarContaianer baseWorkflowRoute={baseWorkflowRoute} isExpanded={this.props.isExpanded} toggleIsExpanded={this.props.toggleIsExpanded} {...this.props}/>
      </div>
    )
  }
}


  

SidebarWithTopbar.contextTypes = {
  router: PropTypes.object
}

export default SidebarWithTopbar;