import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PublicLayoutContainer from './containers/PublicLayoutContainer';
import AuthLayoutContainer from './containers/AuthLayoutContainer';
import DashboardLayoutContainer from './containers/DashboardLayoutContainer';
import ProfileContainer from './containers/ProfileContainer';
import LoginContainer from './containers/LoginContainer';
import SignUp from './components/pages/login/SignUp';
import ForgotPassword from './components/pages/login/ForgotPassword';
import SetPassword from './components/pages/login/SetPassword';
//Import Components
import PublicHome from './components/public/home';
import OpenHome from './components/public/open';
import AuthHome from './components/auth/home';
import NoMatch from './components/notFound';
import Dashboard from './components/dashboard/index';
import Profile from './components/profile/home';
import ProcessPayment from './components/pages/process-payment';
import AOS from 'aos';
import DataStore from './utils/DataStore';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import SetPasswordContainer from './containers/SetPasswordContainer';

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      React.createElement(layout, props, React.createElement(component, props))
    } />
  );
}

class App extends Component {

  componentDidMount() {
    AOS.init();
    let user = window.user ? window.user : localStorage.getItem('user') || null;
    user = typeof user === 'string' && JSON.parse(user);

    const pathname = window.location && window.location.pathname

    //SHould not be https://app....
    const hasApp = window.location.href.includes("/app") && !window.location.href.includes("://app");

    console.log(pathname, '->', hasApp);
    if(pathname === "/" && !user.email ){
       window.location.href =  '/login'
    }

    if(  !hasApp && window.location.href == `${window.location.protocol}//${window.location.hostname}/` && user.email   ){
			window.location.href = '/app';
		}

    if( !(window.location.href.includes("/app/setpassword") || window.location.href.includes("/app/forgotpassword")) && hasApp  && !user.email) {
      window.location.href =  '/login'
    }
 
  }

  render() {
    let totalQuantityAdded = 0;
    const globalOrderItems = localStorage.getItem('orderitems');
    const isTurboTradeCompany =   window && window.location && window.location.hostname.includes('turbotradeplus') ||   window && window.location && window.location.hostname.includes('bemycredapp') ||   window && window.location && window.location.hostname.includes('comeramoney')
    if (globalOrderItems) {
      const parsedData = JSON.parse(globalOrderItems);
      totalQuantityAdded = parsedData ? parsedData.length : 0;
    }

    return (
      <div className="neene-player-app h-100">
      <Switch>
        <RouteWithLayout layout={PublicLayoutContainer} exact path={"/login"} component={PublicHome} />
        <RouteWithLayout layout={PublicLayoutContainer} path="/open" component={OpenHome} />
        <RouteWithLayout layout={PublicLayoutContainer} exact path="/public/link/:linkId" component={PublicHome} />
        <Route path="/processpayment" component={ProcessPayment} />
        <Route path="/welcome" component={LoginContainer} />
        <Route path="/app/signup" component={SignUp} />
        <Route path="/app/forgotpassword" component={ForgotPasswordContainer} />
        <Route path="/app/setpassword/:reflink" component={SetPasswordContainer} />
        
        <Switch>
          <RouteWithLayout layout={AuthLayoutContainer} path="/app" component={AuthHome} />
          <RouteWithLayout layout={DashboardLayoutContainer} path="/dashboard" component={Dashboard} />
          <RouteWithLayout layout={ProfileContainer} path="/profile" component={Profile} />
          <Route path='*' component={NoMatch} />
          {/* <Route component={NoMatch}/> */}
        </Switch>
        </Switch>
      </div>
    );
  }
}

export default App;
