import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import StateToPdfMake from '../commons/stateToPdfMake';
import { convertFromRaw } from 'draft-js';
import companyLogoBase64 from './companyLogoBase64';
import { stateToHTML } from 'draft-js-export-html';
import config from '../../config'
import { startsWith } from 'lodash';

const HtmlToReact = require('html-to-react');
var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

export const entityStyleFn = (workflowData, entity) => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'image') {
        const data = entity.getData();
        return {
            element: 'img',
            attributes: {
                src: data.src,
                alignment: data.alignment
            },
            style: {
                maxWidth: '100%',
                height: 'auto',
                width: `${data.width}%`
            },
        };
    }
    if (entityType === 'draft-js-video-plugin-video') {
        const data = entity.getData();
        return {
            element: 'video',
            attributes: {
                src: data.src,
                alignment: data.alignment,
                autoplay: 'autoplay',
                controls: 'controls'
            },
            style: {
                maxWidth: '100%',
                height: 'auto',
                width: `${data.width}%`
            },
        };
    }
    if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: {
            href: data.url,
            target:'_blank'
          },
          style: {
            // Put styles here...
          },
        };
      } 
    if ( entityType === 'MODEL_PROPERTY'.toLowerCase()) {
        const data = entity.getData();
        const parsedData = ModelPropertiesParser(data.key, workflowData);
        if(React.isValidElement(parsedData)) {
            let contentState = null;
            if(parsedData.props.rawContent.includes("***|||***")) {
                const richTextContent = parsedData.props.rawContent.split("***|||***");
                contentState = convertFromRaw(JSON.parse(richTextContent[0]));
            } else {
                contentState = convertFromRaw(JSON.parse(parsedData.props.rawContent));
            }
            let options = {
                inlineStyles: { ...colorStyleMap },
                entityStyleFn: entityStyleFn.bind(this, workflowData)
            };
            const __HTML = stateToHTML(contentState, options);
            return {
                element: "span",
                attributes: {
                    data: __HTML,
                    isRichText: true,
                    ismodelproperty: true
                }
            }
        }
        return {
            element: 'span',
            attributes: {
                data: parsedData === "null" || parsedData === null ? "" : parsedData,
                ismodelproperty: true
            }
        };
    }
}

const getPdfStyles = () => {
    return {
        pdfBrandHeader: {
            fontSize: 16
        }
    }
}

const getBrandHeaderForPDF = () => {
    return {
        columns: [
            {
                image: `data:image/jpeg;base64,${companyLogoBase64}`,
                fit: [100, 100],
                alignment: 'left',
                width: "30%",
                margin: [10, 10, 0, 30],
            },
            {
                width: "65%",
                text: "LogiNext Solutions",
                alignment: "right",
                margin: [10, 20, 20, 30],
                color: '#777'
            }
        ],
        style: 'pdfBrandHeader'
    }
}

export const getPdfMakeContents = (rawContent, data) => {
    let contentState = convertFromRaw(rawContent);
    const blocks = contentState.get('blockMap').toList().toJS();
    const updatedContentState = contentState.toJS();
    updatedContentState.blocks = blocks;
    const stateToPdfMake = new StateToPdfMake(updatedContentState);
    const pdfmakeContents = stateToPdfMake.generate();
    pdfmakeContents.content.map((_con, index) => {
        if (typeof _con.text === 'string') {
            _con.text = ModelPropertiesParser(_con.text, data) + '\n\n';
        }
        if (Array.isArray(_con.text)) {
            _con.text.map((_tex) => {
                _tex.text = ModelPropertiesParser(_tex.text, data) + '\n\n';
            });
        }
    });
    pdfmakeContents.content.unshift({
        text: 'Address: A901 Supreme Business Park, South Avenue, Near Hiranandani Gardens, Powai, Mumbai, Maharashtra 400076',
        margin: [0, 30, 0, 15] // margin: [left, top, right, bottom]
    }, {
        canvas: [
            { type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1 }
        ]
    }, {
        text: '',
        margin: [0, 15, 0, 0] // margin: [left, top, right, bottom]
    });
    pdfmakeContents.header = getBrandHeaderForPDF();
    pdfmakeContents.styles = getPdfStyles();
    return pdfmakeContents;
}

export const base64ToArrayBuffer = (base64) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const colorStyleMap = {
    red: {
        style: {
            color: 'rgba(255, 0, 0, 1.0)'
        }
    },
    orange: {
        style: {
            color: 'rgba(255, 127, 0, 1.0)'
        }
    },
    yellow: {
        style: {
            color: 'rgba(180, 180, 0, 1.0)'
        }
    },
    green: {
        style: {
            color: 'rgba(0, 180, 0, 1.0)'
        }
    },
    blue: {
        style: {
            color: 'rgba(0, 0, 255, 1.0)'
        }
    },
    indigo: {
        style: {
            color: 'rgba(75, 0, 130, 1.0)'
        }
    },
    violet: {
        style: {
            color: 'rgba(127, 0, 255, 1.0)'
        }
    },
    white: {
        style: {
            color: 'rgba(255, 255, 255, 1.0)'
        }
    }
};

export const isValidNode = function () {
    return true;
};

export const processingInstructions = [
    {
        shouldProcessNode: function (node) {
            if (node.parent && node.parent.attribs) {
                if (node.parent.attribs.ismodelproperty) {
                    return true;
                }
            }
            return false;
        },
        processNode: function (node, children) {
            const parsedText = node.parent.attribs.data;
            if(node.parent.attribs.isrichtext)
                return <span dangerouslySetInnerHTML={{__html: parsedText}} ></span>;
                const linkParsendText = parsedText && parsedText.includes('/') && parsedText.includes('.') && (parsedText.endsWith('.png') || parsedText.endsWith('.jpeg') || parsedText.endsWith('.PDF') || parsedText.endsWith('.pdf') || parsedText.endsWith('.jpg') || parsedText.endsWith('.JPG') || parsedText.endsWith('.JPEG'))
                if(linkParsendText){
                    return <a target="_blank"  href={`${config.assetsBasePath}${parsedText}`}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${parsedText})`} </a>
                }
            return <span className='richhtext-variable-parsed-text'>{parsedText}</span>;
        }
    },
    {
        shouldProcessNode: function (node) {
            return node.name === 'figure' && node.children[0].name === 'img'
        },
        processNode: function (node, children) {
            const imgNode = children[0];
            const classes = `has-image align-image-${imgNode.props.alignment}`;
            let imageSrc = imgNode.props.src;
            if(imageSrc && (imageSrc.startsWith('http://') || imageSrc.startsWith('https://'))) {
                let imagePath = imageSrc.substring(imageSrc.lastIndexOf('/'));
                let  subPath = imageSrc.substring(0, imageSrc.lastIndexOf('/'));
                let companyName = subPath.substring(subPath.lastIndexOf('/')+1);
                imageSrc = config.assetsBasePath  + companyName  + imagePath;
             }
            return <figure key={`figure-key-${imgNode.key}`} className={classes}>
                <img src={imageSrc} className='img-fluid' />
            </figure>;
        }
    }, {
        shouldProcessNode: function (node) {
            return node.name === 'figure' && node.children[0].name === 'video'
        },
        processNode: function (node, children) {
            const videoNode = children[0];
            return <figure key={`figure-video-key-${videoNode.key}`}>
                <ReactPlayer controls width={`100%`} url={`${videoNode.props.src}`} />
            </figure>;
        }
    }, {
        shouldProcessNode: function (node) {
            return node.name === 'li';
        },  
        processNode: function (node, children) {
            var text = children.join(' ');
            var className = '';
            if(text.startsWith('{{')) {
                var closeIndex = text.indexOf('}}');
                if(closeIndex != -1) {
                    className = text.substring(2, closeIndex);
                    text = text.substring(closeIndex + 2);
                }
            }
            return <li className={className}>
                {text}
            </li>;
        }
    },
    {
        shouldProcessNode: function (node) {
            return true;
        },
        processNode: processNodeDefinitions.processDefaultNode
    }
];