import React from 'react';
import {  Collapse, Label } from 'reactstrap';

const Divider = (props) => {
  const itemProperties = props.layoutItem.itemProperties;
  const dividerConfig = itemProperties.dividerConfig;
  const label = itemProperties.label;
  if(dividerConfig && dividerConfig.collapse)
  {
    window['dividerCollapse']=true
    return <div className='d-flex' ><Label style={{
      marginTop: '-2%'
    }}>{label.show ?label.text : ''}</Label><div style={{
      borderTop: '1px solid', 
      borderColor: '#ccc',
      width:'100%'
    }}></div> <i style={{
      marginTop: '-7px'
    }} className={`icon fa ${!props.dividerCollapse ? 'fa-chevron-up' : 'fa-chevron-down'}`} onClick={() => 
        props.handleDividerToggler()}></i></div>
  }
  window['dividerCollapse']=false
  return <hr style={{
    height: '2px'
  }} />
}

export default Divider;