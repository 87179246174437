import React, {useEffect} from 'react';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { useEfr } from "../../utils/useEfr";


const FaceRecognition = (props) => {
  const {
    layoutItem: {
      itemProperties: { efrConfig },
    },
    workflowData,
    change
  } = props;
  const tokenField = efrConfig && efrConfig.tokenField;
  const configurationTokenField = efrConfig && efrConfig.configurationTokenField;
  const temporaryTokenField = efrConfig && efrConfig.temporaryTokenField;
  const codeField = efrConfig && efrConfig.codeField;
  const expiryField = efrConfig && efrConfig.expiryField;
  const fieldToUpdate = efrConfig && efrConfig.fieldToUpdate;


  if(!tokenField && !configurationTokenField && !temporaryTokenField && !codeField && !expiryField) return <div>Please configure EFR</div>
  const token = ModelPropertiesParser(tokenField, workflowData);
  const configurationToken = ModelPropertiesParser(configurationTokenField, workflowData);
  const temporaryToken = ModelPropertiesParser(temporaryTokenField, workflowData);
  const code = ModelPropertiesParser(codeField, workflowData);
  const expiry = ModelPropertiesParser(expiryField, workflowData);
  const secretKey = {
    "code": code,
    "value": temporaryToken,
    "expiry": expiry
  }

  console.log(secretKey);
  const { loading, data } = useEfr(token, configurationToken, secretKey, (faceData) => {
    change(fieldToUpdate, faceData);
  });
  // if(fieldToUpdate && data.status != undefined && data.status == true) {
    
  // } else if(data.status == false) {
  //   change(fieldToUpdate, data.errors[0].message);
  // }
  return (
    <div>
      <div className='loader' id='loader'></div>
      <>
      {loading ? <h1>Processing</h1> : <h1>EFR SDK</h1>}
      {data.status != undefined && data.status == true ? (
        <>
          <h1>Success</h1>
          <img src={data.thumbnail} />
        </>
      ) : data.status == false ? (
        <h1>{data.errors[0].message}</h1>
      ) : (
        ""
      )}
    </>
    </div>
  ) ;
};

export default FaceRecognition;
