import React from 'react';

import Chart from "react-apexcharts";


const Direction = () => {
  const D1 = {
    "series": [
      {
        "name": "north",
        "data": [
          {
            "x": 1996,
            "y": 322
          },
          {
            "x": 1997,
            "y": 324
          },
          {
            "x": 1998,
            "y": 329
          },
          {
            "x": 1999,
            "y": 342
          },
          {
            "x": 2000,
            "y": 348
          },
          {
            "x": 2001,
            "y": 334
          },
          {
            "x": 2002,
            "y": 325
          },
          {
            "x": 2003,
            "y": 316
          },
          {
            "x": 2004,
            "y": 318
          },
          {
            "x": 2005,
            "y": 330
          },
          {
            "x": 2006,
            "y": 355
          },
          {
            "x": 2007,
            "y": 366
          },
          {
            "x": 2008,
            "y": 337
          },
          {
            "x": 2009,
            "y": 352
          },
          {
            "x": 2010,
            "y": 377
          },
          {
            "x": 2011,
            "y": 383
          },
          {
            "x": 2012,
            "y": 344
          },
          {
            "x": 2013,
            "y": 366
          },
          {
            "x": 2014,
            "y": 389
          },
          {
            "x": 2015,
            "y": 334
          }
        ]
      },
      {
        "name": "south",
        "data": [
          {
            "x": 1996,
            "y": 162
          },
          {
            "x": 1997,
            "y": 90
          },
          {
            "x": 1998,
            "y": 50
          },
          {
            "x": 1999,
            "y": 77
          },
          {
            "x": 2000,
            "y": 35
          },
          {
            "x": 2001,
            "y": -45
          },
          {
            "x": 2002,
            "y": -88
          },
          {
            "x": 2003,
            "y": -120
          },
          {
            "x": 2004,
            "y": -156
          },
          {
            "x": 2005,
            "y": -123
          },
          {
            "x": 2006,
            "y": -88
          },
          {
            "x": 2007,
            "y": -66
          },
          {
            "x": 2008,
            "y": -45
          },
          {
            "x": 2009,
            "y": -29
          },
          {
            "x": 2010,
            "y": -45
          },
          {
            "x": 2011,
            "y": -88
          },
          {
            "x": 2012,
            "y": -132
          },
          {
            "x": 2013,
            "y": -146
          },
          {
            "x": 2014,
            "y": -169
          },
          {
            "x": 2015,
            "y": -184
          }
        ]
      }
    ],
    "options": {
      "chart": {
        "type": "area",
        "height": 350
      },
      "dataLabels": {
        "enabled": false
      },
      "stroke": {
        "curve": "straight"
      },
      "title": {
        "text": "Direction",
        "align": "left",
        "style": {
          "fontSize": "14px"
        }
      },
      "xaxis": {
        "type": "datetime",
        "axisBorder": {
          "show": false
        },
        "axisTicks": {
          "show": false
        }
      },
      "yaxis": {
        "tickAmount": 4,
        "floating": false,
        "labels": {
          "style": {
            "colors": "#8e8da4"
          },
          "offsetY": -7,
          "offsetX": 0
        },
        "axisBorder": {
          "show": false
        },
        "axisTicks": {
          "show": false
        }
      },
      "fill": {
        "opacity": 0.5
      },
      "tooltip": {
        "x": {
          "format": "yyyy"
        },
        "fixed": {
          "enabled": false,
          "position": "topRight"
        }
      },
      "grid": {
        "yaxis": {
          "lines": {
            "offsetX": -30
          }
        },
        "padding": {
          "left": 20
        }
      }
    }
  }
  return <div className="container-column col-md-12 offset-md-0">
    <Chart options={D1.options} series={D1.series} type="area" height={350} />
  </div>
}

const Path = () => {

  const D2 = {
    "series":[
       {
          "type":"rangeArea",
          "name":"Team B Range",
          "data":[
             {
                "x":"Jan",
                "y":[
                   1100,
                   1900
                ]
             },
             {
                "x":"Feb",
                "y":[
                   1200,
                   1800
                ]
             },
             {
                "x":"Mar",
                "y":[
                   900,
                   2900
                ]
             },
             {
                "x":"Apr",
                "y":[
                   1400,
                   2700
                ]
             },
             {
                "x":"May",
                "y":[
                   2600,
                   3900
                ]
             },
             {
                "x":"Jun",
                "y":[
                   500,
                   1700
                ]
             },
             {
                "x":"Jul",
                "y":[
                   1900,
                   2300
                ]
             },
             {
                "x":"Aug",
                "y":[
                   1000,
                   1500
                ]
             }
          ]
       },
       {
          "type":"rangeArea",
          "name":"Team A Range",
          "data":[
             {
                "x":"Jan",
                "y":[
                   3100,
                   3400
                ]
             },
             {
                "x":"Feb",
                "y":[
                   4200,
                   5200
                ]
             },
             {
                "x":"Mar",
                "y":[
                   3900,
                   4900
                ]
             },
             {
                "x":"Apr",
                "y":[
                   3400,
                   3900
                ]
             },
             {
                "x":"May",
                "y":[
                   5100,
                   5900
                ]
             },
             {
                "x":"Jun",
                "y":[
                   5400,
                   6700
                ]
             },
             {
                "x":"Jul",
                "y":[
                   4300,
                   4600
                ]
             },
             {
                "x":"Aug",
                "y":[
                   2100,
                   2900
                ]
             }
          ]
       },
       {
          "type":"line",
          "name":"Team B Median",
          "data":[
             {
                "x":"Jan",
                "y":1500
             },
             {
                "x":"Feb",
                "y":1700
             },
             {
                "x":"Mar",
                "y":1900
             },
             {
                "x":"Apr",
                "y":2200
             },
             {
                "x":"May",
                "y":3000
             },
             {
                "x":"Jun",
                "y":1000
             },
             {
                "x":"Jul",
                "y":2100
             },
             {
                "x":"Aug",
                "y":1200
             },
             {
                "x":"Sep",
                "y":1800
             },
             {
                "x":"Oct",
                "y":2000
             }
          ]
       },
       {
          "type":"line",
          "name":"Team A Median",
          "data":[
             {
                "x":"Jan",
                "y":3300
             },
             {
                "x":"Feb",
                "y":4900
             },
             {
                "x":"Mar",
                "y":4300
             },
             {
                "x":"Apr",
                "y":3700
             },
             {
                "x":"May",
                "y":5500
             },
             {
                "x":"Jun",
                "y":5900
             },
             {
                "x":"Jul",
                "y":4500
             },
             {
                "x":"Aug",
                "y":2400
             },
             {
                "x":"Sep",
                "y":2100
             },
             {
                "x":"Oct",
                "y":1500
             }
          ]
       }
    ],
    "options":{
       "chart":{
          "height":350,
          "type":"rangeArea",
          "animations":{
             "speed":500
          }
       },
       "colors":[
          "#d4526e",
          "#33b2df",
          "#d4526e",
          "#33b2df"
       ],
       "dataLabels":{
          "enabled":false
       },
       "fill":{
          "opacity":[
             0.24,
             0.24,
             1,
             1
          ]
       },
       "forecastDataPoints":{
          "count":2
       },
       "stroke":{
          "curve":"straight",
          "width":[
             0,
             0,
             2,
             2
          ]
       },
       "legend":{
          "show":true,
          "customLegendItems":[
             "Team B",
             "Team A"
          ],
          "inverseOrder":true
       },
       "title":{
          "text":"Range Area with Forecast Line"
       },
       "markers":{
          "hover":{
             "sizeOffset":5
          }
       }
    }
 }
  return <div className="container-column col-md-12 offset-md-0">
  <Chart options={D2.options} series={D2.series} type="rangeArea" height={350} />
</div>
}

const Area = () => {
  const D3 = {
    "series":[
       {
          "name":"TEAM A",
          "type":"column",
          "data":[
             23,
             11,
             22,
             27,
             13,
             22,
             37,
             21,
             44,
             22,
             30
          ]
       },
       {
          "name":"TEAM B",
          "type":"area",
          "data":[
             44,
             55,
             41,
             67,
             22,
             43,
             21,
             41,
             56,
             27,
             43
          ]
       },
       {
          "name":"TEAM C",
          "type":"line",
          "data":[
             30,
             25,
             36,
             30,
             45,
             35,
             64,
             52,
             59,
             36,
             39
          ]
       }
    ],
    "options":{
       "chart":{
          "height":350,
          "type":"line",
          "stacked":false
       },
       "stroke":{
          "width":[
             0,
             2,
             5
          ],
          "curve":"smooth"
       },
       "plotOptions":{
          "bar":{
             "columnWidth":"50%"
          }
       },
       "fill":{
          "opacity":[
             0.85,
             0.25,
             1
          ],
          "gradient":{
             "inverseColors":false,
             "shade":"light",
             "type":"vertical",
             "opacityFrom":0.85,
             "opacityTo":0.55,
             "stops":[
                0,
                100,
                100,
                100
             ]
          }
       },
       "labels":[
          "01/01/2003",
          "02/01/2003",
          "03/01/2003",
          "04/01/2003",
          "05/01/2003",
          "06/01/2003",
          "07/01/2003",
          "08/01/2003",
          "09/01/2003",
          "10/01/2003",
          "11/01/2003"
       ],
       "markers":{
          "size":0
       },
       "xaxis":{
          "type":"datetime"
       },
       "yaxis":{
          "title":{
             "text":"Points"
          }
       },
       "tooltip":{
          "shared":true,
          "intersect":false,
          "y":{
             "formatter": function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
        
            }
          }
       }
    }
 }
  return <div className="container-column col-md-12 offset-md-0">
  <Chart options={D3.options} series={D3.series} type="line" height={350} />
</div>
}

const PathTwo = () => {
  const D4 = {
    "series": [
      {
        "name": "Series 1",
        "data": [
          80,
          50,
          30,
          40,
          100,
          20
        ]
      }
    ],
    "options": {
      "chart": {
        "height": 350,
        "type": "radar"
      },
      "title": {
        "text": "Radar"
      },
      "yaxis": {
        "stepSize": 20
      },
      "xaxis": {
        "categories": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June"
        ]
      }
    }
  }
  return <div className="container-column col-md-12 offset-md-0">
  <Chart options={D4.options} series={D4.series} type="radar" height={350} />
</div>
}

export {
  Direction,
  Path,
  Area,
  PathTwo
}

