import React, { Component } from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { buildPdf } from '../../actions/uploader';
import StateToPdfMake from '../commons/stateToPdfMake';
import { 
    processingInstructions, 
    isValidNode, 
    colorStyleMap, 
    base64ToArrayBuffer, 
    getPdfMakeContents,
    entityStyleFn 
} from './RichTextResolverUtils';

const HtmlToReact = require('html-to-react');
const HtmlToReactParser = HtmlToReact.Parser;

class RichhTextResolver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfData: null,
            generatedSuccess: false
        }
    }
    generatePdf() {
        const { rawContent, workflowData } = this.props;
        const pdfmakeContents = getPdfMakeContents(rawContent, workflowData)
        this.props.buildPdf(pdfmakeContents).then((res) => {
            const bytes = base64ToArrayBuffer(res.data);
            var blob = new Blob([bytes]);
            var fileURL = window.URL.createObjectURL(blob);
            this.setState({ generatedSuccess: true, pdfData: fileURL });
        });
    }

    render() {
        const { workflowData, rawContent, richtextConfig, isTransparent,itemProperties, eleStyle } = this.props;
        const classNameRichText = richtextConfig && richtextConfig.classNameRichText ;
       const textAlignment = itemProperties && itemProperties.textAlignment && itemProperties.textAlignment.value
        const { generatedSuccess, pdfData } = this.state;
        const contentState = convertFromRaw(rawContent);
        let options = {
            inlineStyles: { ...colorStyleMap },
            entityStyleFn: entityStyleFn.bind(this, workflowData)
        };
        const __HTML = stateToHTML(contentState, options);
        
        const htmlToReactParser = new HtmlToReactParser();
        var instructions = (richtextConfig && richtextConfig.listClass)?processingInstructions.slice():processingInstructions;
        if(richtextConfig && richtextConfig.listClass) {
        instructions.unshift({
            shouldProcessNode: function (node) {
                return node.name === 'li';
            },
            processNode: function (node, children) {
                return <li className={richtextConfig.listClass.value}>
                {children.join(' ')}
                </li>;
            }
        });
    }
        // const updatedHtml = workflowData ? ModelPropertiesParser(__HTML, workflowData) : __HTML;
        // const _VALID_CHILD = htmlToReactParser.parseWithInstructions(updatedHtml, isValidNode, instructions);
        const _VALID_CHILD = htmlToReactParser.parseWithInstructions(__HTML, isValidNode, instructions);
        return (
            <div style={eleStyle} className={`richhtext-editor ${isTransparent?'richtext-transparent':''} ${classNameRichText} ${textAlignment === 'center' ? 'center-align-richtext':''} `}>
                <div className='d-flex justify-content-end'>
                    {
                        richtextConfig && richtextConfig.canDownloadPdf ? <div>
                            {
                                generatedSuccess ? 
                                    <Button download={`${ModelPropertiesParser(richtextConfig.pdfFileName, workflowData)}.pdf` || 'Download'} color='light' size='sm' href={pdfData}>
                                        <i className="fa fa-cloud-download mr-1" aria-hidden="true"></i>Download PDF
                                    </Button> :
                                    <Button onClick={this.generatePdf.bind(this)} color='light' size='sm'>
                                        <i className="fa fa-file-pdf-o mr-1" aria-hidden="true"></i>Generate PDF
                                    </Button>
                            }
                        </div> : ''
                    }
                </div>
                {_VALID_CHILD}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        buildPdf: (payload) => {
            return dispatch(buildPdf(payload))
        }
    }
}

export default connect(null, mapDispatchToProps)(toJS(RichhTextResolver));