import React, { Component } from 'react';
import { Input, FormGroup, Label, Button ,UncontrolledTooltip,Row,Col} from 'reactstrap';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import config from '../../config';
import client from '../../client';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { uploadFiles, clearUploader } from '../../actions/uploader';
import { renderErrorMessage } from './_FORM_FIELDS';
import { Field,change } from 'redux-form/immutable';
import { toast } from 'react-toastify';
import PrintText from './PrintText';
import RenderIcon from '../commons/RenderIcon';

let dropzoneRef;

class Uploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            classProperty: null,
            removedFiles: [],
            removed: false
        }
        this.uploaderToastId = null;
    }
    onDrop(accepted, rejected) {
        
        let fileSizeNumber = this.props.layoutItem && this.props.layoutItem.config && this.props.layoutItem.config.fileSizeNumber ? this.props.layoutItem.config.fileSizeNumber : 
        this.props.layoutItem.itemProperties.fileOptions && this.props.layoutItem.itemProperties.fileOptions.fileSizeNumber ? this.props.layoutItem.itemProperties.fileOptions.fileSizeNumber : this.props.layoutItem.itemProperties.imageConfig && this.props.layoutItem.itemProperties.imageConfig.fileSizeNumber
        let fileSize= this.props.layoutItem && this.props.layoutItem.config && this.props.layoutItem.config.fileSize  ? this.props.layoutItem.config.fileSize.value : 
            this.props.layoutItem.itemProperties.fileOptions && this.props.layoutItem.itemProperties.fileOptions.fileSize && 
            this.props.layoutItem.itemProperties.fileOptions.fileSize.value ? this.props.layoutItem.itemProperties.fileOptions.fileSize.value :
            this.props.layoutItem.itemProperties.imageConfig  && this.props.layoutItem.itemProperties.imageConfig.fileSize ?
            this.props.layoutItem.itemProperties.imageConfig.fileSize.value : '';
        let fileSizeName= this.props.layoutItem && this.props.layoutItem.config && this.props.layoutItem.config.fileSize ? 
            this.props.layoutItem.config.fileSize.name: this.props.layoutItem.itemProperties.fileOptions && this.props.layoutItem.itemProperties.fileOptions.fileSize &&
            this.props.layoutItem.itemProperties.fileOptions.fileSize.name ? this.props.layoutItem.itemProperties.fileOptions.fileSize.name 
            : this.props.layoutItem.itemProperties.imageConfig && this.props.layoutItem.itemProperties.imageConfig.fileSize ?
            this.props.layoutItem.itemProperties.imageConfig.fileSize.name : '';
        fileSizeNumber =  fileSizeNumber && parseInt(fileSizeNumber);
        fileSize = fileSize && parseInt(fileSize);
        const fileSizeTotal = fileSize * fileSizeNumber;
        let isValidFile = true;

        if(rejected && rejected.length > 0 ) {
            this.uploaderToastId = toast.error('Invalid file format...', {autoClose: true, position: toast.POSITION.TOP_CENTER});
        }
        else {
            accepted && accepted.map((file, index) => {
               if(file.size > fileSizeTotal){
                isValidFile = false;
                this.uploaderToastId = toast.error(`File size should be less than ${fileSizeNumber} ${fileSizeName}`, {autoClose: true, position: toast.POSITION.TOP_CENTER});
               }
            })
            if(isValidFile){
                this.uploaderToastId = toast.info('Upload in progress. Please wait...', {autoClose: false, position: toast.POSITION.TOP_CENTER});
                const isProtected = this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.fileOptions && this.props.layoutItem.itemProperties.fileOptions.isSecure;
                this.setState({ files: [...accepted], classProperty: this.props.classProperty, removed: false }, () => {
                    this.props.uploadFiles({ files: this.state.files, namespace: this.props.classProperty, isProtected })
                });
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        const { uploader, classProperty, formValues,inlineFormKey,dispatch } = nextProps;
        const { itemProperties } = nextProps.layoutItem;
        const { field } = itemProperties;
        if (uploader && !uploader.hasError && uploader.data && this.state.classProperty === classProperty) {
            const url = field.collection ? uploader.data.data : uploader.data.data[0];
			let fieldValue = formValues && formValues[`${classProperty}`];
			fieldValue = fieldValue && typeof fieldValue != 'string' ? fieldValue[0] : fieldValue;
			if(fieldValue != url[0] && !this.state.removed) {
                inlineFormKey && inlineFormKey.startsWith('workflow-card') ? dispatch(change('create-update-form-base',classProperty, url)): nextProps.change(classProperty, url);
				clearUploader(classProperty);
				toast.update(this.uploaderToastId, {
					render: "Upload Done!",
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					position: toast.POSITION.TOP_CENTER
				});
			}
            
        }
        if (uploader && uploader.hasError) {
            toast.update(this.uploaderToastId, {
                render: "Something went wrong. Could not upload file at this time.",
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    removeFile(index, classProperty) {
        let files = Object.assign([], this.state.files);
        files.splice(index, 1);
        this.setState({ files,removed: true },() => {
            this.props.change(classProperty,'')
        });
    }
    removeFileFromForm(name) {
        this.props.change(name,'')
        let removedFiles = Object.assign([], this.state.removedFiles);
        const found = removedFiles.find(ele => ele == name)
        !found && removedFiles.push(name);
        this.setState({ removedFiles });
    }
    render() {
        const { itemProperties } = this.props.layoutItem;
        const colConfig = this.props.layoutItem.config;
        const { uploader, validations, classProperty, isReadOne,customIcons,workflowData,dontShowDROPBOX, translate,itemClassPropertyFromTable } = this.props;
        let updatedClassProperty = this.props.classProperty
        let parentModelField = '';
        let indexChild = '';
        const fieldID = itemProperties && itemProperties.field && itemProperties.field.id;
        if(updatedClassProperty.indexOf('].') !== -1 ){

           parentModelField = updatedClassProperty.substring( 0,updatedClassProperty.indexOf('['))
           indexChild = updatedClassProperty.substring( updatedClassProperty.indexOf('[') + 1,updatedClassProperty.indexOf(']'))
           indexChild = parseInt(indexChild)
        }
        let workflowDataUpdated = workflowData && parentModelField && workflowData[parentModelField] ? workflowData[parentModelField][indexChild] : workflowData
        let  uploadedFileName = classProperty && classProperty.indexOf('{{') == -1 ? ModelPropertiesParser(`{{${classProperty}}}`, workflowData) : ModelPropertiesParser(classProperty, workflowData);
        if(itemClassPropertyFromTable) {
            uploadedFileName  = itemClassPropertyFromTable.indexOf('{{') == -1 ? ModelPropertiesParser(`{{${itemClassPropertyFromTable}}}`, workflowDataUpdated) : ModelPropertiesParser(itemClassPropertyFromTable, workflowDataUpdated);
        }
        const uploadFileNameUrl = uploadedFileName && uploadedFileName.startsWith('http') ? uploadedFileName : `${config.assetsBasePath}${uploadedFileName}`
        
        const { label, field, placeholder,footer ,info} = itemProperties;
        const fileFormat = colConfig && colConfig.fileFormat ? colConfig.fileFormat : itemProperties && itemProperties.imageConfig && itemProperties.imageConfig.fileFormat ? itemProperties.imageConfig.fileFormat : null
        const imageFormatSelection2 = itemProperties && itemProperties.imageConfig && itemProperties.imageConfig.imageFormatSelection ? itemProperties.imageConfig.imageFormatSelection : null
        const onlyIconDropboxImage = itemProperties && itemProperties.imageConfig && itemProperties.imageConfig.onlyIconDropbox ? itemProperties.imageConfig.onlyIconDropbox : null
        const fileFormatUploader =  colConfig && colConfig.fileFormat ? colConfig.fileFormat : itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.fileFormat ? itemProperties.fileOptions.fileFormat : null
        const fileboxtemplate =   itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.fileboxtemplate ? itemProperties.fileOptions.fileboxtemplate : null
        const fileFormatSelection = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.fileFormatSelection ? itemProperties.fileOptions.fileFormatSelection : null
        const hideDropBox = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.hideDropBox ? itemProperties.fileOptions.hideDropBox : null
        const fileboxtemplateImage = itemProperties && itemProperties.imageConfig && itemProperties.imageConfig.fileboxtemplate ? itemProperties.imageConfig.fileboxtemplate : null
         const allFormatSelection = colConfig && colConfig.allFormatSelection? colConfig.allFormatSelection : itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.allFormatSelection ? itemProperties.fileOptions.allFormatSelection : null
        const imageFormatSelection = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.imageFormatSelection ? itemProperties.fileOptions.imageFormatSelection : null
        const videoFormatSelection = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.videoFormatSelection ? itemProperties.fileOptions.videoFormatSelection : null
        const audioFormatSelection = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.audioFormatSelection ? itemProperties.fileOptions.audioFormatSelection : null
        const onlyIconDropboxFile = itemProperties && itemProperties.fileOptions && itemProperties.fileOptions.onlyIconDropbox ? itemProperties.fileOptions.onlyIconDropbox : dontShowDROPBOX ? dontShowDROPBOX : null;
        const onlyIconDropboxVideoFile = itemProperties && itemProperties.videoConfig && itemProperties.videoConfig.onlyIconDropbox ? itemProperties.videoConfig.onlyIconDropbox : null
        const minLength = itemProperties && itemProperties.minLength ? itemProperties.minLength : null
        const { files, removedFiles } = this.state;
        const removedFile = removedFiles.find(ele => ele == classProperty);
        let translatedLabel = label.text;
        if(label.languageKey) {
            translatedLabel = translate(`label.${label.languageKey.toLowerCase().replace(/ /g, "_")}`);
            translatedLabel = translatedLabel.startsWith("Missing translation") ? label.text: translatedLabel;
        }
        if (isReadOne) {
            return <PrintText {...this.props} />
        }
        if(hideDropBox){
            return (
                <FormGroup className='player-dropzone-uploader-wrapper'>
                {
                  label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
              }
                    <Field
                        name={classProperty}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                    </FormGroup>
            )
        }
        if ((fileFormat && fileFormat.value ===  'image') || (fileFormatUploader && fileFormatUploader.value ===  'image')){
            let acceptImage = []
            {
                imageFormatSelection &&  imageFormatSelection.map((imageFormat, index) => {
                    acceptImage.push(imageFormat.value) 
                })
            }
            {
                imageFormatSelection2 && imageFormatSelection2.map((imageFormat, index) => {
                    acceptImage.push(imageFormat.value) 
                })
            }
          const  acceptOnly  = acceptImage.length > 0  ? acceptImage.join(',') : "image/png, image/gif, image/jpeg, image/svg+xml,image/tiff,image/x-icon"
            return (
                <FormGroup className='player-dropzone-uploader-wrapper'>
               {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                    <Field
                        name={classProperty}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                    
                   <Dropzone multiple={field.collection} className='player-dropzone-uploader' accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
                        <div className='d-flex flex-column pt-2 pb-2'>
                            <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                            <p className='text-center mb-1 mt-0'>Drag &amp; Drop Image files here</p>
                            <p className='text-center mb-1 mt-0'>or</p>
                            <p className='text-center mb-0'>
                                <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                            </p>
                        </div>
                    </Dropzone>
    
                    <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                    { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !removedFile && <div>
                        {
                            uploadedFileName.startsWith('protected/') ? <a href={""} onClick={(e) => {
                                e.preventDefault();
                                client().get(`${config.playerApi}/${`uploadedFileName`}`).then(res => {
                                    window.open(res.data);
                                }).catch(e => {
                                    console.log(e)
                                })

                            }}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a> : <a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
                        }
                    <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
                    }
                    {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
                </FormGroup>
            );
    
        }
        if ((fileFormat && fileFormat.value ===  'audio') || (fileFormatUploader && fileFormatUploader.value ===  'audio')){
            let acceptAudio = []
            {
                audioFormatSelection.map((audioFormat, index) => {
                    acceptAudio.push(audioFormat.value) 
                })
            }
          const  acceptOnly  = acceptAudio.length > 0  ? acceptAudio.join(',') : "audio/mpeg, audio/ogg, audio/wav"
            return (
                <FormGroup className='player-dropzone-uploader-wrapper'>
                {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                    <Field
                        name={classProperty}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                    
                   <Dropzone multiple={field.collection} className='player-dropzone-uploader' accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
                        <div className='d-flex flex-column pt-2 pb-2'>
                            <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                            <p className='text-center mb-1 mt-0'>Drag &amp; Drop Audio files here</p>
                            <p className='text-center mb-1 mt-0'>or</p>
                            <p className='text-center mb-0'>
                                <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                            </p>
                        </div>
                    </Dropzone>
    
                    <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                    { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !removedFile && <div>{
                            uploadedFileName.startsWith('protected/') ? <a href={""} onClick={(e) => {
                                e.preventDefault()
                                client().get(`${config.playerApi}/${uploadedFileName}`).then(res => {
                                    window.open(res.data);
                                }).catch(e => {
                                    console.log(e)
                                })

                            }}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a> : <a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
                        }
                    <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
                    }
                    {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
                </FormGroup>
            );
    
        }
        if ((fileFormat && fileFormat.value ===  'video') || (fileFormatUploader && fileFormatUploader.value ===  'video')){
            let acceptVideo = []
            {
                videoFormatSelection.map((videoFormat, index) => {
                    acceptVideo.push(videoFormat.value) 
                })
            }
          const  acceptOnly  = acceptVideo.length > 0  ? acceptVideo.join(',') : "video/avi, video/mp4 , video/ogg,video/x-ms-asf,video/webm"
            return (
                <FormGroup className='player-dropzone-uploader-wrapper'>
                 {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                    <Field
                        name={classProperty}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                    
                   <Dropzone multiple={field.collection} className='player-dropzone-uploader' accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
                        <div className='d-flex flex-column pt-2 pb-2'>
                            <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                            <p className='text-center mb-1 mt-0'>Drag &amp; Drop Video files here</p>
                            <p className='text-center mb-1 mt-0'>or</p>
                            <p className='text-center mb-0'>
                                <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                            </p>
                        </div>
                    </Dropzone>
    
                    <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                    { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !removedFile && <div><a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
                    <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>}
                    {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
                </FormGroup>
            );
    
        }
        if ((fileFormat && fileFormat.value ===  'file') || (fileFormatUploader && fileFormatUploader.value ===  'file')){
          let acceptFile = []
            {
                fileFormatSelection.map((fileFormat, index) => {
                    acceptFile.push(fileFormat.value) 
                })
            }
          const  acceptOnly  = acceptFile.length > 0   ? acceptFile.join(',') : "application/doc, application/excel, application/mspowerpoint,application/pdf,application/json,.csv,.css"
            return (
                <FormGroup className='player-dropzone-uploader-wrapper'>
                 {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                    <Field
                        name={classProperty}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                    
                   <Dropzone multiple={field.collection} className='player-dropzone-uploader' accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
                        <div className='d-flex flex-column pt-2 pb-2'>
                            <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                            <p className='text-center mb-1 mt-0'>Drag &amp; Drop files here</p>
                            <p className='text-center mb-1 mt-0'>or</p>
                            <p className='text-center mb-0'>
                                <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                            </p>
                        </div>
                    </Dropzone>
    
                    <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                    { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !removedFile &&  <div>{
                            uploadedFileName.startsWith('protected/') ? <a href={""} onClick={(e) => {
                                e.preventDefault()
                                client().get(`${config.playerApi}/${uploadedFileName}`).then(res => {
                                    console.log(res);
                                    window.open(res.data);
                                }).catch(e => {
                                    console.log(e)
                                })

                            }}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a> : <a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
                        }
                    <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
                    }
                    {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
                </FormGroup>
            );
    
        }
        if ((fileFormatUploader && fileFormatUploader.value ===  'all')){
            let acceptFile = []
              {
                  allFormatSelection.map((fileFormat, index) => {
                      acceptFile.push(fileFormat.value) 
                  })
              }
            const  acceptOnly  = acceptFile.length > 0   ? acceptFile.join(',') : "application/doc, application/excel, application/mspowerpoint,application/pdf,application/json,.csv,.css"
              return (
                <FormGroup className={!dontShowDROPBOX?'player-dropzone-uploader-wrapper':'player-dropzone-uploader-wrapper create-update-table-dropbox'} >
                  {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                      { !dontShowDROPBOX &&    <Field
                          name={classProperty}
                          component={renderErrorMessage}
                          validate={validations}
                      />}
                     {   !(onlyIconDropboxImage || onlyIconDropboxFile || onlyIconDropboxVideoFile ) ?  <Dropzone multiple={field.collection} className='player-dropzone-uploader' accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
               <div className='d-flex flex-column pt-2 pb-2'>
                        <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                        <p className='text-center mb-1 mt-0'>Drag &amp; Drop files here</p>
                        <p className='text-center mb-1 mt-0'>or</p>
                        <p className='text-center mb-0'>
                            <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                        </p>
                    </div>     
                </Dropzone> : 
                <Dropzone multiple={field.collection} className={!dontShowDROPBOX ? 'player-dropzone-uploader-only-icon-wrapper ':'player-dropzone-uploader-only-icon-wrapper create-update-table-icon-wrapper'} accept={acceptOnly} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>{itemProperties.beforeIcon?<RenderIcon className="player-dropzone-uploader-only-icon" config={itemProperties.beforeIcon} customIcons={customIcons} color={itemProperties.textColor?itemProperties.textColor.value:''} />:dontShowDROPBOX ?       <i className="fa fa-upload" style={{fontSize:18}} aria-hidden="true"></i> : null}</Dropzone>}

      
                    
                      <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                      { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !dontShowDROPBOX  &&  !removedFile && <div><a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
                      <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
                      }
                      { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && dontShowDROPBOX && !removedFile &&  <div><a  target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download`}</a>
                      <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
                      }
            {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
                  { dontShowDROPBOX &&    <Field
                          name={classProperty}
                          component={renderErrorMessage}
                          validate={validations}
                      />}
                      
                  </FormGroup>
              );
      
        }
        if((fileboxtemplate && fileboxtemplate.value === 'template3') || (fileboxtemplateImage && fileboxtemplateImage.value === 'template3')){
            return        <FormGroup className={!dontShowDROPBOX?'player-dropzone-uploader-wrapper-template3':'player-dropzone-uploader-wrapper create-update-table-dropbox-template3'} >
            {
                 label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}
                 {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
                     <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
                     {info.text}
                 </UncontrolledTooltip>
                 </>
                 }
                 
                 </Label> : ''
             }
             <Field
                 name={classProperty}
                 component={renderErrorMessage}
                 validate={validations}
             />
            {   !(onlyIconDropboxImage || onlyIconDropboxFile || onlyIconDropboxVideoFile ) ?  <Dropzone multiple={field.collection} className='player-dropzone-uploader-template3' accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
            <div className='d-flex flex-row pt-2 pb-2 intoBox-template3'>
                  
                     <p className=' draganddrop'>Drag &amp; Drop Files Here |     <p className='filesize-player-template3'> Max Size : 10mb</p></p> 
                 
                     <p className='text-center'><i className="fa fa-2x fa-upload text-info" aria-hidden="true"></i></p>
                    
                 </div>     
             </Dropzone> : 
             <Dropzone multiple={field.collection} className={!dontShowDROPBOX ? 'player-dropzone-uploader-only-icon-wrapper ':'player-dropzone-uploader-only-icon-wrapper create-update-table-icon-wrapper'} accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>{itemProperties.beforeIcon?<RenderIcon className="player-dropzone-uploader-only-icon" config={itemProperties.beforeIcon} customIcons={customIcons} color={itemProperties.textColor?itemProperties.textColor.value:''} />:dontShowDROPBOX ?       <i className="fa fa-upload" style={{fontSize:18}} aria-hidden="true"></i> : null}</Dropzone>}

             <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
        { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !dontShowDROPBOX && !removedFile && <div><a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
        <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                             <i className="fa fa-times text-danger" aria-hidden="true"></i>
                         </Button></div>
        }
        { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && dontShowDROPBOX && !removedFile && <div><a  target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download`}</a>
        <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                             <i className="fa fa-times text-danger" aria-hidden="true"></i>
                         </Button></div>
        }
           
             {
               footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                 }
         </FormGroup>
        }
        if((fileboxtemplate && fileboxtemplate.value ==='template2') || (fileboxtemplateImage && fileboxtemplateImage.value ==='template2')){
            return      <FormGroup className={!dontShowDROPBOX?'player-dropzone-uploader-wrapper newStyleDropbox':'player-dropzone-uploader-wrapper create-update-table-dropbox'} >
          <Row>
           <Col sm={5}>
            {
                 label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}
                 {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
                     <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
                     {info.text}
                 </UncontrolledTooltip>
                 </>
                 }
                 
                 </Label> : ''
             }
       
             <Field
                 name={classProperty}
                 component={renderErrorMessage}
                 validate={validations}
             />
                   </Col>
                   <Col sm={3}>
             {!(files && files.length > 0 ) ? <div><i className="fa fa-circle" style={{color:'red'}} aria-hidden="true"></i> Pending</div>:<div><i className="fa fa-check-circle" style={{color:'green'}} aria-hidden="true"></i> Uploaded</div>}
             </Col>
             <Col sm={4}>
            {   !(onlyIconDropboxImage || onlyIconDropboxFile || onlyIconDropboxVideoFile ) ?  <Dropzone multiple={field.collection} className='player-dropzone-uploader' accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
            <div className='d-flex flex-column pt-2 pb-2'>
                       <p className='text-center mb-1 mt-0'>Drag &amp; Drop files here</p>
                 </div>     
             </Dropzone> : 
             <Dropzone multiple={field.collection} className={!dontShowDROPBOX ? 'player-dropzone-uploader-only-icon-wrapper ':'player-dropzone-uploader-only-icon-wrapper create-update-table-icon-wrapper'} accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>{itemProperties.beforeIcon?<RenderIcon className="player-dropzone-uploader-only-icon" config={itemProperties.beforeIcon} customIcons={customIcons} color={itemProperties.textColor?itemProperties.textColor.value:''} />:dontShowDROPBOX ?       <i className="fa fa-upload" style={{fontSize:18}} aria-hidden="true"></i> : null}</Dropzone>}

             <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
        { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !dontShowDROPBOX && !removedFile && <div><a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
        <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                             <i className="fa fa-times text-danger" aria-hidden="true"></i>
                         </Button></div>
        }
        { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && dontShowDROPBOX && !removedFile && <div><a  target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download`}</a>
        <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                             <i className="fa fa-times text-danger" aria-hidden="true"></i>
                         </Button></div>
        }
        </Col>
        </Row>
           
             {
               footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                 }
         </FormGroup>
        }
        return (
            <FormGroup className={!dontShowDROPBOX?'player-dropzone-uploader-wrapper':'player-dropzone-uploader-wrapper create-update-table-dropbox'} >
               {
                    label.show ? <Label>{translatedLabel}{field.properties.required || minLength ? <sup>*</sup> : ''}
                    {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
                        <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
                        {info.text}
                    </UncontrolledTooltip>
                    </>
                    }
                    
                    </Label> : ''
                }
                <Field
                    name={classProperty}
                    component={renderErrorMessage}
                    validate={validations}
                />
               {   !(onlyIconDropboxImage || onlyIconDropboxFile || onlyIconDropboxVideoFile ) ?  <Dropzone multiple={field.collection} className='player-dropzone-uploader' accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
               <div className='d-flex flex-column pt-2 pb-2'>
                        <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                        <p className='text-center mb-1 mt-0'>Drag &amp; Drop files here</p>
                        <p className='text-center mb-1 mt-0'>or</p>
                        <p className='text-center mb-0'>
                            <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                        </p>
                    </div>     
                </Dropzone> : 
                <Dropzone multiple={field.collection} className={!dontShowDROPBOX ? 'player-dropzone-uploader-only-icon-wrapper ':'player-dropzone-uploader-only-icon-wrapper create-update-table-icon-wrapper'} accepts="application/msword, application/pdf" ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>{itemProperties.beforeIcon?<RenderIcon className="player-dropzone-uploader-only-icon" config={itemProperties.beforeIcon} customIcons={customIcons} color={itemProperties.textColor?itemProperties.textColor.value:''} />:dontShowDROPBOX ?       <i className="fa fa-upload" style={{fontSize:18}} aria-hidden="true"></i> : null}</Dropzone>}

                <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
           { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && !dontShowDROPBOX && !removedFile && <div><a target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download (${uploadedFileName})`}</a>
           <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
           }
           { !(files.length > 0) &&  uploadedFileName && !(uploadedFileName === "null") && dontShowDROPBOX && !removedFile && <div><a  target="_blank" href={uploadFileNameUrl}><i className="fa fa-external-link mr-1" aria-hidden="true"></i>{`View/Download`}</a>
           <Button onClick={this.removeFileFromForm.bind(this, classProperty)} color='link' className='p-0 ml-1' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button></div>
           }
              
                {
                  footer &&  footer.show ? <h6  width='100%' className="footer-text">{footer.text}</h6> : ''
                    }
            </FormGroup>
        );
    }
}

const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const FilesList = (props) => {
    const { files, removeFile, classProperty } = props;
    return (
        <div className='mt-2'>
            {
                files.map((file, index) => {
                    return <div key={index} className='file-info d-flex justify-content-between'>
                        <div>
						{/*<a href={file.preview} target='_blank' className='mb-0'>
                                {file.name} ({formatFileSize(file.size)}){' '}
                                <i className="fa fa-external-link ml-1 text-muted" aria-hidden="true"></i>
						</a>*/}
							{file.name} ({formatFileSize(file.size)}){' '}
                        </div>
                        <div>
                            <Button onClick={removeFile.bind(this, index, classProperty)} color='link' className='p-0' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button>
                        </div>
                    </div>
                })
            }
        </div>

    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        uploader: state.getIn(['uploader', `uploader_for_${ownProps.classProperty}`])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFiles: (payload) => {
            dispatch(uploadFiles(payload));
        },
        clearUploader: (namespace) => {
          dispatch(clearUploader(namespace));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(toJS(Uploader));