import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Filters from './index';
import Pagination from '../../../commons/pagination';
import { Row,Col } from 'reactstrap';
import SideFilters from './SideFilters';


class FilterWrapperHOC extends Component {
    getIsFilterActive(filterConfig) {
        let isActive = true;
        if (filterConfig && typeof filterConfig.active === 'boolean') {
            isActive = filterConfig.active;
        }
        return isActive;
    }
    getFilterConfigFromLayoutItem(layoutItem) {
        let config = null;
        if (layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.filterConfig) {
            config = layoutItem.itemProperties.filterConfig;
        }
        return config;
    }
    getFilterConfigFromWorkflowItem(workflowItem) {
        let config = null;
        const {containerIndex} = this.props;
        if (workflowItem && workflowItem.layoutProperties && workflowItem.layoutProperties.readAllFilterConfig && workflowItem.layoutProperties.readAllFilterConfig[containerIndex] && workflowItem.layoutProperties.readAllFilterConfig[containerIndex].filterConfig) {
            config = workflowItem.layoutProperties.readAllFilterConfig[containerIndex].filterConfig;
        }
        else if (workflowItem && workflowItem.layoutProperties && workflowItem.layoutProperties.readAllFilterConfig && workflowItem.layoutProperties.readAllFilterConfig.filterConfig) {
            config = workflowItem.layoutProperties.readAllFilterConfig.filterConfig;
        }
        return config;
    }
    render() {
        const { useLayoutItem, children, paginationStart, pageSize, pagination, onChangePage, collection, onChangePageSize, tableStyleConfig,filterTemplate, selectedTemplate } = this.props;
        const filterConfig = useLayoutItem ? this.getFilterConfigFromLayoutItem(this.props.layoutItem) : this.getFilterConfigFromWorkflowItem(this.props.workflowItem);
        const filters = filterConfig && filterConfig.filters ? filterConfig.filters : [];
        const allFilters = (filterConfig && filterConfig.filterAll) ? true : false;
        if(filterTemplate === 'Template3'){
            return (
                <div>
               
                {
                    this.getIsFilterActive(filterConfig) ? <Filters alwaysShow={true}  filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} filterTemplate={filterTemplate} {...this.props} /> : null
                }
           
                <Row>
                    <Col lg={4} md={4} sm={4}>
                    {
                    this.getIsFilterActive(filterConfig) ? <SideFilters alwaysShow={true}  filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} filterTemplate={filterTemplate} {...this.props} /> : null
                }
                    </Col>
                    <Col lg={8} md={8} sm={8}>
                    {children}
                    {
                    pagination && collection && !(tableStyleConfig && tableStyleConfig.enablePagination )  &&  <Pagination 
                        start={paginationStart} 
                        hasNextPage={collection ? collection.data.length === pageSize : false}
                        size={pageSize}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                        totalCount={collection && collection.totalCount ? parseInt(collection.totalCount) : 0}
                    />
                }
                </Col>
                </Row>
               
            </div>
            )
        }
        return (
            <div>
                {
                    this.getIsFilterActive(filterConfig) ? <Filters filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} {...this.props} /> : null
                }
                {children}
                {
                    pagination && collection && !(tableStyleConfig && tableStyleConfig.enablePagination )  &&  <Pagination 
                        start={paginationStart} 
                        hasNextPage={collection ? collection.data.length === pageSize : false}
                        size={pageSize}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                        totalCount={collection && collection.totalCount ? parseInt(collection.totalCount) : 0}
                    />
                }
            </div>
        );
    }
}

FilterWrapperHOC.defaultProps = {
    useLayoutItem: false
}

FilterWrapperHOC.propTypes = {
    useLayoutItem: PropTypes.bool,
};

export default FilterWrapperHOC;