import React, { Component } from 'react'
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, FormFeedback, Alert, InputGroupText, InputGroupAddon } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
// import ForgotPassword2 from '../pages/login/ForgotPassword2';

export default class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validRegex: true,
            regexMessage: '',
            passwordType: 'password',
            confirmPasswordType: 'password',
            showPasswordState: false,
            showConfirmPasswordState: false
        }
        this.showPassword = this.showPassword.bind(this);
        this.showConfirmPassword = this.showConfirmPassword.bind(this);
    }
    renderField({ input, label, type, placeholder, showPasswordFunc, passwordType, meta, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                </InputGroupAddon>
                <Input placeholder={placeholder} type={type} {...input} />
                <InputGroupText onClick={showPasswordFunc}>
                    <i className={passwordType === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" style={{ color: '#675cff', fontSize: 18 }}>
                    </i>
                </InputGroupText>
            </InputGroup>


            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    renderField2({ input, label, type, placeholder, showPasswordFunc, passwordType, meta, meta: { touched, error } }) {
        const hasError = touched && error;
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                </InputGroupAddon>
                <Input placeholder={placeholder} type={type} {...input} valid={hasError ? false : null} >
                </Input>
                <InputGroupText onClick={showPasswordFunc}>
                    <i className={passwordType === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true" style={{ color: '#675cff', fontSize: 18 }}>
                    </i>
                </InputGroupText>
            </InputGroup>
        </FormGroup>
    }
    setPasswordType(passwordType) {
        this.setState({ passwordType });
    }

    setConfirmPasswordType(confirmPasswordType) {
        this.setState({ confirmPasswordType });
    }

    showPassword() {
        this.setState({ showPasswordState: !this.state.showPasswordState });
        this.setPasswordType(this.state.showPasswordState ? 'text' : 'password');
    }

    showConfirmPassword() {
        this.setState({ showConfirmPasswordState: !this.state.showConfirmPasswordState });
        this.setConfirmPasswordType(this.state.showConfirmPasswordState ? 'text' : 'password');
    }

    regexPattern = (pattern, message) => value => {
        const regex = new RegExp(pattern, "gi");
        this.setState({ validRegex: regex.test(value) });
        if (!regex.test(value)) {
            this.setState({ regexMessage: message });
        }
        if (regex.test(value)) {
            this.setState({ regexMessage: '' });
        }
    }


    render() {
        const { setPasswordState, companyDetails, layoutItem, isSetPasswordIsHavingError, setPasswordErrorMsg } = this.props;
        const { validRegex, regexMessage, passwordType, confirmPasswordType } = this.state;
        const setPasswordConfig = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.setPasswordConfig;
        const regexPatterns = setPasswordConfig && setPasswordConfig.regexPattern;
        const regexErrorMessage = setPasswordConfig && setPasswordConfig.regexErrorMessage;
        let setPasswordValidation = [];
        if (regexPatterns) {
            setPasswordValidation.push(
                this.regexPattern(regexPatterns, regexErrorMessage));
        }

        const template = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.setPasswordConfig && layoutItem.itemProperties.setPasswordConfig.template.value
        // if(template === 'Template2'){
        //   return <ForgotPassword2 {...this.props}/>
        // }
        const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/login'
        if (!setPasswordState.isLoading && setPasswordState.data !== null && !(isSetPasswordIsHavingError === 'Failure')) {
            return <div className='player-public-user-form p-4 public-setpassword-component' style={{ marginTop: 100 }}>
                <h5 className='text-center mb-3'>Password Set Successfully</h5>
                <Alert color='success'>
                    <i className='fa fa-check'></i> Great! You have successfully set your password
                </Alert>
                <Link className='mt-4 d-block text-center bg-white p-2 rounded' to={logoutUrl}>
                    Continue to login
                </Link>
            </div>
        }
        return (
            <div className='div-setpassword' style={{ marginTop: 100 }}>
                <Form onSubmit={this.props.setPassword} className='player-public-user-form-setPassword p-4'>
                    <h5 className='text-center mb-3'>Set your password</h5>
                    <Field
                        name="password"
                        component={this.renderField2}
                        type={passwordType}
                        label='Password'
                        validate={setPasswordValidation}
                        showPasswordFunc={this.showPassword}
                        passwordType={passwordType}
                    />
                    {!validRegex && regexMessage.length > 0 ? <FormFeedback className='animated fadeIn d-block'>
                        <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
                        {regexMessage}
                    </FormFeedback> : isSetPasswordIsHavingError === 'Failure' && setPasswordErrorMsg ? <FormFeedback className='animated fadeIn d-block'>
                        <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
                        {setPasswordErrorMsg}
                    </FormFeedback> : null}
                    <Field
                        name="confirmPassword"
                        component={this.renderField}
                        type={confirmPasswordType}
                        label='Confirm Password'
                        showPasswordFunc={this.showConfirmPassword}
                        passwordType={confirmPasswordType}
                    />
                    {/*<ReCAPTCHA
                                    ref="recaptcha"
                                    sitekey="6LdA1rYUAAAAADPkB4lFQbyas7GQ_1LQalVgiu4-"
                                    onChange={this.onRecaptchaSuccess}
                                />*/}
                    <Button disabled={setPasswordState.isLoading || !validRegex} className='mt-4' color='primary' block>
                        {setPasswordState.isLoading ? 'Please wait...' : 'Set Password'}
                    </Button>
                    <Link className='mt-4 d-block text-center' to={logoutUrl}>
                        <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                        Back to login
                    </Link>
                </Form>
            </div>
        )
    }
}




