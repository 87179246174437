import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../Table/Tablev1';
import { Label, Button, ButtonGroup, Input, Row, Col } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import DataListStore from '../../workflow/operations/filters/DataListStore';
import DataListWrapper from '../../workflow/operations/filters/DataListWrapper';
import FilterWrapperHOC from '../../workflow/operations/filters/FilterWrapperHOC';
import NoDataAlertBox from '../../commons/NoDataAlertBox';
import { Field, FieldArray } from 'redux-form/immutable';
import { renderField } from '../_FORM_FIELDS';
import { Map } from 'immutable';
import * as Elements from '../index';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import JSONPath from 'jsonpath';
import SortableList, { SortableItem } from 'react-easy-sort';
import Pagination from '../../commons/pagination';

String.prototype.capitalize = function () { return this.charAt(0).toUpperCase() + this.slice(1); }

const renderTableFields = ({ rows, label, columns, fields, getValidations, validations, meta: { error }, classProperty, 
		 isPagination,pageStart, pageSize, onChangePage,...rest }) => {

	const { layoutItem: { itemProperties: { field: { customTypeClass }, buttonLabel } }, workflow: { currentItem: { typeModel: { customClassName, customerName } } } } = rest;
	let modelVersion = customClassName.replace('com.uttara.neene.proxyto.' + customerName + '_', '');
	modelVersion = modelVersion.substring(0, modelVersion.indexOf('.'));
	const { layoutItem: { itemProperties } } = rest;
	const { reOrderRows, isLimitDataEnable, numberOfLimitedRow } = itemProperties;
	const hideAddDeleteButton = itemProperties && itemProperties.dontAllowAdditionDeletion ? itemProperties.dontAllowAdditionDeletion : null;
	const iModelVerision = parseInt(modelVersion.substring(1));
	let className = customTypeClass;
	for (let i = 0; i < iModelVerision; i++) {
		if (className.indexOf('v' + i + '.') != -1)
			className = className.replace('v' + i + '.', modelVersion + '.').replace(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
	}
	if (fields.length == 0) {
		fields.push(new Map({ class: className }));
	}
	
	if(reOrderRows){
		return <div className='mt-2'>
		<SortableList onSortEnd={(oldIndex, newIndex) => {
				fields.move(oldIndex, newIndex)
			}} className="list" draggedItemClassName="dragged">
				{fields.map((member, index) => 
				{
					if(index >= pageStart+pageSize || index < pageStart){
						return null;
					}
					return <SortableItem key={member}>
						<div className="item d-flex justify-content-space-between">
							<i title="Move" className="fa fa-arrows gridster-drag-handle mt-2 fa-xs"></i>
							{
								columns.map((item, colIndex) => {
									return item.field.type != 'LARGETEXT' && <Col key={colIndex}>
										<TableLayoutItem
											member={member}
											memberData={rows[index]}
											item={item}
											rows={rows}
											parentClassProperty={classProperty}
											parentIndex={index}
											columns={columns}
											getValidations={getValidations}
											{...rest}
										/></Col>
								})
							}
							{!hideAddDeleteButton && <Button onClick={() => fields.remove(index)} size='sm' color='link'>
								<i className="fa fa-trash text-danger" aria-hidden="true"></i>
							</Button>}
						</div>
					</SortableItem>
				})}
			</SortableList>
		{!hideAddDeleteButton && <div className="d-flex justify-content-end mt-4">
			<Button disabled={isLimitDataEnable && fields.length == numberOfLimitedRow ? true : false} 
				color='primary' size="sm" onClick={() => fields.push(new Map({ class: className }))}>
				<i className="fa fa-plus" aria-hidden="true"></i>{` ${buttonLabel ? buttonLabel : label.text}`}
			</Button>
		</div>}
		{
				isPagination && <Pagination
				start={pageStart}
				hasNextPage={fields.length>pageStart+pageSize}
				size={pageSize}
				onChangePage={onChangePage}
				totalCount={fields.length}
				isTypeModelCreateUpdateTable={true}
				className='mt-4'
			/>
			}
	</div>
	} else {
		return <div className='mt-2'>
			{
				fields.map((member, index) => {
					if(index >= pageStart+pageSize || index < pageStart){
						return null;
					}
					return <Row key={`${pageStart}_${index}`}>
						{
							columns.map((item, colIndex) => {
								return item.field.type != 'LARGETEXT' && <Col key={`${pageStart}_${index}_${colIndex}`}>
									<TableLayoutItem
										member={member}
										memberData={rows[index]}
										item={item}
										rows={rows}
										parentClassProperty={classProperty}
										parentIndex={index}
										columns={columns}
										getValidations={getValidations}
										{...rest}
									/></Col>
							})
						}
						{!hideAddDeleteButton && <Button onClick={() => fields.remove(index)} size='sm' color='link'>
							<i className="fa fa-trash text-danger" aria-hidden="true"></i>
						</Button>}
					</Row>
				})
			}
			{!hideAddDeleteButton && <div className="d-flex justify-content-end mt-4">
				<Button disabled={isLimitDataEnable && fields.length == numberOfLimitedRow ? true : false}
					color='primary' size="sm" onClick={() => fields.push(new Map({ class: className }))}>
					<i className="fa fa-plus" aria-hidden="true"></i>{` ${buttonLabel ? buttonLabel : label.text}`}
				</Button>
			</div>}
			<div>
			{
				isPagination && <Pagination
				start={pageStart}
				hasNextPage={fields.length>pageStart+pageSize}
				size={pageSize}
				onChangePage={onChangePage}
				totalCount={fields.length}
				isTypeModelCreateUpdateTable={true}
			/>
			}
			</div>
		</div>
	}
}

class TypeModelCreateUpdateTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validationArr: [],
			isPagination: false,
			pageSize: 0,
			pageStart: 0
		};
	}
	componentDidMount() {
		const { rows, layoutItem: { itemProperties } } = this.props;
		const { allowPagination, pageSize } = itemProperties;
		this.setState({isPagination: allowPagination, pageSize: Number(pageSize)})
	}
	componentWillReceiveProps(nextProps) {
	}

	onChangePage(page){
		this.setState({pageStart: page})
	}

	render() {
		const { workflow, rows, columns, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode,
			workflow: { currentItem: { typeModel: { name } } }, layoutItem: { itemProperties }, noDataText } = this.props;
		const { isPagination, pageSize, pageStart } = this.state;
		return (
			<div className="type-model-table">
				<div className='d-flex justify-content-between mb-2 border-botoom'>
					<div>
						<Label className='text-muted text-capitalize mb-0' style={{ marginLeft: 20 }}>{label.text}</Label>
					</div>
				</div>
					<div className='mb-1'>
						<div className="p-2" style={{ border: '1px solid #ccc', borderRadius: 5 }}>
							<ColumnHeader columns={columns} />
							<FieldArray label={label} rows={rows} columns={columns} name={this.props.classProperty} validations={this.props.validations}
								component={renderTableFields} {...this.props} isPagination={isPagination} 
								pageStart={pageStart} pageSize={pageSize} onChangePage={this.onChangePage.bind(this)}/>							
						</div>
					</div>
			</div>
		);
	}
}

const ColumnHeader = (props) => {
	const { columns } = props;
	return (
		<Row className='w-100'>
			{
				columns && columns.length && columns.map((item, index) => {
					return item.field.type != 'LARGETEXT' && <Col key={index}><b className='text-capitalize'>{item.config.header}</b></Col>
				})
			}
		</Row>
	)
}

class TableLayoutItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validations: [],
			itemId: ''
		};
	}
	componentDidMount() {
		const item = this.props.item;
		const validations = this.props.getValidations({
			itemType: 'MODELPROPERTIES',
			itemProperties: {
				field: item.field,
				minLength: item.config.minLength,
				regexPattern: item.config.regexPattern,
				regexErrorMessage: item.config.regexErrorMessage,
				maxLength: item.config.maxLength,
			}
		});
		this.setState({ validations, itemId: item.field.id });
	}

	componentWillReceiveProps(nextProps) {
		const item = nextProps.item;
		const formValues = nextProps.formValues;
		const unique = item.config.unique;
		const parentClassProperty = nextProps.parentClassProperty;
		const member = nextProps.member;
		const data = formValues[`${parentClassProperty}`];
		const itemId = this.state.itemId;
		const itemIdIndex = this.state.itemIdIndex;
		const compareField = item.config.compareField;
		const selectedOperator = item.config.selectedOperator;
		if (unique) {
			const dataToRender = JSONPath.value(formValues, `$.${member}.${item.field.classProperty}`);
			let count = 0;
			data.map(d => {
				if (d[`${item.field.classProperty}`] == dataToRender) {
					count = count + 1;
				}
			})
			if (count > 1 && itemId !== item.field.id) {
				const validations = this.props.getValidations({
					itemType: 'MODELPROPERTIES',
					itemProperties: {
						field: item.field,
						minLength: item.config.minLength,
						regexPattern: item.config.regexPattern,
						regexErrorMessage: item.config.regexErrorMessage,
						maxLength: item.config.maxLength,
						isUnique: true
					}
				});
				this.setState({ validations, itemId: item.field.id });
			}
		}
		if (compareField) {
			let field = compareField.indexOf('{{') !== -1 ? compareField.replace('{{', '').replace('}}', '') : compareField;
			let fArr = field.split('.');
			const length = fArr.length;
			for (let i = 0; i < length; i++) {
				if (fArr[i] == parentClassProperty) {
					fArr[i] = `${parentClassProperty}[${nextProps.parentIndex}]`
				}
			}
			field = fArr.join('.')
			const dataToCompare = JSONPath.value(formValues, `$.${field}`);
			const dataToRender = JSONPath.value(formValues, `$.${member}.${item.field.classProperty}`);
			let isValid = true;
			if (dataToCompare && dataToRender) {
				switch (selectedOperator.value) {
					case '<=':
						if (dataToRender <= dataToCompare) {
							isValid = true
						} else
							isValid = false;
						break;
					case '>=':
						if (dataToRender >= dataToCompare) {
							isValid = true
						} else
							isValid = false;
						break;
					case '==':
						if (dataToRender == dataToCompare) {
							isValid = true
						} else
							isValid = false;
						break;
				}
			}

			if (dataToCompare && isValid == false && itemIdIndex !== nextProps.parentIndex) {
				const validations = this.props.getValidations({
					itemType: 'MODELPROPERTIES',
					itemProperties: {
						field: item.field,
						minLength: item.config.minLength,
						regexPattern: item.config.regexPattern,
						regexErrorMessage: item.config.regexErrorMessage,
						maxLength: item.config.maxLength,
						notValidValue: true,
						validationMsg: `Value must be ${selectedOperator.value} ${dataToCompare}`
					}
				});

				this.setState({ validations, itemIdIndex: nextProps.parentIndex });
			}
			if (dataToCompare && isValid == true && itemIdIndex === nextProps.parentIndex) {
				const validations = this.props.getValidations({
					itemType: 'MODELPROPERTIES',
					itemProperties: {
						field: item.field,
						minLength: item.config.minLength,
						regexPattern: item.config.regexPattern,
						regexErrorMessage: item.config.regexErrorMessage,
						maxLength: item.config.maxLength,
						notValidValue: true,
						validationMsg: null
					}
				});
				this.setState({ validations, itemIdIndex: null });
			}
		}
	}

	render() {
		const { item, member, layoutItem, rows, columns, parentClassProperty, parentIndex, ...rest } = this.props;
		const filterFieldForUniqueCheck = item && item.config && item.config.filterFieldForUniqueCheck
		let ResolvedComponent = Elements[item.field.type];
		if (ResolvedComponent === null || !ResolvedComponent) {
			ResolvedComponent = Elements.FALLBACK;
		}
		const { hasMember, memberClassProperty, memberIndex } = this.props.workflow;
		if (item.config.renderReadOnly) {
			if (!this.props.workflowData) return null;
			let textToRender = '';
			if (typeof member === 'string') {
				textToRender = ModelPropertiesParser(item.config.modelSelector, this.props.memberData[item.field.classProperty]);
			} else {

				const dataToRender = JSONPath.value(this.props.workflowData, `$.${member}.${item.field.classProperty}`);
				textToRender = ModelPropertiesParser(item.config.modelSelector, dataToRender);

			}
			//const textToRender = ModelPropertiesParser(item.config.modelSelector, member[item.field.classProperty]);
			return <Label>{textToRender}</Label>
		}
		return (
			<ResolvedComponent
				classProperty={`${parentClassProperty}[${parentIndex}].${item.field.classProperty}`}
				itemClassPropertyFromTable={item.field.classProperty}
				label=''
				placeholder=''
				ratingConfig={item.config.ratingConfig}
				filterFieldForUniqueCheck={filterFieldForUniqueCheck}
				layoutItem={{
					itemProperties: {
						modelSelector: item.config.modelSelector ? item.config.modelSelector : '',
						label: { show: false },
						placeholder: { show: false },
						elementType: item.field.type,
						field: item.field,
						ratingConfig: item.config.ratingConfig,
						replaceClassProperty: `${parentClassProperty}[${parentIndex}].${item.field.classProperty}`,
						dateConfig: { relativeMinDate: item.config.relativeMinDate, relativeMaxDate: item.config.relativeMaxDate },
						filterField: item.config.filterField,
						filterValueField: item.config.filterValueField
					},
					...item
				}}
				type={item.field.type}
				validations={item.field.id == this.state.itemId ? this.state.validations : []}
				dontShowDROPBOX={true}
				rows={rows}
				columns={columns}
				disableState={item && item.config.disableState}
				{...rest}
			/>
		);
	}
}

TypeModelCreateUpdateTable.defaultProps = {
	rows: [],
	columns: []
}

TypeModelCreateUpdateTable.propTypes = {
	workflow: PropTypes.object,
	rows: PropTypes.array,
	columns: PropTypes.array,
	label: PropTypes.object
};

export default TypeModelCreateUpdateTable;