import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../../containers/to-js';
import { resetPassword, resetForgotPasswordState } from '../../../actions/user';
import { getCompanyDetails } from '../../../actions/company';
import isEmail from 'validator/lib/isEmail';
import config from '../../../config';
// import forgetPasswordImage from '../../../images/forget-passowrd.svg';

class ForgotPassword extends Component {
    componentDidMount() {
        localStorage.removeItem('user');
        const { companyDetails } = this.props;
        if (companyDetails.data == null) {
            this.props.getCompanyDetails();
        }
    }
    renderField({ input, label, type, placeholder, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} {...input} />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    componentWillUnmount() {
        this.props.resetForgotPasswordState();
    }
    render() {
        const { handleSubmit, forgotPasswordState, companyDetails,layoutItem,reCaptchaComponent,displayReCaptcha } = this.props;
        const templateSideImage = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.forgotPasswordConfig && layoutItem.itemProperties.forgotPasswordConfig.templateSideImage && layoutItem.itemProperties.forgotPasswordConfig.templateSideImage.path
      
        const forgetPasswordImage = `${config.assetsBasePath}${templateSideImage}`
        const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/login'
        const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
        if (!forgotPasswordState.isLoading && forgotPasswordState.data !== null) {
            return <ShowResponseToUser {...this.props} />
        }
        return (
            <Row className='h-100 d-flex justify-content-center public-custom-user-page-design'>

                <Col xs="6" sm="6" md="5" className='first'>
                 
                    <div className='centerImg'>
                        <img src={forgetPasswordImage} />
                    </div>
                 
                </Col>
                <Col xs="6" sm="6" md="7">
                    <Form onSubmit={handleSubmit} className='player-public-custom-user-form forgotPasswordTemplate'>
                        <h3 className='mb-3'>Forgot password</h3>
                        <h6>Enter Your Email Address</h6>
                        <Field
                            name="email"
                            component={this.renderField}
                            type="text"
                            label=''
                            placeholder='Email'
                        />
                        {reCaptchaComponent}
                        <Button disabled={forgotPasswordState.isLoading || displayReCaptcha} className='mt-4' color='primary' block>
                            {forgotPasswordState.isLoading ? 'Please wait...' : 'Reset Password'}
                        </Button>
                        <Link className='mt-3 d-block text-center back' to={logoutUrl}>
                            <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                            Back to login
                        </Link>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        forgotPasswordState: state.getIn(['user', 'resetPassword']),
        translate: getTranslate(state.get('locale')),
        companyDetails: state.getIn(['company', 'details'])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            let reCaptchaDetails = localStorage.getItem("_captcha_details_forgot");
            reCaptchaDetails = JSON.parse(reCaptchaDetails);
            if(!reCaptchaDetails) {
              localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                count: 0,
                date: new Date()
              }));
            } else {
              const lastSetDate = reCaptchaDetails.date;
              const currentDate = new Date();
              let diff = Math.abs(currentDate - lastSetDate);
              let minutes = Math.floor((diff/1000)/60);
              if(minutes > 30) {
                localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                  count: 0,
                  date: new Date()
                }));
              } else {
                localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                  count: reCaptchaDetails.count + 1,
                  date: new Date()
                }));
              }
      
            }
            dispatch(resetPassword(values));
        },
        resetForgotPasswordState: () => {
            dispatch(resetForgotPasswordState());
        },
        getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        }
    }
};

const ShowResponseToUser = (props) => {
    const { companyDetails ,layoutItem} = props;
    const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
    const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/login'
    const templateSideImage = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.forgotPasswordConfig && layoutItem.itemProperties.forgotPasswordConfig.templateSideImage && layoutItem.itemProperties.forgotPasswordConfig.templateSideImage.path
      
    const forgetPasswordImage = `${config.assetsBasePath}${templateSideImage}`
    return (
        < Row className='h-100 d-flex justify-content-center public-custom-user-page-design' >
            <Col xs="12" sm="10" md="5" className='first'>
              
                <div className='centerImg'>
                    <img src={forgetPasswordImage} />
                </div>
              
            </Col>
            <Col xs="12" sm="10" md="7">
                <div className='alertText'>
                    <Alert color='info'>
                        <i className='fa fa-check'></i> Thank you for submitting the request. If the email address is registered with us, you will recieve an email with link to reset the password.
                    </Alert>
                    <Link className='mt-4 d-block text-center p-2 rounded backButton' to={logoutUrl}>
                        Continue to login
                    </Link>
                </div>
            </Col>
        </Row >
    )
}

const validate = (values) => {
    const errors = {};
    const email = values.get('email');
    if (!email || !isEmail(email)) {
        errors.email = 'Please provide a valid email address';
    }
    return errors;
}

ForgotPassword = reduxForm({
    form: 'player-resetpassword-form',
    validate
})(ForgotPassword)

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ForgotPassword));