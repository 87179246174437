import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../../containers/to-js';
import { signUp } from '../../../actions/user';
import isEmail from 'validator/lib/isEmail';

class SignUp extends Component {
    componentDidMount() {
        localStorage.removeItem('user');
    }
    renderField({ input, label, type, placeholder, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} { ...input } />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    render() {
        const { handleSubmit, user,companyDetails } = this.props;
        const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/login'
        return (
            <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
                <Col xs="12" sm="10" md="4">
                    <h4 className='brand-name player'>APTO<span className='ats'>.AI</span></h4>
                    <Form onSubmit={handleSubmit} className='player-public-user-form p-4'>
                        <h5 className='text-center mb-3'>Create an account</h5>
                        <Field
                            name="email"
                            component={this.renderField}
                            type="text"
                            label='Email'
                            placeholder='John.Doe@example.com'
                        />
                        <Field
                            name="firstName"
                            component={this.renderField}
                            type="text"
                            label='First Name'
                            placeholder='John'
                        />
                        <Field
                            name="lastName"
                            component={this.renderField}
                            type="text"
                            label='Last Name'
                            placeholder='Doe'
                        />
                        <Field
                            name="password"
                            component={this.renderField}
                            type="password"
                            label='Password'
                            placeholder='Set your password'
                        />
                        <Button className='mt-4' color='primary' block>
                            Sign Up
                        </Button>
                        <Link className='mt-4 d-block text-center' to={logoutUrl}>
                            <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                            Back to login
                        </Link>
                    </Form>
                </Col>
            </Row>
        );
    }
}

SignUp.propTypes = {

};

const mapStateToProps = (state) => {
    return { 
        user: state.get('user'),
        translate: getTranslate(state.get('locale'))
      };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            dispatch(signUp(values));
        }
    }
};

const validate = (values) => {
    const errors = {};
    const email = values.get('email');
    const password = values.get('password');
    const firstName = values.get('firstName');
    const lastName = values.get('lastName');
    if (!email || !isEmail(email)) {
        errors.email = 'Please provide a valid email address';
    }
    if (!password) {
        errors.password = 'Please enter your password';
    }
    if (!firstName) {
        errors.firstName = 'Please enter your first name';
    }
    if (!lastName) {
        errors.lastName = 'Please enter your last name';
    }
    return errors;
}

SignUp = reduxForm({
    form: 'player-signup-form',
    validate
})(SignUp)

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SignUp));