import { useState, useEffect } from "react";

const livenessChekList = [
  "BEST_SHOT",
  "CLOSE_EYES_AND_OPEN",
  "TURN_HEAD_TO_LEFT",
  "SMILE",
  "TURN_HEAD_TO_RIGHT",
];
const langIdentifiersForLiveness = {
  accessing_camera: "Accessing Camera. It may take few seconds.",
  action_camera_switch_button: "Switch camera",
  result_state_processing: "Analysis in progress",
  device_turn: "Please turn your device",
  more_than_one_face: "Make sure there are no other faces",
  processing_data: "Processing data",
  purelive_alert_dialog_fail_action_title: "Requested action is not detected",
  error_no_camera: "Error: Unable to access the camera.",
  error_no_camera_ios:
    "Error: Unable to access the camera. <br/> Please use default browser (Safari).",
  error_bad_camera: "Error: Unable to use the camera.",
  error_slow_backend:
    "This browser doesn't support some important features. <br /><br /> For your convenience, please open this page in another browser.",
  alert_close_button: "OK",
  purelive_action_hint_not_centered: "Align your face with the frame",
  purelive_action_hint_move_closer: "Closer",
  purelive_action_hint_move_away: "Move away",
  purelive_action_hint_move_even_closer: "Even closer",
  purelive_action_hint_move_even_away: "Even further",
  purelive_action_hint_look_straight: "Turn your face straight to the camera",
  purelive_action_hint_dont_smile:
    "Will ask you to smile later; don't smile now please",
  purelive_action_hint_dont_left_or_right_head:
    "Will ask you to turn head later; Look straight at the camera now please",
  purelive_action_hint_open_eyes_wide: "Open your eyes wide",
  purelive_action_hint_face_aligned: "Great, don't move",
  purelive_action_hint_remove_object: "Your face must be clearly visible",
  purelive_action_hint_remove_sunglasses: "Please, take off the sunglasses",
  purelive_action_hint_remove_mask: "Please, take off the mask",
  purelive_action_repeat: "Please repeat the action",

  purelive_action_zoom_in_go: "Move your face closer to the screen",
  purelive_action_zoom_out_go: "Move your face farther from the screen",
  purelive_action_smile_go: "Smile",
  purelive_action_blink_go: "Close your eyes and open thema",
  purelive_action_head_up_go: "Raise your head up",
  purelive_action_head_down_go: "Lower your head down",
  purelive_action_head_right_go: "TURNING YOUR HEAD, look to your right",
  purelive_action_head_left_go: "TURNING YOUR HEAD, look to your left",
  purelive_action_look_at_screen: "Look at the screen",
  purelive_action_hint_smile_open_mouth:
    "Please don't smile and don't open your mouth",
  purelive_action_hint_low_score: "Your face must be clearly visible",
  purelive_action_hint_bad_light: "Find better lighting conditions",

  purelive_action_hint_too_blurry: "The image is too blurry",
  purelive_action_hint_OK: "Great!",
  purelive_action_hint_OK_intermediate: "Great, don't move",
  error_upload_unknown: "Error: Unable to process the file.",
  error_virtual_camera: "Please don't use virtual camera.",

  error_no_camera: "Please grant permission to use camera.",
  error_no_camera_ios:
    "Error: Unable to access the camera. <br/> Please use default browser (Safari).",
  error_no_camera_ssl:
    "Error: Unable to access the camera. <br/> Please use HTTPS (SSL)",

  error_no_camera_title:
    "<b>Please grant permission to use camera.</b>" +
    "<br><br>How to allow access:",

  purelive_tutorial_camera_android_default:
    "" +
    "<p>Unfortunately, we haven’t recognized you browser. In case of <b>Chrome</b>, this problem can be solved as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>In <b>Chrome</b>, press the menu button (<b>“three points”</b>).</li>" +
    "<li>Select <b>Settings</b>.</li>" +
    "<li>Scroll down the settings page and select <b>Site settings</b>.</li>" +
    "<li>Select <b>Camera</b>.</li>" +
    "<li>Switch on the radio button <b>Camera</b>.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_chrome:
    "" +
    "<p>To enable access to the camera in <b>Google Chrome</b>, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>In <b>Chrome</b>, press the menu button (<b>“three points”</b>).</li>" +
    "<li>Select <b>Settings</b>.</li>" +
    "<li>Scroll down the settings page and select <b>Site settings</b>.</li>" +
    "<li>Select <b>Camera</b>.</li>" +
    "<li>Switch on the radio button <b>Camera</b>.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_miui:
    "" +
    "<p>To enable access to the camera in <b>Xiaomi Mi</b> browser, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>In MI browser, press the button <b>Profile</b> (a human silhouette in the lower right corner).</li>" +
    "<li>Press the button <b>Settings</b> (a hexagon) beside the number/name of your MI account.</li>" +
    "<li>Scroll down the settings page and select <b>Site settings</b>.</li>" +
    "<li>Select <b>Camera</b>.</li>" +
    "<li>Switch on the radio button <b>Camera</b>.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_yandex:
    "" +
    "<p>To enable camera access in <b>Yandex Browser</b> on an Android device, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>With the biometric verification page of the application open, press the menu button (<b>“three points”</b>) in the browser's lower right corner.</li>" +
    "<li>Select <b>About the site</b>.</li>" +
    "<li>Switch on the radio button <b>Camera</b>.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_instagram:
    "" +
    "<p>If the camera won’t turn on in the built-in <b>Instagram</b> browser, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>With the biometric verification page of the application open, press the menu button (<b>“three points”</b>) in the browser's upper right corner.</li>" +
    "<li>Select <b>Open with...</b> or <b>Open in Browser</b> where <b>Browser</b> is the browser you have assigned by default (e. g. Chrome or any other).</li>" +
    "<li>Select <b>any browser</b> in the window that will appear. A link to the biometric verification site will open in the browser app.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_facebook:
    "" +
    "<p>If the camera won’t turn on in the built-in <b>Facebook</b> browser, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>With the biometric verification page of the application open, press the button <b>“three points”</b> in the browser's upper right corner.</li>" +
    "<li>Select <b>Open with...</b> or <b>Open in Browser</b> where <b>Browser</b> is the browser you have assigned by default (e. g. Chrome or any other browser).</li>" +
    "<li>Select <b>any browser</b> in the window that will appear. A link to the biometric verification site will open in the browser app.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_samsung:
    "" +
    "<p>Unless the <b>Samsung</b> browser is allowed to use the camera in the phone settings, at the request to access the camera the following message will appear, “Allow the Internet browser access the camera to use it in this site”, accompanied with the button <b>Update permissions</b>.</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>Press the button <b>Update permissions</b>.</li>" +
    "<li>A permission request window will appear. In this window, press <b>Allow</b>. Then try again to pass the biometric verification.</b></li>" +
    "</ul>" +
    "<p>If you pressed <b>Cancel</b> and flagged the option <b>“Don’t ask me again”</b> at some earlier point, then proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>Press the menu button (<b>“three stripes”</b>) in the browser's lower right corner.</li>" +
    "<li>Select <b>Settings</b></li>" +
    "<li>Scroll down the settings page and select <b>About the Internet app</b></li>" +
    "<li>Press the button “i” (Information) in the upper right corner. A window with application data from the phone settings will open</li>" +
    "<li>Select <b>App permissions</b></li>" +
    "<li>Switch on the radio button <b>Camera</b></li>" +
    "</ul>" +
    "<p><b>Cleaning up cash in Samsung browser.</b><br>If the radio button <b>Camera</b> is switched on, but still no access request appears in the site, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>Press the menu button <b>“three stripes”</b> in the browser's lower right corner.</li>" +
    "<li>Select <b>Settings</b></li>" +
    "<li>Then select <b>Personal Information</b></li>" +
    "<li>Select <b>Clear browsing data</b></li>" +
    "<li>Keep flagged these options: <b>Cash</b> and <b>Cookies and site data</b></li>" +
    "<li>Press the button <b>Clear</b></li>" +
    "</ul>",

  purelive_tutorial_camera_android_firefox:
    "" +
    "<p>To enable camera access in <b>Firefox</b> browser on an Android device, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>In Firefox, press the menu button (<b>“three points”</b>).</li>" +
    "<li>Select <b>Settings</b>.</li>" +
    "<li>Select <b>Site permissions</b>.</li>" +
    "<li>Select <b>Camera</b>.</li>" +
    "<li>Select the option <b>Always ask</b>.</li>" +
    "<li>If the access still remains blocked by Android, press the button <b>Go to Settings</b>. In the Settings window, select <b>App permissions</b> and switch on the radio button <b>Camera</b>.</li>" +
    "</ul>",

  purelive_tutorial_camera_android_opera:
    "" +
    "<p>To enable camera access in <b>Opera</b> browser on an Android device, proceed as follows:</p>" +
    '<ul class="purelive_tutorial">' +
    "<li>In <b>Opera</b>, press the button <b>O</b> in the browser’s lower right corner.</li>" +
    "<li>Select <b>Settings</b>.</li>" +
    "<li>In the section <b>Confidentiality</b>, select <b>Site settings</b>.</li>" +
    "<li>Select <b>Camera</b>.</li>" +
    "<li>Switch on the radio button <b>Camera</b> - <b>Always ask</b>.</li>" +
    "<li>If the permission was accidentally canceled earlier, the site address will be displayed in the section <b>Canceled</b>. After you press <B>CLEAR PERMISSIONS</b>, the site will again request access to the camera when passing the biometric verification.</li>" +
    "</ul>",
};

let styles = {
  baseColorCustomization: {
    textColorPrimary: "#000000",
    backgroundColorPrimary: "#FFFFFF",
    textColorSecondary: "#8E8E93",
    backgroundColorSecondary: "#F2F2F7",
    iconColor: "#00A5BA",
  },
  baseFontCustomization: {
    textFont: "Roboto, sans-serif",
    textSize: "16px",
    textWeight: "400",
    textStyle: "normal",
  },
  titleFontCustomization: {
    textFont: "inherit",
    textSize: "16px",
    textWeight: "500",
    textStyle: "normal",
  },

  toolbarCustomization: {
    closeButtonIcon: "cross",
    iconColor: "#000",
  },
  centerHintCustomization: {
    textFont: "inherit",
    textSize: "24px",
    textWeight: "500",
    textStyle: "normal",
    textColor: "#FFFFFF",
    backgroundColor: "#1C1C1E",
    backgroundOpacity: "56%",
    backgroundCornerRadius: "14px",
    verticalPosition: "38%",
  },
  hintAnimation: {
    hideAnimation: false,
    hintGradientColor: "#00BCD5",
    hintGradientOpacity: "100%",
    animationIconSize: "80px",
  },
  faceFrameCustomization: {
    geometryType: "oval",
    cornersRadius: "0px",
    strokeDefaultColor: "#D51900",
    strokeFaceInFrameColor: "#00BCD5",
    strokeOpacity: "100%",
    strokeWidth: "6px",
    strokePadding: "4px",
  },

  backgroundCustomization: {
    backgroundColor: "#FFFFFF",
    backgroundOpacity: "88%",
  },

  versionTextCustomization: {
    textFont: "inherit",
    textSize: "16px",
    textWeight: "500",
    textStyle: "normal",
    textColor: "#000000",
    textOpacity: "56%",
  },
};

let baseUrl = `https://devrhserv.server.eface.ae/Finneva/`;
let livenessUrl = `https://gw.purelive.ae/api/v3/`;
export const useEfr = (token, configurationToken, temporaryToken, callback) => {
  const EfrSDK = window.EfrSDK;
  // console.log(window);
  const [authToken, setAuthToken] = useState(token);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  // const [isLivenessCompleted, setIsLivenessCompleted] = useState(false);

  // const [isError, setIsError] = useState(false);

  // const [efrError, setEfrError] = useState();

  //const { t } = useTranslation();

  //const [efrState, efrDispatch] = useReducer(efrReducer, efrInitialState);

  // const onInitialErrorEFR = (error) => {
  //   setIsError(true);

  //   setEfrError(error);

  //   // onInitializeErrorEFR && onInitializeErrorEFR(error);
  // };

  // const efrResetError = () => {
  //   setIsError(false);

  //   setEfrError(undefined);
  // };

  const onInitiateSDK = (config, secret) => {
    const efrConfig = {
      configValue: config,

      secretKey: secret,

      on_success: () => {
        console.log("successs");
        EfrSDK.getInstance.setLivenessChecks(livenessChekList);

        EfrSDK.getInstance.setLocale(langIdentifiersForLiveness);
        EfrSDK.getInstance.setStyles(styles);
        EfrSDK.getInstance.setTimeout(30);
        console.log(
          EfrSDK.getInstance.getVersion(),
          EfrSDK.getInstance.getExpiryDate()
        );
        EfrSDK.getInstance.startProcess({
          on_progress: function () {
            document.getElementById("loader").style.display = "block";
          },
          on_feedback: function (result) {
            // feedBackHandler(result);
            resultCallback(result)
          },
          on_error: function (error) {
            document.getElementById("loader").style.display = "none";
            console.log(error);
            setLoading(false);
          },
          on_close: function () {
            setLoading(false);
          },
          on_timeout: function (res) {
            setLoading(false);
            console.log("on_timeout========", res);
          },
        });
      },

      on_error: (err) => {
        setLoading(false);
        console.log(err);
      },
    };

    EfrSDK.getInstance.initialize(efrConfig);
  };

  useEffect(() => {
    // if (secretKey?.value && configValue && isInitialLoadSdk) {
    // onInitiateSDK();
    // }
    setLoading(true);
    setAuthToken(token);
    // getPureliveConfig(authToken);
  }, []);
  useEffect(() => {
    // // if (secretKey?.value && configValue && isInitialLoadSdk) {
    // // onInitiateSDK();
    // // }
    // login();
    // getPureliveConfig(authToken);
    onInitiateSDK(configurationToken, temporaryToken);
  }, [authToken != ""]);

  const feedBackHandler = async (feedBack) => {
    console.log(authToken);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + authToken);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: feedBack,
      redirect: "follow",
    };
    let response = await fetch(livenessUrl + "sdk/liveness", requestOptions);

    if (response.ok && response.status == 200) {
      let resposeJson = await response.json();
      EfrSDK.getInstance.executeFeedback({
        data: resposeJson.data,
        on_result: function (result) {
          resultCallback(result);
        },
      });
    }
  };

  async function resultCallback(finalResult) {
    setLoading(false);
    callback(finalResult);
    setData(finalResult);
    console.log(finalResult);
  }

  // const onInitiateSDKKeys = async () => {
  //   // efrResetError();
  //   // tempKeyApi.getTempKey();
  //   // configApi.efrSdkConfigExecute();
  // };

  // const getPureliveConfig = async (rhservAuthToken) => {
  //   if (!authToken) {
  //     return "";
  //   }
  //   console.log(authToken);
  //   // return;
  //   try {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", "Bearer " + rhservAuthToken);
  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };
  //     let result = await fetch(livenessUrl + "sdk/configuration", requestOptions);

  //     if (result.status === 200) {
  //       await result.json().then((resultConfig) => {
  //         if (resultConfig) {
  //           var config = resultConfig.data;

  //           // ocrInitialize(config);
  //           setSecretKey(config);
  //         }
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // const setSecretKey = async (config) => {
  //   try {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", "Bearer " + authToken);
  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };
  //     let result = await fetch(
  //       baseUrl + "rhapi/Mgmt/GetTemporaryKey",
  //       requestOptions
  //     );

  //     if (result.status === 200) {
  //       await result.json().then((secretKey) => {
  //         onInitiateSDK(config, secretKey);
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  return { loading, data };
};
