import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import config from '../../config';
import client from '../../client';
import { toJS } from '../../containers/to-js';
import JSONPath from 'jsonpath';
import { setTimeout } from 'timers';
import resolve from '../../utils/resolvePath';
import TwilioConference from './conferenceProvider/TwilioConference';
// var NextRTC = require('nextrtc-js-client');

// class VideoConference extends React.Component {
//   createInstance = () => {
//     let user = localStorage.getItem('user') || null;
//     user = typeof user === 'string' && JSON.parse(user);
//     let token = '';
//     if (user !== null && user !== false && user.token !== null)
//       token = user.token;
//     var object = new NextRTC({
//       //TODO: Set up via config
//       wsURL: `${config.socketAPI}?token=${token}`,
//       mediaConfig: {
//         video: true,
//         audio: true,
//       },
//       peerConfig: {
//         iceServers: [
//           {
//             urls: ['stun:34.242.219.115:3478', 'stun:stun.l.google.com:19302'],
//           },
//         ],
//         iceTransportPolicy: 'all',
//         rtcpMuxPolicy: 'negotiate',
//       },
//     });
//     return object;
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       destroyed: false,
//       localStream: null,
//       remoteStream: null,
//       remoteStreams: [],
//       conferenceStarted: false,
//       conferenceJoined: false,
//       conferenceId: null,
//       videoEnabled: true,
//       isOwner: false,
//       muted: false,
//       provider: null,
//       interval: null,
//       token: null,
//       roomName: null,
//     };
//     this.nextRtc = null;
//   }
//   mute = localStream => {
//     localStream.getAudioTracks()[0].enabled = this.state.muted;
//     this.setState({
//       muted: !this.state.muted,
//     });
//   };
//   toggleVideo = localStream => {
//     localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0]
//       .enabled;
//     this.setState({
//       videoEnabled: localStream.getVideoTracks()[0].enabled,
//     });
//   };
//   leaveConversation = () => {
//     if (this.nextRtc) {
//       this.nextRtc.leave();
//       this.nextRtc.close();
//       this.nextRtc = null;
//     }
//     const { localStream } = this.state;
//     localStream && localStream.getTracks().forEach(track => track.stop());
//     this.setState({
//       destroyed: true,
//       localStream: null,
//       remoteStream: null,
//       conferenceJoined: false,
//     });
//   };
//   joinConference = () => {
//     const {
//       workflow: { workflowData },
//       layoutItem: {
//         itemProperties: {
//           videoConfConfig: { conferenceIdField, oneWayConf },
//         },
//       },
//     } = this.props;
//     //TODO: Support for one way conference
//     const confId = resolve(conferenceIdField, workflowData);
//     var self = this;
//     const startUrl = `${config.conferenceAPI}start`;

//     client()
//       .post(startUrl, {
//         confId,
//       })
//       .then(res => {
//         if (res.data) {
//           self.setState({ conferenceStarted: true, conferenceJoined: true });
//           this.setupRTC();
//           setTimeout(() => this.nextRtc.join(confId), 1000); //Giving a second to join
//         } else {
//           //TODO: Settimeout to make a call again
//         }
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   };
//   isEmptyList = obj => {
//     for (var key in obj) {
//       if (obj.hasOwnProperty(key)) return false;
//     }
//     return true;
//   };
//   setupRTC = () => {
//     if (!this.nextRtc) {
//       this.nextRtc = this.createInstance();
//       var self = this;
//       this.nextRtc.getLocalStream(function (stream) {
//         const { destroyed } = self.state;
//         if (destroyed) return;
//         self.setState({ localStream: stream });
//       });
//       this.nextRtc.on('textMessage', function (message) {
//         console.log('Received text message - ', message);
//       });

//       this.nextRtc.on('remoteStream', function (stream) {
//         const { destroyed } = self.state;
//         if (destroyed) return;
//         let streams = self.state.remoteStreams;
//         streams[stream.member] = stream.stream;
//         self.nextRtc.onPeerConnection(stream.member, function (peer) {
//           peer.oniceconnectionstatechange = function (change) {
//             if (
//               peer.iceConnectionState === 'failed' ||
//               peer.iceConnectionState === 'disconnected' ||
//               peer.iceConnectionState === 'closed'
//             ) {
//               delete streams[stream.member];
//               self.setState({ remoteStreams: streams });
//             }
//           };
//         });
//         self.setState({ remoteStreams: streams });
//       });
//       this.nextRtc.on('MEMBER_LEFT', function (member) {
//         console.log('MEMBER LEFT');
//       });
//     }
//   };
//   componentDidMount() {
//     const {
//       workflow: { workflowData },
//       layoutItem: {
//         itemProperties: {
//           videoConfConfig: { conferenceIdField },
//         },
//       },
//     } = this.props;
//     const confId = resolve(conferenceIdField, workflowData);
//     console.log(workflowData);
//     const checkUrl = `${config.conferenceAPI}check`;
//     console.log('CheckUrl ', checkUrl);
//     confId &&
//       client()
//         .post(checkUrl, {
//           confId,
//         })
//         .then(res => {
//           if (res.data) {
//             console.log('res', res.data);
//             this.setState(
//               {
//                 conferenceStarted: res.data.data.hasStarted,
//                 isOwner: res.data.data.owner,
//                 roomName: res.data.data.confId,
//                 token: res.data.data.accessToken,
//                 provider: res.data.data.providerName,
//               },
//               () => {
//                 (this.state.conferenceStarted || this.state.isOwner) &&
//                   clearInterval(interval);
//               }
//             );
//           }
//         })
//         .catch(error => {
//           console.error('CHECK', error);
//         });

//     const interval =
//       !this.state.isOwner &&
//       setInterval(() => {
//         confId &&
//           client()
//             .post(checkUrl, {
//               confId,
//             })
//             .then(res => {
//               if (res.data) {
//                 this.setState(
//                   {
//                     conferenceStarted: res.data.data.hasStarted,
//                     isOwner: res.data.data.owner,
//                     roomName: res.data.data.confId,
//                     token: res.data.data.accessToken,
//                   },
//                   () => {
//                     (this.state.conferenceStarted || this.state.isOwner) &&
//                       clearInterval(interval);
//                   }
//                 );
//               }
//             })
//             .catch(error => {
//               console.error('CHECK', error);
//             });
//       }, 30000);
//   }
//   componentWillUnmount() {
//     this.state.interval && clearInterval(this.state.interval);
//     this.leaveConversation();
//   }
//   render() {
//     const {
//       localStream,
//       remoteStreams,
//       conferenceStarted,
//       conferenceJoined,
//       muted,
//       provider,
//     } = this.state;
//     const remoteVideoStyle = {
//       width: '80%',
//       height: '40%',
//     };
//     const overlayStyle = {
//       position: 'fixed',
//       right: '10px',
//       bottom: '0px',
//     };
//     const buttonStyle = {
//       width: '300px',
//       float: 'left',
//     };
//     const actionButtonGroup = {
//       position: 'absolute',
//       bottom: '30px',
//       left: '30px',
//       zIndex: '1',
//     };
//     const actionButtonStyles = {
//       borderRadius: '100px !important',
//       padding: '9px',
//       margin: '3px',
//       width: '40px',
//     };

//     if(provider === null) {
//       return <span>...</span>
//     }

//     if (provider === 'twilio-video-conf') {
//       return <TwilioConference joinConference={this.joinConference} {...this.state} {...this.props} />;
//     }

//     return (
//       <div className='video-conference'>
//         {!conferenceJoined ? (
//           !conferenceStarted ? (
//             <div>
//               <div>
//                 If you are the organizer of the conference, please click on
//                 Start button. When its time, you can click the Join button to
//                 join the video conference.
//               </div>
//               <div>
//                 <Button
//                   style={buttonStyle}
//                   name='join'
//                   onClick={this.joinConference}
//                   block
//                   color='primary'
//                 >
//                   {this.state.isOwner ? 'Start' : 'Join'}
//                 </Button>
//               </div>
//             </div>
//           ) : (
//             <div>
//               <div>
//                 Your conference has already started. Please click the Join
//                 button to get into the conference
//               </div>
//               <div>
//                 <Button
//                   style={buttonStyle}
//                   name='join'
//                   onClick={this.joinConference}
//                   block
//                   color='primary'
//                 >
//                   Join
//                 </Button>
//               </div>
//             </div>
//           )
//         ) : (
//           <div>
//             <div style={remoteVideoStyle}>
//               <Row>
//                 {this.isEmptyList(remoteStreams) ? (
//                   <div>No participants yet</div>
//                 ) : (
//                   Object.keys(remoteStreams).map((remoteStreamKey, index) => {
//                     const remoteStream = remoteStreams[remoteStreamKey];
//                     return (
//                       <Col md='6'>
//                         <video
//                           width='100%'
//                           autoPlay
//                           playsInline
//                           ref={remoteVideo => {
//                             if (remoteVideo) {
//                               remoteVideo.srcObject = remoteStream;
//                               remoteVideo.playsinline = 'true';
//                               remoteVideo.autoplay = 'true';
//                             }
//                           }}
//                         ></video>
//                       </Col>
//                     );
//                   })
//                 )}
//               </Row>
//             </div>
//           </div>
//         )}
//         <div style={overlayStyle}>
//           <div>
//             {localStream == null ? (
//               <div>No Local video yet</div>
//             ) : (
//               <div>
//                 <div
//                   className='video-conference-action-buttons'
//                   style={actionButtonGroup}
//                 >
//                   <Button
//                     name='toggleVideo'
//                     style={actionButtonStyles}
//                     onClick={() => this.toggleVideo(localStream)}
//                     color={this.state.videoEnabled ? 'muted' : 'danger'}
//                     title={
//                       this.state.videoEnabled ? 'Stop Video' : 'Start Video'
//                     }
//                   >
//                     <i
//                       className={`fa ${
//                         this.state.muted ? 'fa-video-camera' : 'fa-video-camera'
//                       } m-1`}
//                       aria-hidden='true'
//                     ></i>
//                   </Button>
//                   <Button
//                     style={actionButtonStyles}
//                     name='mute'
//                     onClick={() => this.mute(localStream)}
//                     color='warning'
//                     title={this.state.muted ? 'Unmute' : 'Mute'}
//                   >
//                     <i
//                       className={`fa ${
//                         this.state.muted
//                           ? 'fa-microphone-slash'
//                           : 'fa-microphone'
//                       } m-1`}
//                       aria-hidden='true'
//                     ></i>
//                   </Button>
//                   <Button
//                     style={actionButtonStyles}
//                     name='hangup'
//                     onClick={() => this.leaveConversation()}
//                     color='danger'
//                     title='Disconnect'
//                   >
//                     <i className='fa fa-phone' aria-hidden='true'></i>
//                   </Button>
//                 </div>
//                 <video
//                   width='200px'
//                   height='200px'
//                   autoPlay
//                   playsInline
//                   muted
//                   ref={localVideo => {
//                     if (localVideo) {
//                       localVideo.srcObject = localStream;
//                       localVideo.playsinline = 'true';
//                       localVideo.autoplay = 'true';
//                       localVideo.muted = 'true';
//                     }
//                   }}
//                 ></video>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

const VideoConference = () => {
  return <div>Video</div>
}

export default VideoConference;
