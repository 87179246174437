import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col } from 'reactstrap';

class DropdownFilter extends Component {
    constructor(props) {
        super(props);
        const { filterStateMap, filterIndex, filter } = props;
        const savedFilterState = filterStateMap ? filterStateMap[`${filter.paramName}`] : '';
        this.state = {
            options: []
        }
    }
    
    handleChange(selectedOption) {
        const { onFilterChange, filter, filterIndex } = this.props;
        selectedOption = !selectedOption ? '' : selectedOption;
        this.setState({ selectedOption });
		let selectedVal = '';
		if(selectedOption.length) {
				selectedOption.map((val) => {
				selectedVal = `${selectedVal}${val.value},`;
			});
			selectedVal = selectedVal.substring(0, selectedVal.lastIndexOf(','));
		} else {
			selectedVal = selectedOption.value;
		}
		
        onFilterChange(filter.paramName, selectedVal);
    }
    componentDidMount() {
        const { filter: {staticValue} } = this.props;
		let values = staticValue.split(',');
		const options = values.map((val) => {
			return {
				value: val,
				label: val
			};
		});
        this.setState({ options });
    }
    
    render() {
        const { filter, elem_id, filter: {type} } = this.props;
        const { selectedOption, options } = this.state;
		const isMulti = type == 'MULTISELECT' ? true : false;
        return (
            <Col xs="12" className='w-100 p-0'>
                <Select
                    name={elem_id}
                    value={selectedOption}
                    onChange={this.handleChange.bind(this)}
                    options={options}
                    isMulti={isMulti}
                    className={'Select-Control'}
                    classNamePrefix={'Select-Control-inner'}
                />
            </Col>
        );
    }
}

DropdownFilter.propTypes = {

};

export default DropdownFilter;