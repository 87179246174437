import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { next } from './workflow-item';
import { setUser } from './user';

export const FETCH_NAV_WORKFLOWS = 'FETCH_NAV_WORKFLOWS';
export const FETCH_PUBLIC_NAV_WORKFLOWS = 'FETCH_PUBLIC_NAV_WORKFLOWS';
export const FETCH_NAV_WORKFLOWS_SUCCESS = 'FETCH_NAV_WORKFLOWS_SUCCESS';
export const FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS = 'FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS';
export const FETCH_NAV_WORKFLOWS_FALIURE = 'FETCH_NAV_WORKFLOWS_FALIURE';
export const FETCH_PUBLIC_NAV_WORKFLOWS_FALIURE = 'FETCH_PUBLIC_NAV_WORKFLOWS_FALIURE';

export const START_WORKFLOW = 'START_WORKFLOW';
export const START_WORKFLOW_SUCCESS = 'START_WORKFLOW_SUCCESS';
export const BASE_WORKFLOW_SUCCESS = 'BASE_WORKFLOW_SUCCESS';
export const START_WORKFLOW_FAILURE = 'START_WORKFLOW_FAILURE';

export const NEXT_BACKGROUND_WAIT = 'NEXT_BACKGROUND_WAIT';

export const REFRESH_BASE_WORKFLOW_TRUE = 'REFRESH_BASE_WORKFLOW_TRUE';
export const REFRESH_BASE_WORKFLOW_FALSE = 'REFRESH_BASE_WORKFLOW_FALSE';

export const END_WORKFLOW = 'END_WORKFLOW';
export const END_WORKFLOW_SUCCESS = 'END_WORKFLOW_SUCCESS';
export const END_WORKFLOW_FAILURE = 'END_WORKFLOW_FAILURE';

export const LOAD_BG = 'LOAD_BG';
export const LOAD_BG_SUCCESS = 'LOAD_BG_SUCCESS';
export const LOAD_BG_FAILURE = 'LOAD_BG_FAILURE';

export const REDIRECT_TO_DASHBOARD = 'REDIRECT_TO_DASHBOARD';
export const RESET_WORKFLOW_STATE = 'RESET_WORKFLOW_STATE';
export const RESET_SORT_PAGINATION = 'RESET_SORT_PAGINATION';
export const SET_SORT_PAGINATION = 'SET_SORT_PAGINATION';

export const GET_OFFLINE_WORKFLOWS = 'GET_OFFLINE_WORKFLOWS';
export const GET_OFFLINE_WORKFLOWS_SUCCESS = 'GET_OFFLINE_WORKFLOWS_SUCCESS';
export const GET_OFFLINE_WORKFLOWS_FAILURE = 'GET_OFFLINE_WORKFLOWS_FAILURE';

export const SET_WORKFLOW_FOR_WORKFLOWCARD = 'SET_WORKFLOW_FOR_WORKFLOWCARD';

export const fetchNavWorkflows = () => {
    return (dispatch, getState) => {
        const isAnonymous = getState().getIn(['user', 'isAnonymous']);
        const userObj = getState().getIn(['user', 'user']);
        const token = userObj?userObj.token:'';
        dispatch({
            type: FETCH_NAV_WORKFLOWS,
            payload: {}
        });
        if(window.menuCache && window.menuCache[token]) {
            dispatch({
                type: FETCH_NAV_WORKFLOWS_SUCCESS,
                payload: window.menuCache[token]
            });
            return;
        }        
        dispatch(showLoading());
        client().post(`${isAnonymous ? config.publicAPI : config.authAPI}/settings/menu/all`).then((res) => {
            if(!window.menuCache) window.menuCache = [];
            window.menuCache[token] =res.data;
            dispatch({
                type: FETCH_NAV_WORKFLOWS_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
            localStorage.setItem('menues', JSON.stringify(res.data))
        }).catch((error) => {
            dispatch({
                type: FETCH_NAV_WORKFLOWS_FALIURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const fetchNavWorkflowsPublic = () => {
    return (dispatch, getState) => {
        const isAnonymous = getState().getIn(['user', 'isAnonymous']);
        const userObj = getState().getIn(['user', 'user']);
        const token = userObj?userObj.token:'';
        dispatch({
            type: FETCH_PUBLIC_NAV_WORKFLOWS,
            payload: {}
        });
        if(window.menuCache && window.menuCache[token]) {
            dispatch({
                type: FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS,
                payload: window.menuCache[token]
            });
            return;
        }        
        dispatch(showLoading());
        client().post(`${isAnonymous ? config.publicAPI : config.publicAPI}/settings/menu/all`).then((res) => {
            if(!window.menuCache) window.menuCache = [];
            window.menuCache[token] =res.data;
            dispatch({
                type: FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
            localStorage.setItem('menues-public', JSON.stringify(res.data))
        }).catch((error) => {
            dispatch({
                type: FETCH_PUBLIC_NAV_WORKFLOWS_FALIURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const startWorkflow = (payload, endPoint = '/workflow/start') => {
    return (dispatch, getState) => {
        const isAnonymous = getState().getIn(['user', 'isAnonymous']);
        dispatch({
            type: START_WORKFLOW,
            payload: {}
        });
        const cacheKey = (payload.id? payload.id:"D") + '_' + (payload.tid?payload.tid:'NaN');
        let showLoadingFlag = true;
        // if(window.workflowCache && window.workflowCache[cacheKey]) {
        //     dispatch({
        //         type: payload.isBase ? BASE_WORKFLOW_SUCCESS : START_WORKFLOW_SUCCESS,
        //         payload: window.workflowCache[cacheKey]
        //     });
        //     showLoadingFlag = false;
        // }
        // if(showLoadingFlag)
            dispatch(showLoading());
        if(!window.navigator.onLine) {
            let offlineWorkflows = localStorage.getItem('offlineWfList')
            if(offlineWorkflows)
            {
                offlineWorkflows = JSON.parse(offlineWorkflows);
                offlineWorkflows.map(item => {
                    if(payload.id == item.id){
                        dispatch({
                            type: payload.isBase ? BASE_WORKFLOW_SUCCESS : START_WORKFLOW_SUCCESS,
                            payload: item
                        });
                        dispatch(hideLoading());
                    }
                })
            }
        } else {
            client().post(`${isAnonymous ? config.publicAPI : config.authAPI}${endPoint}`, payload).then((res) => {
                const { redirecToDashboard } = res.data;
                if (redirecToDashboard === true) {
                    dispatch({
                        type: REDIRECT_TO_DASHBOARD,
                        payload: {}
                    });
                }
                else {
                    if(!window.workflowCache) window.workflowCache= [];
                    // window.workflowCache[cacheKey] = res.data;
                    dispatch({
                        type: payload.isBase ? BASE_WORKFLOW_SUCCESS : START_WORKFLOW_SUCCESS,
                        payload: res.data
                    });
                }       
                if(showLoadingFlag)
                    dispatch(hideLoading());
            }).catch((error) => {
                console.log('error', error)
                dispatch({
                    type: START_WORKFLOW_FAILURE,
                    payload: {
                        hasError: true,
                        error
                    }
                });
                if(showLoadingFlag)
                    dispatch(hideLoading());
            });
        }
        
    }
}

export const startPublicDefaultWorkflow = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: START_WORKFLOW,
            payload: {}
        });
        dispatch(showLoading());
        client().post(`${config.publicAPI}/workflow/start`, payload).then((res) => {
            dispatch({
                type: payload.isBase ? BASE_WORKFLOW_SUCCESS : START_WORKFLOW_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: START_WORKFLOW_FAILURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const startPublicLinkWorkflow = (payload) => {
    return (dispatch, getState) => {
        client().post(`${config.publicAPI}/workflow/link/start`, payload).then((res) => {
            dispatch(setUser({
                user: {
                    token: res.data.data.latestToken,
                },
                isAnonymous: false
            }));
            dispatch(startWorkflow({ id: res.data.data, isBase: true, displayMode: 'DEFAULT' }));
        }).catch((error) => {
            dispatch({
                type: START_WORKFLOW_FAILURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const endCurrentBaseWorkflow = () => {
    return (dispatch, getState) => {
        const baseWorkflow = getState().getIn(['workflow', 'baseWorkflow']);
        const isAnonymous = getState().getIn(['user', 'isAnonymous']);
        const payload = {
            workflowId: baseWorkflow.get('workflowModel'),
            sessionId: baseWorkflow.get('sessionId')
        }
        dispatch({
            type: REFRESH_BASE_WORKFLOW_TRUE,
            payload: {}
        })
        client().post(`${isAnonymous ? config.publicAPI : config.authAPI}/workflow/end`, payload);
    }
}

export const toggleRefreshBaseWorkflow = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: flag ? REFRESH_BASE_WORKFLOW_TRUE : REFRESH_BASE_WORKFLOW_FALSE,
            payload: {}
        })
    }
}

export const endWorkflow = (payload) => {

    return (dispatch, getState) => {
        const isAnonymous = getState().getIn(['user', 'isAnonymous']);

        dispatch({
            type: END_WORKFLOW,
            payload: {}
        });
        dispatch(showLoading());
        client().post(`${isAnonymous ? config.publicAPI : config.authAPI}/workflow/end`, payload).then((res) => {
          if(res.data && res.data.autoProgress && !res.data.workflowEnd) {
            dispatch(next(res.data));
          } else {
            dispatch({
                type: END_WORKFLOW_SUCCESS,
                payload: res.data
            });
          }
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: END_WORKFLOW_FAILURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const loadAndSetBinaryBg = (path) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_BG,
            payload: {}
        });
        dispatch(showLoading());
        client().get(`${config.pathResolverAPI}${path}`).then((res) => {
            dispatch({
                type: LOAD_BG_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: LOAD_BG_FAILURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const getWorkflowFromCode = (code) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/workflow/fromunique`, { code });
    }
}

export const getWorkflowById = (id) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/workflows/${id}`);
    }
}

export const getModelByNamePromise = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/model/findbyname`, payload);
    };
};


export const clearWorkflowState = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_WORKFLOW_STATE,
            payload: null
        });
    }
}

export const resetSortAndPagination = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_SORT_PAGINATION,
            payload: null
        });
    }
}

export const setSortAndPagination = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_PAGINATION,
            payload: payload
        });
    }
}

export const getOfflineWorkflows = () => {
    const offlineWfApi = `${config.authAPI}/getOfflineWorkflows`;
    return (dispatch) => {
        client().post(`${offlineWfApi}`).then((res) => {
            console.log(res.data)
            localStorage.setItem('offlineWfList', JSON.stringify(res.data))
        }).catch((error) => {
            console.log(error)
        });
    };
}

export const setWorkflowForWorkflowCard = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_WORKFLOW_FOR_WORKFLOWCARD,
            payload: payload
        });
    }
}