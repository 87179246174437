import React, { Component } from 'react';
import { operation as operations } from '../../dto/workflow';
import CreateUpdateContainer from '../../containers/workflow/operations/CreateUpdateContainer';
import ReadOneContainer from '../../containers/workflow/operations/ReadOneContainer';
import ReadAllContainer from '../../containers/workflow//operations/ReadAllContainer';
import CreateUpdateHeader from './operations/headers/CreateUpdateHeader';
import ReadAllHeader from './operations/headers/ReadAllHeader';
import WorkflowMessages from './messages/index';
import WorkflowSubMenu from './operations/workflow-submenu/index';
import * as ActionContainers from '../action-types/index';
import ActionContainer from '../../containers/workflow/ActionContainer';
import * as ContainerTemplates from '../templates/container/index';
import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';
import client from '../../client';
import config from '../../config';
import { TabContent, TabPane, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Collapse, Jumbotron } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Stepper from '../commons/stepper';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { setTimeout, clearTimeout } from 'timers';
import DataStore from '../../utils/DataStore'
import WorkflowSubMenuEndButton from './operations/workflow-submenu/WorkflowSubmenuEndButton';
import { lastIndexOf } from 'lodash';
import workflow from '../../reducers/workflow';


class WorkflowItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submenuActiveIndex: 0,
            currentViews: {},
            bgProcessStatus: null,
            activeContainer: -1,
            stepsVisited: []
        }
        this.componentBody = {
            [operations.READALL]: ReadAllContainer,
            [operations.READONE]: ReadOneContainer,
            [operations.CREATEUPDATE]: CreateUpdateContainer
        }
        this.componentHeader = {
            [operations.READALL]: ReadAllHeader,
            [operations.READONE]: CreateUpdateHeader,
            [operations.CREATEUPDATE]: CreateUpdateHeader
        }
        this.getInlineActions = this.getInlineActions.bind(this);
        this.changeViewParent = this.changeViewParent.bind(this);
        this.getUIActions = this.getUIActions.bind(this);
    }
    changeViewParent(index, view) {
        let { currentViews } = this.state;
        currentViews[index] = view;
        this.setState({ currentViews });
    }

    componentDidMount() {
        const {
            workflow: {
                currentItem: {
                    layoutProperties: {
                        workflowSubmenuConfig
                    }
                }
            },
            customStyles
        } = this.props;
        const { getCompanyDetails } = this.props;
        getCompanyDetails && getCompanyDetails();
        const hasSubmenu = workflowSubmenuConfig && workflowSubmenuConfig.isActive && workflowSubmenuConfig.items.length;
        if (hasSubmenu) {
            this.onSubmenuClicked((workflowSubmenuConfig.initialTab && workflowSubmenuConfig.initialTab.value) ? workflowSubmenuConfig.initialTab.value - 1 : 0);
        }
        if(window[`styleCall`] !== 1 && !customStyles.data)
            this.props.getCustomStyles();
    }

    componentWillReceiveProps(nextProps) {
        const currentId = nextProps.workflow.currentItem && nextProps.workflow.currentItem.id;
        const prevId = this.props.workflow.currentItem && this.props.workflow.currentItem.id;
        let { stepsVisited }= this.state;
        const {
            workflow: {
                currentItem: {
                    layoutProperties: {
                        workflowSubmenuConfig
                    }
                }
            }
        } = this.props;
        if (currentId !== prevId) {
            let submenuActiveIndex = 0;
            if (nextProps.workflow && nextProps.workflow.workflowCode && global[`submenu${nextProps.workflow.workflowCode}`]) {
                submenuActiveIndex = global[`submenu${nextProps.workflow.workflowCode}`];
            }
            this.setState({ submenuActiveIndex })
        }

        const f = stepsVisited.find(s => s == nextProps.workflow.currentStep)
        if(typeof f == 'undefined'){
            stepsVisited.push(nextProps.workflow.currentStep)
            this.setState(stepsVisited)
        } else {
            for(let i= 0; i < stepsVisited.length; i++){
                if(stepsVisited[i] > nextProps.workflow.currentStep){
                    stepsVisited.splice(i)
                }
            }
            this.setState(stepsVisited);
        }
    }
    getInlineActions(workflowItem, filterContainerId) {
        if (!workflowItem)
            return null;
        let actions = {
            globals: [],
            inlines: []
        };
        if (typeof workflowItem.inlineActionPlacement === 'object') {
            if ((filterContainerId || filterContainerId === 0) && workflowItem.inlineActionPlacement.globals) {
                workflowItem.inlineActionPlacement.globals.forEach(action => {
                    if (action.actionProperties.targetContainer !== undefined && action.actionProperties.targetContainer == filterContainerId)
                        actions.globals.push(action);
                    else if ((action.actionProperties.targetContainer === undefined || action.actionProperties.targetContainer === null) && filterContainerId)
                        actions.globals.push(action);
                });
            } else {
                workflowItem.inlineActionPlacement.globals.forEach(action => {
                    if (!action.actionProperties.targetContainer)
                        actions.globals.push(action);
                });
            }


            if ((filterContainerId || filterContainerId === 0) && workflowItem.inlineActionPlacement.inlines) {
                workflowItem.inlineActionPlacement.inlines.forEach(action => {
                    if (action.actionProperties.targetContainer != null &&
                        action.actionProperties.targetContainer == filterContainerId) {
                        actions.inlines.push(action);
                    } else if (action.actionProperties.targetContainer === null || action.actionProperties.targetContainer === undefined)
                        actions.inlines.push(action);
                });
            } else
                actions.inlines = workflowItem.inlineActionPlacement.inlines;
        }
        return actions;
    }
    getInlineActionsOld(workflowItem) {
        if (!workflowItem) return null;
        const actions = { globals: [], inlines: [] };
        if (typeof workflowItem.inlineActionPlacement === 'object') {
            actions.globals = workflowItem.inlineActionPlacement.globals;
            actions.inlines = workflowItem.inlineActionPlacement.inlines;
        }
        return actions;
    }
    onSubmenuClicked(submenuActiveIndex) {
        this.setState({ submenuActiveIndex }, () => {
            if (this.props.workflow && this.props.workflow.workflowCode) global[`submenu${this.props.workflow.workflowCode}`] = submenuActiveIndex;
        });
    }

    getUIActions = (workflowItem) => {
        const uiActions = workflowItem.layoutProperties.uiActions;
        return uiActions;
    }

    saveAsPDF = () => {
        const { workflow: { currentItem } } = this.props;
        const divElement = this[currentItem.id];
        html2canvas(divElement, {
            logging: false,
            useCORS: true
        })
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/png');
                var imgWidth = 210;
                var pageHeight = 295;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                var doc = new jsPDF('p', 'mm', 'a4', true);
                var position = 0;
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                    heightLeft -= pageHeight;
                }
                doc.save(currentItem.description);
            });
    }

    saveAsOnePagePDF = () => {
        const { workflow: { currentItem }, companyDetails } = this.props;
        const menuType = companyDetails && companyDetails.data && companyDetails.data.menuType;
        const divElement = this[currentItem.id];
        html2canvas(divElement, {
            logging: false,
            useCORS: true
        })
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/png');
                var imgWidth = 100;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                const extraWidth = menuType && menuType == 'SIDEBAR' ? 50 : 0;
                var doc = new jsPDF('p', 'mm', [100, imgHeight+extraWidth]);
                var position = 0;
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                doc.save(currentItem.description);
            });
    }

    componentWillUnmount() {
        window.checkingStatus = false;
    }

    toggleContainer(index) {
        if (this.state.activeContainer === index) {
            this.setState({ activeContainer: -1 });
        } else {
            this.setState({ activeContainer: index });
        }
    }

    render() {
        const { workflow, isLoading, displayHeader, workflow: { currentItem, runningActionsAsync, backgroundProcessStatus }, size, breadcrumbs, workflow: { currentItem: { displayMode, name, operation, layout: { containers }, layoutProperties: { bgImage, mainContainerWidth, hideProcessingActions, showCustomText, customTextForProcessing, workflowSubmenuConfig, pageHeaderConfig, customIcons } } } } = this.props;
        const { bgProcessStatus } = this.state;
        const OperationLayout = this.componentBody[operation];
        const OperationHeader = this.componentHeader[operation];
        const headerActions = this.getInlineActions(currentItem);
        const uiActions = this.getUIActions(currentItem);
        const hasBreadcrumbs = breadcrumbs.length > 1;
        let unusedContainers = [];
        const hasSubmenu = workflowSubmenuConfig && workflowSubmenuConfig.isActive && workflowSubmenuConfig.items.length;
        const hasBottomNextButton = workflowSubmenuConfig && workflowSubmenuConfig.showBottomNextButton && workflowSubmenuConfig.items && workflowSubmenuConfig.items.length;
        if (hasSubmenu) {
            let listOfContainersUsed = [];
            workflowSubmenuConfig.items.map((i, j) => {
                listOfContainersUsed = listOfContainersUsed.concat(i.containerIndexes)
            })

            //listOfContainersUsed = listOfContainersUsed.filter((item, pos) => listOfContainersUsed.indexOf(item) === pos);
            containers.map((con, index) => {
                if (listOfContainersUsed.indexOf(index) === -1) {
                    unusedContainers.push(con);
                }
            })
        }
        const { viewMode } = this.state;
        let mainContainerStyles = {};
        let hasBg = false;

        if (bgImage && bgImage.path) {
            mainContainerStyles.backgroundImage = `url("${config.assetsBasePath}${bgImage.path}")`;
            hasBg = true
        }

        const hideHeader = pageHeaderConfig && pageHeaderConfig.hidePageTitle;
        const self = this;
        const clsName = `workflow-item h-100 wi_${currentItem.id} wi_${currentItem.id} ${hasSubmenu ? 'has-workflow-submenu' : ''} uniqueCode_${currentItem.uniqueCode} ${hasBreadcrumbs ? 'has-breadcrumbs' : ''} `;
        const processStatus = bgProcessStatus ? bgProcessStatus : backgroundProcessStatus;
        if (runningActionsAsync && processStatus && processStatus.percentCompleted < 0.99) {
            if (!window.checkingStatus) {
                window.checkingStatus = true;
                client().get(`${config.playerApi}/sd/${workflow.sessionId}`).then((res) => {
                    if (window.checkingStatus && res && res.data && res.data.data && res.data.data.backgroundProcessStatus) {
                        if (res.data.data.backgroundProcessStatus.percentCompleted < 0.99) {
                            window.checkingStatus = false;
                            this.setState({ bgProcessStatus: res.data.data.backgroundProcessStatus });
                        } else
                            this.props.next();
                    }
                }).catch((ex) => {
                    if(window.checkStatusErrorCount == null) window.checkStatusErrorCount = -1;
                    window.checkStatusErrorCount = window.checkStatusErrorCount + 1;
                    if(window.checkStatusErrorCount < 5) {
                        //Max 5 retries... 
                        window.checkingStatus = false;
                        this.setState({bgProcessStatus: processStatus});
                    }
                });
            }
            return (
                <div className={clsName} style={{ width: '100%', top: '200px', position: 'relative', textAlign: 'center', margin: 'auto' }}>
                    <Progress striped animated style={{ width: '50%', marginLeft: '25%', marginBottom: '10px' }} value={processStatus.percentCompleted * 100}>
                        Waiting for tasks to finish...
                    </Progress>
                    {showCustomText && <div className='row progressBarCustomText' style={{ width: '50%', marginLeft: '25%' }} ><span className='col col-md-10'>{customTextForProcessing}</span><span className={`col col-md-2 fa ${false ? 'fa-check' : 'fa-spinner fa-spin'}`}></span></div>
                    }
                    {
                        !hideProcessingActions && processStatus.steps.map(x => {
                            return <div className='row progressBarAction' style={{ width: '50%', marginLeft: '25%' }} key={x.stepTitle}><span className='col col-md-8'>{x.stepTitle}</span><span className={`col col-md-2 fa ${x.complete ? 'fa-check' : 'fa-spinner fa-spin'}`}></span><span className="col col-md-2"> {x.timeTakenInMs ? (x.timeTakenInMs + " ms") : ""}</span></div>
                        })
                    }
                </div>
            )
        }
        window.checkingStatus = false;
        window.checkStatusErrorCount = -1;
        return (
            <div ref={ref => this[currentItem.id] = ref} className={clsName}>
                {
                    hasSubmenu && unusedContainers.length > 0 && (
                        <Containers
                            size={size}
                            viewMode={viewMode}
                            containers={unusedContainers}
                            OperationHeader={OperationHeader}
                            OperationLayout={OperationLayout}
                            getInlineActions={this.getInlineActions}
                            mainContainerWidth={mainContainerWidth}
                            hasBottomNextButton={hasBottomNextButton}
                            mainContainerStyles={mainContainerStyles}
                            hasBg={hasBg}
                            submenuActiveIndex={this.state.submenuActiveIndex}
                            onSubmenuClicked={this.onSubmenuClicked.bind(this)}
                            showHeader={displayHeader && !hideHeader}
                            changeViewParent={this.changeViewParent}
                            currentViews={this.state.currentViews}
                            saveAsPDF={this.saveAsPDF.bind(this)}
                            saveAsOnePagePDF={this.saveAsOnePagePDF.bind(this)}
                            activeContainer={this.state.activeContainer}
                            toggleContainer={this.toggleContainer.bind(this)}
                            hasUnusedContainers={hasSubmenu && unusedContainers.length}
                            uiActions={uiActions}
                            stepsVisited={this.state.stepsVisited}
                            {...this.props}
                        />
                    )
                }
                {
                    hasSubmenu ? <WorkflowSubMenu
                        onSubmenuClicked={this.onSubmenuClicked.bind(this)}
                        mainContainerWidth={null}
                        customIcons={customIcons}
                        submenuActiveIndex={this.state.submenuActiveIndex}
                        hasUnusedContainers={hasSubmenu && unusedContainers.length}
                        workflowSubmenuConfig={workflowSubmenuConfig}
                        {...this.props}
                    /> : ''
                }

                <Containers
                    size={size}
                    viewMode={viewMode}
                    containers={containers}
                    OperationHeader={OperationHeader}
                    OperationLayout={OperationLayout}
                    getInlineActions={this.getInlineActions}
                    mainContainerWidth={mainContainerWidth}
                    mainContainerStyles={mainContainerStyles}
                    hasBg={hasBg}
                    hasSubmenu={hasSubmenu}
                    hasBottomNextButton={hasBottomNextButton}
                    submenuActiveIndex={this.state.submenuActiveIndex}
                    onSubmenuClicked={this.onSubmenuClicked.bind(this)}
                    showHeader={displayHeader && !hideHeader && !(hasSubmenu && unusedContainers.length)}
                    changeViewParent={this.changeViewParent}
                    currentViews={this.state.currentViews}
                    saveAsPDF={this.saveAsPDF.bind(this)}
                    saveAsOnePagePDF={this.saveAsOnePagePDF.bind(this)}
                    activeContainer={this.state.activeContainer}
                    toggleContainer={this.toggleContainer.bind(this)}
                    hasUnusedContainersOnTop={hasSubmenu && unusedContainers.length}
                    uiActions={uiActions}
                    setUIFilter={this.props.setUIFilter}
                    uiFilters={this.props.uiFilters}
                    getCitiesData={this.props.getCitiesData}
                    getStatesData={this.props.getStatesData}
                    stepsVisited={this.state.stepsVisited}
                    {...this.props}
                />
                <ActionContainers.AssignValue {...this.props} />
            </div>
        );
    }
}

const Containers = (props) => {
    let { containers, mainContainerWidth, mainContainerStyles, hasBg, noPadding, hasSubmenu, OperationHeader, submenuActiveIndex, onSubmenuClicked, hasUnusedContainers, hasUnusedContainersOnTop, activeContainer, hasBottomNextButton, ...rest } = props;
    const { workflow: { stepperType, itemDetails, currentStep, backNotAllowed ,stepperLogo}, size, stepsVisited } = props;
    const { layoutProperties: { pageHeaderConfig, videoBackground ,sideImageURL,leftWidth,rightWidth}, allowDownloadAsPdf, uniqueCode } = props.workflow.currentItem;
    mainContainerWidth = Number(mainContainerWidth);
    const shouldCenterAndApplyWidth = typeof mainContainerWidth === 'number' && mainContainerWidth < 100 && props.workflow.displayMode === 'DEFAULT';
    const centerStyle = {
        width: `${window && window.innerWidth <= 920 ? 100 : mainContainerWidth}%`,
        margin: '0 auto'
    };
    const workflowSubMenuItems = hasSubmenu ? props.workflow.currentItem.layoutProperties.workflowSubmenuConfig.items : [];
    let containerCounter = 0;
    let steps = [];
    const stepperIconSize = size && size.width >= 600 ? 40 : 32;
    if (stepperType === 'HORIZONTAL' || stepperType === 'PROGRESSBARSTEPPER' || stepperType === 'STEPPERTEMPLATE2' ) {
        itemDetails && itemDetails.map((item, itemIndex) => {
            if (!item || !item.name || item.name.length == 0) return;
            currentStep > itemIndex ? steps.push({
                title: size.width >= 600 ? item.name : '',
                icon: <i className='fa fa-check' area-hidden="true" style={{
                    fontSize: size.width >= 600 ? '18px' : '12px'
                }} />,
                onClick: () => {
                    props.jumptoSubmit({ jumpto: item.uniqueCode });
                }
            }) : item.icon ? steps.push({
                title: size.width >= 600 ? item.name : '',
                icon: <i className={`fa ${item.icon}`} area-hidden="true" style={{
                    fontSize: size.width >= 600 ? '18px' : '12px'
                }} />
            }) : steps.push({
                title: size.width >= 600 ? item.name : '',
            });
        })
    }
    var additionalClassName = '';
    const containerRowAdditionalClassName = pageHeaderConfig && pageHeaderConfig.addBorder ? 'hasborder' : '';

    if (pageHeaderConfig && pageHeaderConfig.hidePageTitle) {
        additionalClassName = pageHeaderConfig.startFromTop ? 'mt-0' : 'mt-5';
    }
    if (hasUnusedContainers) additionalClassName += ' has-unused-containers';
    if (hasUnusedContainersOnTop) additionalClassName += ' has-unused-containers-above';
    let disabledSteps = [];
    let i = 0;
    while(i < steps.length){
        const stepFound = stepsVisited.find(s => s == i)
        if(typeof stepFound == 'undefined' && i <= stepsVisited[stepsVisited.length-1]){
            disabledSteps.push(i)
        }
        i++;
    }
    if(sideImageURL){
        return <Row className={'workflowItemWithSideImageMainDiv'}>
             <Col  sm={leftWidth} style={mainContainerStyles} className={`containers-main-wrapper content-div ${videoBackground ? 'container-having-video' : ''} ${additionalClassName} ${hasBg ? 'has-background' : ''} ${props.workflow.currentItem.operation} ${noPadding ? 'm-0 p-0 no-padding' : ''}`}>
        <WorkflowMessages {...rest} />
        <div className='h-100' style={shouldCenterAndApplyWidth ? centerStyle : null}>
            {videoBackground && <video autoPlay loop muted className="bg-vid" src={videoBackground}> </video>}
            {
                stepperType === 'HORIZONTAL' && <div className={`stepper-container ${hasBg ? 'hasBackground' : ''}`}>
                    <Stepper
                        size={stepperIconSize}
                        steps={steps}
                        activeStep={currentStep}
                        disabledSteps={disabledSteps}
                        activeColor="#17a2b8"
                        activeTitleColor="#17a2b8"
                        completeColor="#28a745"
                        completeTitleColor="#28a745"
                        defaultColor="#adb5bd"
                        defaultTitleColor="#adb5bd"
                        defaultBarColor="#adb5bd"
                        completeBarColor="#28a745"
                        circleFontSize={size.width >= 600 ? 18 : 12}
                        backNotAllowed={backNotAllowed}
                    />
                </div>
            }
            {(props.showHeader) ? <OperationHeader
                workflow={props.workflow}
                getInlineActions={props.getInlineActions}
                showOnlyActions={true}
                showOnlySwitch={false}
                stepperType={stepperType}
                stepperLogo={stepperLogo}
                size={stepperIconSize}
                steps={steps}
                activeStep={currentStep}
                showTitle={true}
                readAllData={props.readAllData}
                setUIFilter={props.setUIFilter}
                uiFilters={props.uiFilters}
                getCitiesData={props.getCitiesData}
                getStatesData={props.getStatesData}
                saveAsPDF={props.saveAsPDF}
                saveAsOnePagePDF={props.saveAsOnePagePDF}
            /> : ''
            }

            {
                hasSubmenu ? <TabContent className='h-100' activeTab={submenuActiveIndex}>
                    {
                        workflowSubMenuItems.map((workflowSubMenuItem, index) => {
                            return <TabPane key={index} tabId={index}>
                                {
                                    workflowSubMenuItem.containerIndexes.map((containerIndex, _cindex) => {
                                        containerCounter++;
                                        const container = containers[containerIndex];
                                        let targetContainer = Number(container.displayText.split(' ')[1]);
                                        targetContainer = targetContainer - 1;
                                        const containerProperties = container ? container.properties : null;
                                        let displayMode = container.properties.displayMode && container.properties.displayMode.key ?
                                            container.properties.displayMode.key : 'DEFAULT';
                                        if (displayMode === 'COLLAPSE') {
                                            const showModal = containerIndex === activeContainer;
                                            const actions = props.getInlineActions ? props.getInlineActions(props.workflow.currentItem, index) : null;
                                            return <div key={containerIndex}>
                                                <Row className='mb-2 p-3 container-collapse-row justify-content-between'>
                                                    <div>
                                                        <Button onClick={() => props.toggleContainer(containerIndex)} color='primary' outline size="sm">
                                                            <i className={`fa fa-${!showModal ? 'angle-double-down' : 'angle-double-up'}`} aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {containerProperties.title}
                                                    </div>
                                                    <div className="text-right">
                                                        {actions.globals.length > 0 && <RenderActions workflow={props.workflow} actions={actions.globals} />}
                                                    </div>
                                                </Row>
                                                <Collapse isOpen={showModal}>
                                                    <Jumbotron>
                                                        <BuildContainer targetContainerIndex={targetContainer} isCollapsible={true} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${containerCounter} ${containerRowAdditionalClassName}`} index={containerIndex} container={containers[containerIndex]} key={_cindex} layoutItemCode={uniqueCode} {...rest} />
                                                    </Jumbotron>
                                                </Collapse>
                                            </div>
                                        } else
                                            return <div key={_cindex}><BuildContainer targetContainerIndex={targetContainer} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${containerCounter} ${containerRowAdditionalClassName}`} index={containerIndex} container={containers[containerIndex]} key={_cindex} layoutItemCode={uniqueCode} {...rest} />
                                                {lastIndexOf(workflowSubMenuItem.containerIndexes) - 1 == _cindex && hasSubmenu && hasBottomNextButton ? (
                                                    <WorkflowSubMenuEndButton
                                                        submenuActiveIndex={submenuActiveIndex}
                                                        hasSubmenu={hasSubmenu}
                                                        onSubmenuClicked={onSubmenuClicked}
                                                        {...rest}
                                                    />
                                                ) : null}
                                            </div>

                                    })
                                }
                            </TabPane>
                        })
                    }
                </TabContent> : <div className='h-100'>

                    {
                        containers.map((container, index) => {
                            const containerProperties = container ? container.properties : null;
                            const hideContainerWeb = containerProperties ? containerProperties.hideContainerWeb : null;
                            const hideContainerMobileWebView = containerProperties ? containerProperties.hideContainerMobileWebView : null;
                            const hideContainerDesktopWebView = containerProperties ? containerProperties.hideContainerDesktopWebView : null;
                            if (hideContainerWeb) return null;
                            if (hideContainerMobileWebView && window.innerWidth < 480) return null;
                            if (hideContainerDesktopWebView && window.innerWidth >= 480) return null;
                            const defaultContainer = (options) => {
                                if (container.properties.background) {
                                    return <div key={index} style={{ backgroundImage: `url("${container.properties.background.startsWith('http') ? container.properties.background : (config.assetsBasePath + container.properties.background)}")` }} className={`containers-main-wrapper has-background ${props.workflow.currentItem.operation} ${noPadding ? 'm-0 p-0 no-padding' : ''}`}>
                                        <BuildContainer isCollapsible={options && options.collapsible} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${index + 1} ${containerRowAdditionalClassName}`} index={index} container={container} layoutItemCode={uniqueCode} {...rest} />
                                    </div>
                                }
                                return <BuildContainer isCollapsible={options && options.collapsible} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${index + 1} ${containerRowAdditionalClassName}`} index={index} container={container} key={index} layoutItemCode={uniqueCode} {...rest} />
                            };
                            const showModal = index === activeContainer;
                            const saveButtonText = container.properties && container.properties.saveButtonLTC
                            const agreeDisagreeLTC = container.properties && container.properties.agreeDisagreeLTC
                            const agreeBitField = container.properties && container.properties.agreeBitField
                            const disagreeBitField = container.properties && container.properties.disagreeBitField
                            let displayMode = container.properties.displayMode && container.properties.displayMode.key ?
                                container.properties.displayMode.key : 'DEFAULT';
                            if (displayMode === 'MODAL') {
                                return <Modal size='lg' isOpen={showModal} toggle={() => props.toggleContainer(index)} className='container-modal-wrapper'>
                                    <ModalBody>
                                        {defaultContainer()}
                                    </ModalBody>
                                    <ModalFooter>
                                        {!agreeDisagreeLTC ? <Button onClick={() => props.toggleContainer(index)}>{saveButtonText ? saveButtonText : 'Save'}</Button> :
                                            <div> <Button style={{ marginRight: 20 }} color='danger' onClick={() => {
                                                props.toggleContainer(index)
                                                props.change(agreeBitField, false)
                                            }}>Disagree</Button>
                                                <Button onClick={() => {
                                                    props.toggleContainer(index)
                                                    props.change(disagreeBitField, true)
                                                }}>Agree</Button>
                                            </div>
                                        }
                                    </ModalFooter>
                                </Modal>
                            } else if (displayMode === 'COLLAPSE') {
                                const actions = props.getInlineActions ? props.getInlineActions(props.workflow.currentItem, index) : null;
                                return <div key={index}>
                                    <Row className='mb-2 p-3 container-collapse-row justify-content-between'>
                                        <div>
                                            <Button onClick={() => props.toggleContainer(index)} color='primary' outline size="sm">
                                                <i className={`fa fa-${!showModal ? 'angle-double-down' : 'angle-double-up'}`} aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                        <div>
                                            {containerProperties.title}
                                        </div>
                                        <div className="text-right">
                                            {actions.globals.length > 0 && <RenderActions workflow={props.workflow} actions={actions.globals} />}
                                        </div>
                                    </Row>
                                    <Collapse isOpen={showModal}>
                                        <Jumbotron>
                                            {defaultContainer({ collapsible: true })}
                                        </Jumbotron>
                                    </Collapse>
                                </div>
                            }
                            return defaultContainer();
                        })
                    }
                </div>
            }

        </div>
       
      </Col>
      <Col sm={rightWidth} className={'workflowItemWithSideimageDiv'}>
           <img src={sideImageURL} alt="SideImage" className='imageRIghtWI'  />
        </Col>

        </Row>
    }
    return <div style={mainContainerStyles} className={`containers-main-wrapper ${videoBackground ? 'container-having-video' : ''} ${additionalClassName} ${hasBg ? 'has-background' : ''} ${props.workflow.currentItem.operation} ${noPadding ? 'm-0 p-0 no-padding' : ''}`}>
        <WorkflowMessages {...rest} />
        <div className='h-100' style={shouldCenterAndApplyWidth ? centerStyle : null}>
            {videoBackground && <video autoPlay loop muted className="bg-vid" src={videoBackground}> </video>}
            {
                stepperType === 'HORIZONTAL' && <div className={`stepper-container ${hasBg ? 'hasBackground' : ''}`}>
                    <Stepper
                        size={stepperIconSize}
                        steps={steps}
                        activeStep={currentStep}
                        disabledSteps={disabledSteps}
                        activeColor="#17a2b8"
                        activeTitleColor="#17a2b8"
                        completeColor="#28a745"
                        completeTitleColor="#28a745"
                        defaultColor="#adb5bd"
                        defaultTitleColor="#adb5bd"
                        defaultBarColor="#adb5bd"
                        completeBarColor="#28a745"
                        circleFontSize={size.width >= 600 ? 18 : 12}
                        backNotAllowed={backNotAllowed}
                    />
                </div>
            }
            {(props.showHeader) ? <OperationHeader
                workflow={props.workflow}
                getInlineActions={props.getInlineActions}
                showOnlyActions={true}
                showOnlySwitch={false}
                stepperType={stepperType}
                stepperLogo={stepperLogo}
                size={stepperIconSize}
                steps={steps}
                activeStep={currentStep}
                showTitle={true}
                readAllData={props.readAllData}
                setUIFilter={props.setUIFilter}
                uiFilters={props.uiFilters}
                getCitiesData={props.getCitiesData}
                getStatesData={props.getStatesData}
                saveAsPDF={props.saveAsPDF}
                saveAsOnePagePDF={props.saveAsOnePagePDF}
            /> : ''
            }

{
                stepperType === 'STEPPERTEMPLATE2' && <div className={`stepper-container ${hasBg ? 'hasBackground' : ''}`}>
                    <Stepper
                        size={stepperIconSize}
                        steps={steps}
                        activeStep={currentStep}
                        disabledSteps={disabledSteps}
                        stepperType={stepperType}
                        stepperLogo={stepperLogo}
                        activeColor="#17a2b8"
                        activeTitleColor="#17a2b8"
                        completeColor="#28a745"
                        completeTitleColor="#28a745"
                        defaultColor="#adb5bd"
                        defaultTitleColor="#adb5bd"
                        defaultBarColor="#adb5bd"
                        completeBarColor="#28a745"
                        circleFontSize={size.width >= 600 ? 18 : 12}
                        backNotAllowed={backNotAllowed}
                    />
                </div>
            }

            {
                hasSubmenu ? <TabContent className='h-100' activeTab={submenuActiveIndex}>
                    {
                        workflowSubMenuItems.map((workflowSubMenuItem, index) => {
                            return <TabPane key={index} tabId={index}>
                                {
                                    workflowSubMenuItem.containerIndexes.map((containerIndex, _cindex) => {
                                        containerCounter++;
                                        const container = containers[containerIndex];
                                        let targetContainer = Number(container.displayText.split(' ')[1]);
                                        targetContainer = targetContainer - 1;
                                        const containerProperties = container ? container.properties : null;
                                        let displayMode = container.properties.displayMode && container.properties.displayMode.key ?
                                            container.properties.displayMode.key : 'DEFAULT';
                                        if (displayMode === 'COLLAPSE') {
                                            const showModal = containerIndex === activeContainer;
                                            const actions = props.getInlineActions ? props.getInlineActions(props.workflow.currentItem, index) : null;
                                            return <div key={containerIndex}>
                                                <Row className='mb-2 p-3 container-collapse-row justify-content-between'>
                                                    <div>
                                                        <Button onClick={() => props.toggleContainer(containerIndex)} color='primary' outline size="sm">
                                                            <i className={`fa fa-${!showModal ? 'angle-double-down' : 'angle-double-up'}`} aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {containerProperties.title}
                                                    </div>
                                                    <div className="text-right">
                                                        {actions.globals.length > 0 && <RenderActions workflow={props.workflow} actions={actions.globals} />}
                                                    </div>
                                                </Row>
                                                <Collapse isOpen={showModal}>
                                                    <Jumbotron>
                                                        <BuildContainer targetContainerIndex={targetContainer} isCollapsible={true} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${containerCounter} ${containerRowAdditionalClassName}`} index={containerIndex} container={containers[containerIndex]} key={_cindex} layoutItemCode={uniqueCode} {...rest} />
                                                    </Jumbotron>
                                                </Collapse>
                                            </div>
                                        } else
                                            return <div key={_cindex}><BuildContainer targetContainerIndex={targetContainer} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${containerCounter} ${containerRowAdditionalClassName}`} index={containerIndex} container={containers[containerIndex]} key={_cindex} layoutItemCode={uniqueCode} {...rest} />
                                                {lastIndexOf(workflowSubMenuItem.containerIndexes) - 1 == _cindex && hasSubmenu && hasBottomNextButton ? (
                                                    <WorkflowSubMenuEndButton
                                                        submenuActiveIndex={submenuActiveIndex}
                                                        hasSubmenu={hasSubmenu}
                                                        onSubmenuClicked={onSubmenuClicked}
                                                        {...rest}
                                                    />
                                                ) : null}
                                            </div>

                                    })
                                }
                            </TabPane>
                        })
                    }
                </TabContent> : <div className='h-100'>

                    {
                        containers.map((container, index) => {
                            const containerProperties = container ? container.properties : null;
                            const hideContainerWeb = containerProperties ? containerProperties.hideContainerWeb : null;
                            const hideContainerMobileWebView = containerProperties ? containerProperties.hideContainerMobileWebView : null;
                            const hideContainerDesktopWebView = containerProperties ? containerProperties.hideContainerDesktopWebView : null;
                            if (hideContainerWeb) return null;
                            if (hideContainerMobileWebView && window.innerWidth < 480) return null;
                            if (hideContainerDesktopWebView && window.innerWidth >= 480) return null;
                            const defaultContainer = (options) => {
                                if (container.properties.background) {
                                    return <div key={index} style={{ backgroundImage: `url("${container.properties.background.startsWith('http') ? container.properties.background : (config.assetsBasePath + container.properties.background)}")` }} className={`containers-main-wrapper has-background ${props.workflow.currentItem.operation} ${noPadding ? 'm-0 p-0 no-padding' : ''}`}>
                                        <BuildContainer isCollapsible={options && options.collapsible} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${index + 1} ${containerRowAdditionalClassName}`} index={index} container={container} layoutItemCode={uniqueCode} {...rest} />
                                    </div>
                                }
                                return <BuildContainer isCollapsible={options && options.collapsible} OperationHeader={OperationHeader} rowClassName={`container-main-row unique-container-${index + 1} ${containerRowAdditionalClassName}`} index={index} container={container} key={index} layoutItemCode={uniqueCode} {...rest} />
                            };
                            const showModal = index === activeContainer;
                            const saveButtonText = container.properties && container.properties.saveButtonLTC
                            const agreeDisagreeLTC = container.properties && container.properties.agreeDisagreeLTC
                            const agreeBitField = container.properties && container.properties.agreeBitField
                            const disagreeBitField = container.properties && container.properties.disagreeBitField
                            let displayMode = container.properties.displayMode && container.properties.displayMode.key ?
                                container.properties.displayMode.key : 'DEFAULT';
                            if (displayMode === 'MODAL') {
                                return <Modal size='lg' isOpen={showModal} toggle={() => props.toggleContainer(index)} className='container-modal-wrapper'>
                                    <ModalBody>
                                        {defaultContainer()}
                                    </ModalBody>
                                    <ModalFooter>
                                        {!agreeDisagreeLTC ? <Button onClick={() => props.toggleContainer(index)}>{saveButtonText ? saveButtonText : 'Save'}</Button> :
                                            <div> <Button style={{ marginRight: 20 }} color='danger' onClick={() => {
                                                props.toggleContainer(index)
                                                props.change(agreeBitField, false)
                                            }}>Disagree</Button>
                                                <Button onClick={() => {
                                                    props.toggleContainer(index)
                                                    props.change(disagreeBitField, true)
                                                }}>Agree</Button>
                                            </div>
                                        }
                                    </ModalFooter>
                                </Modal>
                            } else if (displayMode === 'COLLAPSE') {
                                const actions = props.getInlineActions ? props.getInlineActions(props.workflow.currentItem, index) : null;
                                return <div key={index}>
                                    <Row className='mb-2 p-3 container-collapse-row justify-content-between'>
                                        <div>
                                            <Button onClick={() => props.toggleContainer(index)} color='primary' outline size="sm">
                                                <i className={`fa fa-${!showModal ? 'angle-double-down' : 'angle-double-up'}`} aria-hidden="true"></i>
                                            </Button>
                                        </div>
                                        <div>
                                            {containerProperties.title}
                                        </div>
                                        <div className="text-right">
                                            {actions.globals.length > 0 && <RenderActions workflow={props.workflow} actions={actions.globals} />}
                                        </div>
                                    </Row>
                                    <Collapse isOpen={showModal}>
                                        <Jumbotron>
                                            {defaultContainer({ collapsible: true })}
                                        </Jumbotron>
                                    </Collapse>
                                </div>
                            }
                            return defaultContainer();
                        })
                    }
                </div>
            }

        </div>

    </div>
}

class BuildContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: null,
            hide: true,
            globalDataKey: null,
            showButton: false,
        }
        this.changeView = this.changeView.bind(this);
        DataStore.registerHandler(this.globalDataHandler.bind(this));
    }
    changeView(viewMode) {
        //this.setState({ viewMode });
        if (this.props.changeViewParent && viewMode !== this.props.currentViews[this.props.index]) {
            this.props.changeViewParent(this.props.index, viewMode);
        }
    }

    componentDidMount() {
        this.handleProps(this.props);
        this.setState({ hide: false });
    }

    handleProps(nextProps) {
        const { index, workflow: { currentItem: { layoutProperties: { readAllViews } } } } = nextProps || this.props;
        const { table, card, keyed, accordian, workflowCard, imageCard } = readAllViews[index] || readAllViews;
        //console.log(imageCard,"imageCardimageCard")
        if (window.innerWidth < 480 && workflowCard && workflowCard.isConfigured) {
            this.changeView('WORKFLOWCARD');
        }
        else if (table && table.isConfigured) {
            this.changeView('TABLE');
        }
        else if (card && card.isConfigured) {
            this.changeView('CARD');
        }
        else if (keyed && keyed.isConfigured) {
            this.changeView('KEYED');
        }
        else if (accordian && accordian.isConfigured) {
            this.changeView('ACCORDIAN');
        }
        else if (workflowCard && workflowCard.isConfigured) {
            this.changeView('WORKFLOWCARD');
        } else if (imageCard && imageCard.isConfigured) {
            this.changeView('IMAGECARD');
        }
    }

    onBtnPress(containerID) {
        localStorage.setItem("container_show_btn_id", `${containerID}`)
        this.setState({ hide: false });
    }

    globalDataHandler(key, data) {
        const globalKey = this.state.globalDataKey;
        if (key === globalKey) {
            this.setState({ hide: false });
        }
    }

    componentWillUnmount() {
        DataStore.deRegisterHandler(this.globalDataHandler);
        localStorage.removeItem('container_show_btn_id')
    }

    componentWillReceiveProps(nextProps) {
        const { workflow: { currentItem, sessionId, sessionParams }, formValues, resolveRules, getCustomIcons } = nextProps;
        const containerProp = nextProps.container.properties;

        if (this.props.workflow.currentItem.id !== currentItem.id) {
            this.handleProps(nextProps, nextProps.currentViews[nextProps.index]);
        }

        if (containerProp && containerProp.displayConfig) {
            if (containerProp.displayConfig.displayWhen.value === 'basedOnRule') {
                if (
                    containerProp.connector &&
                    containerProp.connector.rules &&
                    containerProp.connector.rules.length
                ) {

                    const rules = containerProp.connector.rules;
                    let cacheId = sessionId;
                    rules.forEach((x, i) => {
                        if (x.key) {
                            cacheId += "_" + x.key.typeModelName;
                            cacheId += "_" + x.key.property;
                            cacheId += "_" + x.key.staticValue;
                        }
                        cacheId += "_" + x.operator;
                        if (x.value) {
                            cacheId += "_" + x.value.staticValue;
                            cacheId += "_" + x.value.typeModelName;
                            cacheId += "_" + x.value.property;
                        }
                    });
                    const self = this;
                    let chkParamRule = false
                    rules.forEach((x, i) => {
                        if (x && x.sessionParamCode == "HasRequestParam") {
                            let staticValue = x.value.staticValue
                            chkParamRule = true
                            staticValue = staticValue.split('=')
                            const sessionParamKey = Object.keys(sessionParams)
                            sessionParamKey.forEach((key) => {
                                if (key == staticValue[0] && sessionParams[key] == staticValue[1]) {
                                    self.setState({ hide: false });

                                }
                            })
                        }
                    })
                    if (!window.rulesCache) window.rulesCache = [];

                    if (window.rulesCache[cacheId]) {
                        self.setState({ hide: !window.rulesCache[cacheId].data });
                    } else {
                        if (!chkParamRule) {
                            resolveRules({
                                rules: containerProp.connector.rules,
                                sessionId,
                            }).then(rules => {
                                if (rules.data) {
                                    window.rulesCache[cacheId] = rules.data;
                                    self.setState({ hide: !rules.data.data });
                                }
                            });
                        }
                    }
                } else {
                    this.setState({ hide: true });
                }
            } else if (
                containerProp.displayConfig.displayWhen.value === 'onGlobalData'
            ) {
                const globalKey = containerProp.displayConfig.updatedData;
                if (DataStore.get(globalKey))
                    this.setState({ globalDataKey: globalKey, hide: false });
                else
                    this.setState({ globalDataKey: globalKey, hide: true });
            } else if (
                containerProp.displayConfig.displayWhen.value === 'onButtonPress'
            ) {
                this.setState({ showButton: true, hide: true });
            } else if (
                containerProp.displayConfig.displayWhen.value === 'onDataChange'
            ) {
                const field = containerProp.displayConfig.dataField;
                const fieldValue = formValues && ModelPropertiesParser(`{{${field}}}`, formValues);
                let values = containerProp.displayConfig.updatedData;
                values = (values && values.indexOf(',') != -1) ? values.split(",").map((item) => item.trim()) : values;
                if (typeof values == 'string') {
                    if (values !== fieldValue) {
                        this.setState({ hide: true });
                    } else {
                        this.setState({ hide: false });
                    }
                } else {
                    const found = values && values.find(element => element == fieldValue);
                    if (found) {
                        this.setState({ hide: false });
                    } else {
                        this.setState({ hide: true });
                    }
                }

            } else if (
                containerProp.displayConfig.displayWhen.value === 'always'
            ) {
                this.setState({ hide: false });
            }
        } else {
            this.setState({ hide: false });
        }
    }

    render() {
        try {
            const { container, showHeader, currentViews, viewMode, OperationHeader, isCollapsible, index, customStyles, ...rest } = this.props;
            const containerID = container && container.id;
            const containerIDlocal = localStorage.getItem('container_show_btn_id');
            const containerIDlocalInt = parseInt(containerIDlocal);
            let showContainerbtnPressed = false;
            if (containerIDlocalInt === containerID) {
                showContainerbtnPressed = true
            }
            if (!container) {
                return null;
            }
            let { containerType } = container;
            if (containerType === 'PANEL' || containerType === null) containerType = 'ONECOL';
            const ContainerTemplate = ContainerTemplates[containerType];
            const displayConfig = container.properties.displayConfig;
            let contStyle = {};
            if (customStyles.data && customStyles.data.data) {
                const styleData = JSON.parse(customStyles.data.data.style)
                contStyle = styleData[`${this.props.layoutItemCode}_${index}`]
            }
            return <div>
                {this.state.showButton && (
                    <Button
                        onClick={this.onBtnPress.bind(this, containerID)}
                        color={displayConfig.buttonStyle}
                        className='mb-1'
                    >
                        <i
                            className={`fa ${displayConfig.cssClass} mr-1`}
                            aria-hidden='true'
                        ></i>
                        {displayConfig.buttonName}
                    </Button>
                )}
                {(!this.state.hide || showContainerbtnPressed) && (<div>
                    {showHeader && !isCollapsible ? <OperationHeader
                        changeView={this.changeView}
                        container={container}
                        containerIndex={index}
                        viewMode={currentViews[index]}
                        showOnlySwitch={false}
                        {...rest}
                    /> : ''
                    }
                    <ContainerTemplate customStyles={customStyles} container={container} index={index} viewMode={currentViews[index]} contStyle={contStyle} {...rest} />
                </div>
                )}
            </div>
        }
        catch (ex) {

        }
    }

}

const RenderActions = (props) => {
    const { actions, workflow } = props;
    return <div className='d-flex'>
        {
            actions.map((action, index) => {
                const { actionProperties } = action;
                if (actionProperties && actionProperties.actionDisabled) {
                    return null;
                }
                return <div key={index} className='mr-1'>
                    <ActionContainer {...props} action={action} />
                </div>
            })
        }
    </div>
}

/*
const BuildContainer = (props) => {
    try {
        const { container } = props;
        if (!container) return null;
        let { containerType } = container;
        if (containerType === 'PANEL') containerType = 'ONECOL';
        const ContainerTemplate = ContainerTemplates[containerType];
        return <ContainerTemplate {...props} />
    }
    catch(ex) {
        
    }
    
}*/


WorkflowItem.defaultProps = {
    noPadding: false,
    displayHeader: true
}

WorkflowItem.propTypes = {
    noPadding: PropTypes.bool,
    displayHeader: PropTypes.bool
}

export default sizeMe()(WorkflowItem);
