import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import COLORS from '../../../dto/chart-colors';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

const _PieChart = props => {
  const [filters, onChangeFilters] = useState([]);
  const [data, onChangeData] = useState([]);
  const { reportConfig, label, graphHeight, getReportDataByAction, workflow } =
    props;
  useEffect(() => {
    if (
      reportConfig &&
      Array.isArray(reportConfig.filters) &&
      reportConfig.filters[0]
    ) {
      getReportDataByAction({
        workflowSessionId: workflow.sessionId,
        actionUniqueId: reportConfig.filters[0].actionGuid.value,
        filters,
        actionName: reportConfig.filters[0].actionGuid.label,
      })
        .then(res => {
          try {
            const responseDataArray = res.data.data;
            const updatedData = Object.assign([], data);
            for (let index = 0; index < responseDataArray.length; index++) {
              const responseData = res.data.data[index];
              const label = ModelPropertiesParser(
                reportConfig.columns[0].value,
                responseData
              );
              const value = ModelPropertiesParser(
                reportConfig.columns[1].value,
                responseData
              );
              const entry = {
                label: label,
                value: parseInt(value),
              };
              updatedData.push(entry);
            }
            /*            const responseData = res.data.data[0];
            const modelKeys = Object.keys(responseData);
            try {
              modelKeys.map((k, i) => {
                responseData[k.replace(' ', '_')] = JSON.parse(responseData[k]);
              });
            }catch(ee) {
              console.log(ee);
            }
            const updatedData = Object.assign([], data);
            reportConfig.columns &&
              reportConfig.columns.map((col, colIndex) => {
                const value = ModelPropertiesParser(col.value, responseData);
                console.log(col.value);
                const entry = {
                  label: col.label,
                  value: parseInt(value),
                };
                updatedData.push(entry);
              });*/
            onChangeData(updatedData);
          } catch (e) {
            console.log(e, 'JSON PARSE ERROR');
          }
        })
        .catch(ex => {
          onChangeData([]);
        });
    }
  }, [filters]);

  const renderCustomizedLabel = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, percent, value, name, index } =
      props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;

    const isLeftSide = midAngle < 90 && midAngle > -90; // Change 10 to half of the number of items per side
    const ySpacing = 15; // Adjust the vertical spacing between lines
    const xSpacing = -100; // Adjust the horizontal spacing between left and right sides
    const topSpacing = 50;
    const ey = !isLeftSide
      ? topSpacing + (data.length - index) * ySpacing
      : topSpacing + (index + 1) * ySpacing;
    const textAnchor = isLeftSide ? 'start' : 'end';
    const xPosition = cx + (isLeftSide ? -1 : 1) * xSpacing;
    let keyName = name.split(' ');

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${xPosition},${ey}`}
          stroke={fill}
          fill='none'
        />
        <circle cx={xPosition} cy={ey} r={2} fill={fill} stroke='none' />
        <text
          x={textAnchor === 'start' ? xPosition + 10 : xPosition - 10}
          y={ey + 5}
          fontSize={15}
          textAnchor={textAnchor}
          fill={fill}
        >
          {`${keyName}: ${value} `}
        </text>
      </g>
    );
  };

  let colors = COLORS;
  if(reportConfig.valueColors) {
    colors = reportConfig.valueColors.replace('/ /g', "").split(",");
  }
  return (
    <div
      style={{ width: '100%', padding: 10 }}
      className='bg-white mt-3 rounded-lg'
    >
      {label.show && <Label>{label.text}</Label>}

      {data.length ? (
        <ResponsiveContainer height={graphHeight} width={'99%'}>
          <PieChart height={graphHeight}>
            <Tooltip />
            <Pie
              data={data}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              innerRadius={60}
              fill='#8884d8'
              dataKey='value'
              nameKey='label'
              startAngle={90}
              endAngle={-270}
            >
              {data &&
                data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % COLORS.length]}
                  />
                ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 100,
          }}
        >
          <img
            src='https://cn-mumbai-assets.s3.ap-south-1.amazonaws.com/public/nojobsfound-1.png'
            height={100}
            width={200}
          ></img>
        </div>
      )}
    </div>
  );
};

const responseDataDDD = {
  data: [
    {
      'total persons': { name: 'rakes', age: 5 },
    },
  ],
};

export default _PieChart;
