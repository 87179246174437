import FixedDataTable2 from './Table/Tablev1';
import SmallText from './SmallText';
import LargeText from './LargeText';
import TypeModel from './TypeModel';
import Dropdown from './Dropdown';
import Email from './Email';
import RichhTextRenderer from './RichhTextRenderer';
import CardLayout from './CardLayout';
import ImageCardLayout from './ImageCardLayout';
import KeyedLayout from './KeyedLayout';
import _File from './File';
import _Url from './Url';
import ActionButton from './ActionButton';
import MultiChoice from './MultiChoice';
import MultiSelect from './MultiSelect';
import DateTime from './DateTime';
import Slider from './Slider';
import DateRange from './DateRange';
import NumberRange from './NumberRange';
import Time from './Time';
import _Date from './Date';
import Bit from './Bit';
import _Number from './Number';
import PositiveNumber from './PositiveNumber';
import Decimal from './Decimal';
import _Image from './Image';
import _Video from './Video';
import Button from './Button';
import Fallback from './Fallback';
import YesNo from './YesNo';
import UsersList from './UsersList';
import HeroBanner from './HeroBanner';
import Report from './Report';
import Countries from './Countries';
import States from './States';
import Cities from './Cities';
import Phone from './Phone';
import LinkToContainer from './LinkToContainer';
import Percentage from './Percentage';
import Location from './Location';
import CalendarEvent from './CalendarEvent';
import DynamicMultiChoice from './DynamicMultiChoice';
import Timer from './Timer';
import VideoConference from './VideoConference';
import Payment from './Payment';
import Rating from './Rating';
import StaticLink from './StaticLink';
import AccordianLayout from './AccordianLayout';
import WorkflowCardLayout from './WorkflowCardLayout';
import Divider from './Divider';
import Authentication from './Authentication';
import Redirect from './Redirect';
import Logout from './Logout';
import GoogleMap from './GoogleMapElement';
import GlobalSerach from './GlobalSerach';
import Password from './Password';
import ImageSelector from './ImageSelector';
import OCRElement from './OCRElement';
import Refresh from './Refresh';
import ReCaptcha from './ReCaptcha';
import StaticTextCard from './StaticTextCard';
import ExternalReport from './ExternalReport';
import AuthProviders from './AuthProviders';
import Languages from './Languages';
import MediumText from './MediumText';
import GeneratePDF from './GeneratePDF';
import StaticImage from './StaticImage';
import GeoJSONPolygon from './GeoJSONPolygon';
import StaticWebContent from './StaticWebContent';
import UniqueCode from './UniqueCode';
import ForgotPassword from './ForgotPassword';
import SetPassword from './SetPassword';
import LinksElement from './LinksElement';
import CalculatedNumber from './CalculatedNumber';
import FaceRecognition from './FaceRecognition';
import StaticReport from './StaticReport';

export const TABLE = FixedDataTable2;
export const CARDLAYOUT = CardLayout;
export const IMAGECARDLAYOUT = ImageCardLayout;
export const KEYEDLAYOUT = KeyedLayout;
export const SMALLTEXT = SmallText;
export const MEDIUMTEXT = MediumText;
export const LARGETEXT = LargeText;
export const TYPE = TypeModel;
export const EMAIL = Email;
export const RICHHTEXT = RichhTextRenderer;
export const DROPDOWN = Dropdown;
export const FILE = _File;
export const URL = _Url;
export const ACTION = ActionButton;
export const ACTIONBUTTON = ActionButton;
export const MULTICHOICE = MultiChoice;
export const MULTISELECT = MultiSelect;
export const DATETIME = DateTime;
export const SLIDER = Slider;
export const DATE_RANGE = DateRange;
export const TIME = Time;
export const DATE = _Date;
export const NUMBER_RANGE = NumberRange;
export const BIT = Bit;
export const NUMBER = _Number;
export const POSITIVE_NUMBER = PositiveNumber;
export const DECIMAL = Decimal;
export const IMAGE = _Image;
export const VIDEO = _Video;
export const WORKFLOWNEXTBUTTON = Button;
export const FORGOTPASSWORD = ForgotPassword;
export const SETPASSWORD = SetPassword;
export const FALLBACK = Fallback;
export const YESNO = YesNo;
export const USER = UsersList;
export const HEROBANNER = HeroBanner;
export const GLOBALSEARCH = GlobalSerach;
export const REPORT = Report;
export const COUNTRIES = Countries;
export const STATES = States;
export const CITIES = Cities;
export const PHONE = Phone;
export const LINKTOCONTAINER = LinkToContainer;
export const PERCENTAGE = Percentage;
export const LOCATION = Location;
export const CALENDAREVENT = CalendarEvent;
export const DYNAMICMULTICHOICE = DynamicMultiChoice;
export const TIMER = Timer;
export const VIDEOCONFERENCE = VideoConference;
export const PAYMENT = Payment;
export const RATING = Rating;
export const STATICLINK = StaticLink;
export const LINKSELEMENT = LinksElement;
export const ACCORDIANLAYOUT = AccordianLayout;
export const WORKFLOWCARDLAYOUT = WorkflowCardLayout;
export const DIVIDER = Divider;
export const AUTH = Authentication;
export const REDIRECT = Redirect;
export const LOGOUT = Logout;
export const MAPGOOGLE = GoogleMap;
export const PASSWORD = Password;
export const IMAGESELECTER = ImageSelector;
export const OCR = OCRElement;
export const REFRESH = Refresh;
export const RECAPTCHA = ReCaptcha;
export const STATICTEXTCARD = StaticTextCard;
export const EXTERNALREPORT = ExternalReport;
export const AUTHPROVIDERS = AuthProviders;
export const LANGUAGES = Languages;
export const GENERATEPDF =  GeneratePDF;
export const STATICIMAGE = StaticImage;
export const GEOJSONPOLYGON = GeoJSONPolygon;
export const STATICWEBLAYOUT = StaticWebContent;
export const UNIQUE = UniqueCode;
export const CALCULATED_NUMBER = CalculatedNumber;
export const EFR = FaceRecognition;
export const STATICREPORT = StaticReport;