import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderSingleCheckBox } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import {onUIAction} from '../../utils/uiActions';

class Bit extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uiAction: null
        }
    }

    componentDidMount(){
        const { workflow, classProperty, workflowData } = this.props;
        const bitConfig = this.props.layoutItem && this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.bitConfig;
        const defaultvalue = bitConfig && bitConfig.defaultValue;
        const value = ModelPropertiesParser('{{'+classProperty+'}}', workflowData);
        !value && this.props.change(classProperty, defaultvalue)
        const uia = workflow && workflow.currentItem.layoutProperties.uiActions;
        if(Array.isArray(uia)) {
            uia.map((action, aIndex) => {
                if(classProperty === action.triggerActionOn) {
                    this.setState({uiAction:uia});
                }
            })
        }
    }
    
    render() {
        const { classProperty, type, validations, value, isReadOne, workflowData,disableLayoutItem, ...rest } = this.props;
        const enableediting = this.props.layoutItem && this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.isLayoutItemEditable;
        const bitConfig = this.props.layoutItem && this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.bitConfig;
        const defaultvalue = bitConfig && bitConfig.defaultValue;
        const disableBit = bitConfig && bitConfig.disable;
        const displayAs = bitConfig && bitConfig.displayAsToggle;
        const itemValue = ModelPropertiesParser('{{'+classProperty+'}}', workflowData);
        const val = itemValue === "true" ? true : false;
        return (
            <div>
                {
                    isReadOne ?
                    enableediting ? <Field
                                    name={classProperty}
                                    component={renderSingleCheckBox}
                                    type="checkbox"
                                    validate={validations}
                                    value={value}
                                    fieldType={type}
                                    onUIAction={onUIAction}
                                    disableLayoutItem={disableLayoutItem}
                                    dispatchManual={this.props.dispatch}
                                    uiAction={this.state.uiAction}
                                    onDataChange={() => {
                                        const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                        Array.isArray(cEventKeys) &&  cEventKeys.map(k => {
                                            k.startsWith(classProperty) &&
                                            window.cEvents[k](this.props.formValues);
                                        });
                                    }}
                                    {...rest}/>:  <PrintText {...this.props} />
                    :   <Field
                            name={classProperty}
                            component={renderSingleCheckBox}
                            displayAs={displayAs}
                            type="checkbox"
                            validate={validations}
                            disabled={disableBit}
                            value={value}
                            fieldType={type}
                            defaultvalue={val}
                            onUIAction={onUIAction}
                            disableLayoutItem={disableLayoutItem}
                            onDataChange={() => {
                                const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                Array.isArray(cEventKeys) && cEventKeys.map(k => {
                                    k.startsWith(classProperty) &&
                                    window.cEvents[k](this.props.formValues);
                                });
                            }}
                            dispatchManual={this.props.dispatch}
                            uiAction={this.state.uiAction}
                            {...rest}
                        />
                }
            </div>
        );
    }
}

export default Bit;
