import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap';
import NavWorkflowsContainer from '../../containers/NavWorkflowsContainer';
import { NavLink as RRNavLink } from 'react-router-dom';
import { localeReducer } from 'react-localize-redux';
import RenderCompanyLogo from '../commons/RenderCompanyLogo';

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.clearToken = this.clearToken.bind(this)
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    clearToken() {
        const { clearWorkflowState } = this.props;
        localStorage.removeItem('user');
        clearWorkflowState && clearWorkflowState();
    }
    resolveIsActive(match, location) {
        if (!match || !location) {
            return false
        }
        const { path } = match;
        const { pathname } = location;
        if (pathname === path) {
            return true;
        }
        return false;
    }
    render() {
        const { isPublic, logout, isDasboard } = this.props;
        return (
            <div className={`topbar-player ${isPublic ? 'public-topbar' : ''} ${this.props.classes}`}>
                <Navbar className='navbar-expand-md' fixed="top">
                    <NavbarBrand href="/login">
                        <RenderCompanyLogo />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle.bind(this)} className="mr-2" />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        {
                            isDasboard ? null : <NavWorkflowsContainer isPublic={isPublic} resolveIsActive={this.resolveIsActive.bind(this)} />
                        }
                        
                        <Nav className="ml-auto">
                            <NavItem>
                                {
                                    isPublic ? <NavLink onClick={this.clearToken} to='/login' tag={RRNavLink}>
                                        <Button color="link" size="sm" outline>
                                            Already registered? Login here.
                                        </Button>
                                    </NavLink> : <NavLink className='sign-out' onClick={logout}>
                                            <Button color="link" size="sm">
                                                <i className="fa fa-sign-out" aria-hidden="true"></i> {this.props.translate('menu.logout')}
                                            </Button>
                                        </NavLink>
                                }
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

Topbar.defaultProps = {
    classes: ''

};

Topbar.propTypes = {
    classes: PropTypes.string,
    translate: PropTypes.func,
    logout: PropTypes.func,
    user: PropTypes.object,
    currentLanguage: PropTypes.string
};

export default Topbar;
