import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import config from '../../../../../config';
import client from '../../../../../client';

class FileCell extends Component {

    openLink = (text) => {
        if (typeof text === "string" && text.startsWith("protected/")) {
            client().get(`${config.playerApi}/${text}`).then(res => {
                window.open(res.data);
            }).catch(e => {
                console.log(e)
            })
        } else {
            window.open((text && !text.startsWith('http')) ? (config.assetsBasePath + text) : text)
        }
    }
    render() {
        const { rows, column, columnKey, rowIndex, width } = this.props;
        const valueToRender = rows.getObjectAt(rowIndex)[columnKey];
        const DATA_HREF = `${config.assetsBasePath}${valueToRender}`;
        let cellAlignment;
        let cellStyle;
        if(config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
        if(!valueToRender){
            return <NoDataCell {...this.props} /> 
        }
        return (
            <Cell width={width} align={cellAlignment ? cellAlignment : 'left'}>
                <a style={cellStyle} href="" onClick={(e) =>{
                    e.preventDefault()
                    this.openLink(valueToRender)
                }}>Download</a>
            </Cell>
        );
    }
}

export default FileCell;