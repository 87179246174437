import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const _LineChart = props => {
  const data = props.data;
  const yAxis = props.yAxis;
  return (
    <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="xAxis" />
          <YAxis />
          <Tooltip />
          <Legend />
          {
            yAxis.map(axis => {
              const key = axis.label.replace(/ /g, "_");
              return <Line key={key} type="monotone" dataKey={key} stroke={axis.color? axis.color : "#8884d8"} activeDot={{ r: 8 }} />
            })
          }
        </LineChart>
      </ResponsiveContainer>
  );
};

export default _LineChart;
