import React, { Component } from 'react';
import RichTextResolver from '../commons/RichTextResolver';
import PropTypes from 'prop-types';
import RenderIcon from '../commons/RenderIcon';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import config from '../../config';

class RichhTextRenderer extends Component {
    render() {
        const { fromSession, rawContent,customIcons, eleStyle } = this.props;
        let _rawContent = rawContent;
        let workflowData = null;
        if (!fromSession && this.props.layoutItem) {
            _rawContent = this.props.layoutItem.itemProperties.staticContent;
            workflowData = this.props.workflowData;
        } else if(this.props.workflow) {
            workflowData = this.props.workflow.workflowData;
        }
        try {
            if (typeof _rawContent === 'string') {
                if(_rawContent.includes("***|||***")) {
                    const richTextContent = _rawContent.split("***|||***");
                    _rawContent = JSON.parse(richTextContent[0]);
                } else {
                    _rawContent = JSON.parse(_rawContent);
                }
            }
        }
        catch (ex) {
            if (typeof _rawContent === 'string') {
                return <p>{_rawContent}</p>;
            }
        }
        const iconfromImage = this.props.layoutItem && this.props.layoutItem.itemProperties.richtextConfig ? this.props.layoutItem.itemProperties.richtextConfig.imageAsIconField : null
        const iconfromImageEnabled = this.props.layoutItem && this.props.layoutItem.itemProperties.richtextConfig ? this.props.layoutItem.itemProperties.richtextConfig.useImageAsIcon : null

        const imageData = ModelPropertiesParser(iconfromImage, workflowData)
    

        if(this.props.layoutItem && this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.beforeIcon || (iconfromImage && iconfromImageEnabled)) {
            return (
                <div className="d-flex">
                    <span className="mr-2"><RenderIcon customIcons={customIcons} config={this.props.layoutItem.itemProperties.beforeIcon} /></span>
                   { iconfromImageEnabled && <span className='imageSpan' style={{marginRight:2}}><img className='imageAsIcon' src={config.assetsBasePath + imageData} alt=" " /></span>}
                    <RichTextResolver
                        workflowData={workflowData}
                        rawContent={_rawContent}
                        richtextConfig={this.props.layoutItem && this.props.layoutItem.itemProperties.richtextConfig ? this.props.layoutItem.itemProperties.richtextConfig : null}
                        itemProperties={this.props.layoutItem && this.props.layoutItem.itemProperties}
                        eleStyle={eleStyle}
                   />
                </div>
            )
        }
        return (
            <RichTextResolver
                workflowData={workflowData}
                rawContent={_rawContent}
                isTransparent={this.props.layoutItem && this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.qrInput}
                richtextConfig={this.props.layoutItem && this.props.layoutItem.itemProperties.richtextConfig ? this.props.layoutItem.itemProperties.richtextConfig : null}
                itemProperties={this.props.layoutItem && this.props.layoutItem.itemProperties}
                eleStyle={eleStyle}
                />
        );
    }
}

RichhTextRenderer.propTypes = {
    fromSession: PropTypes.bool,
    rawContent: PropTypes.string
};

RichhTextRenderer.defaultProps = {
    fromSession: false,
    rawContent: null
}

export default RichhTextRenderer;