import React from 'react';
import { Button } from 'reactstrap';
import RenderIcon from '../commons/RenderIcon';

const _Button = (props) => {
    const { layoutItem: { itemProperties: { workflowNextButtonConfig } }, classProperty, type, validations, value, label, isReadOne, nextState, workflow: { displayMode },customIcons,disableSaveAndNext, ...rest } = props;
    const isLoading = nextState && nextState.isLoading ? true : false;
    const uniqueCode = workflowNextButtonConfig && workflowNextButtonConfig.item && workflowNextButtonConfig.item.value;
    const endWorkflow = workflowNextButtonConfig && workflowNextButtonConfig.endWorkflow;
    const color = workflowNextButtonConfig && workflowNextButtonConfig.buttonStyle ? workflowNextButtonConfig.buttonStyle : 'primary';
    const outline = workflowNextButtonConfig && workflowNextButtonConfig.isOutline;
    const iconConfig = workflowNextButtonConfig && workflowNextButtonConfig.cssClass ? {
        cssClass: workflowNextButtonConfig.cssClass
    } : null;
    return (
        <div>
            <Button data-html2canvas-ignore='true' name='uniqueCode' onClick={uniqueCode ? () => {
                props.jumptoSubmit({jumpto: uniqueCode})
            } : props.onHandleSubmit(values => {
                    if(displayMode != 'MODAL') {   
                        window && window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                    let payload = values;
                    if(uniqueCode) {
                        payload = values.set('jumpto', uniqueCode)
                    } else if(endWorkflow) {
                        payload = values.set('endWorkflow', endWorkflow);
                    }
                    props.onSubmit(payload);
                })}
                disabled={props.disableLayoutItem || isLoading || disableSaveAndNext} 
                block 
                color={color}
                outline={outline}
            >
            {
                iconConfig && !isLoading ? <RenderIcon customIcons={customIcons} config={iconConfig} /> : null
            }
            {!isLoading ? label.text : 'Please wait...'}
            </Button>
        </div>
    );
}

export default _Button;
